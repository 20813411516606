import { Select } from 'components/UIKit'
import { useAppSelector } from 'store/Orcatec/hooks'
import { selectCompanyTechnicians } from 'store/Orcatec/reducers/settings/company'
import styled from 'styled-components'

interface Props {
  data: {
    user_id: number[]
  }
  onChange: (filters: Props['data']) => void
}

export const BoardFilters = ({ data, onChange }: Props) => {
  const assignee = useAppSelector(selectCompanyTechnicians)

  function handleOpportunityChange(e) {
    const { name, value } = e.target

    onChange({
      ...data,
      [name]: value,
    })
  }

  return (
    <Wrapper>
      <Select
        fieldNames={{ label: 'full_name', value: 'id' }}
        allowClear
        mode='multiple'
        label='Assigned to'
        name='user_id'
        value={data?.user_id}
        options={assignee}
        onChange={handleOpportunityChange}
        listItemHeight={32}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div``
