import { getTechnicians } from 'api/User'
import { useEffect, useState } from 'react'
import { ITechnician } from 'types/Appointment'

const useTechnicians = () => {
  const [technicians, setTechnicians] = useState<ITechnician[]>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchTechs = async () => {
      setLoading(true)
      const data = await getTechnicians()

      setTechnicians(data)
      setLoading(false)
    }

    fetchTechs()
  }, [])

  return { technicians, loading }
}

export default useTechnicians
