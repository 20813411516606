import Avatar from 'containers/MainContent/Orcatec/components/Avatar'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { getMediaURL } from 'helpers/getMediaURL'
import {
  IconUserv2,
  OrganizationsIcon,
} from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
import { ContactType } from 'features/Contacts/types'
import { Tooltip } from 'antd'
import { ProjectsIcon } from 'layouts/MainLayout/Header/components/Navigation/NavigationIcons'
import { WarningOutlined } from '@ant-design/icons'

export interface IOpportunityCard {
  id: number
  title: string
  source: string
  type: string
  stage_id: number
  assignee: {
    profile_picture: string | null
    background_color: string
    full_name: string | null
    id: null
  }
  contacts: { name: string; type: ContactType }[]
}

interface Props {
  data: {
    id: number
    title: string
    source: string
    type: string
    stage_id: number
    assignee: {
      profile_picture: string | null
      background_color: string
      full_name: string | null
      id: null
    }
    contacts: { name: string; type: ContactType }[]
    is_multi_won: boolean
    is_won: boolean
    proposals_count: number
    min_max_total: number
  }
  onClick: () => void
}

export const OpportunityCard = ({ data, onClick }: Props) => {
  return (
    <Wrapper onClick={onClick}>
      <Heading>
        <Title>
          <Link
            to={`projects/${data.id}`}
            className='linkTo'
            onClick={e => e.preventDefault()}
          >
            {data.title}
          </Link>
        </Title>
      </Heading>

      <Row>
        {!!data.contacts?.length && (
          <Contact>
            {data.contacts[0]?.type === ContactType.ORGANIZATION ? (
              <OrganizationsIcon width={20} height={20} />
            ) : (
              <IconUserv2
                style={{ fontSize: 20, position: 'relative', top: 3 }}
              />
            )}
            <Text>{data.contacts[0].name}</Text>

            {data.contacts?.length > 1 && (
              <Tooltip
                overlayStyle={{ whiteSpace: 'pre-line' }}
                title={data.contacts
                  ?.slice(1)
                  ?.map(contact => `${contact?.name}\n`)}
              >
                <ContactsCounter>+{data.contacts?.length - 1}</ContactsCounter>
              </Tooltip>
            )}
          </Contact>
        )}

        {!!data.proposals_count && (
          <AttachedProjects>
            <ProjectsIcon />
            <span>{data.proposals_count}</span>
          </AttachedProjects>
        )}
      </Row>

      {(data.type || data.source) && (
        <TypeAndSource>
          {data.source && (
            <Ellipsed>
              <p>
                <span>Source:</span> {data.source}
              </p>
            </Ellipsed>
          )}
          {data.type && (
            <Ellipsed>
              <p>
                <span>Type:</span> {data.type}
              </p>
            </Ellipsed>
          )}
        </TypeAndSource>
      )}

      <Bottom>
        {data.assignee.full_name && (
          <Avatar
            title={data.assignee?.full_name}
            color={data.assignee?.background_color}
            size={22}
            pictureURL={getMediaURL(data.assignee?.profile_picture)}
          >
            {data.assignee?.full_name}
          </Avatar>
        )}

        {data.is_won && (
          <Tooltip title='Attached project is in "Contract" status'>
            <WONBadge>WON</WONBadge>
          </Tooltip>
        )}

        {data?.min_max_total !== null && <Total>{data.min_max_total}</Total>}

        {data.is_multi_won && (
          <Tooltip
            title='Multiple projects associated with this Opportunity are marked as "Contract." Please verify projects to avoid potential issues.'
            mouseLeaveDelay={0}
          >
            <WarningOutlined style={{ color: '#8C8C8C' }} />
          </Tooltip>
        )}
      </Bottom>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  &:hover {
    .linkTo {
      color: #007ef2;
    }
  }
`

const Heading = styled.div`
  display: flex;
  gap: 5px;
  justify-content: space-between;
  align-items: center;
`
const Title = styled.p`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 500;
`

const TypeAndSource = styled.div`
  /* display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); */
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin: 2px 0 5px;
  font-size: 12px;

  & > * {
    flex: 1 0 48%;
  }
`

const Ellipsed = styled.div`
  overflow: hidden;
  white-space: nowrap;

  p {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  span {
    color: #8c8c8c;
  }
`

const Contact = styled.div`
  display: flex;
  gap: 3px;
  align-items: center;
  margin: 2px 0 5px;
  overflow: hidden;
`

const Text = styled.span`
  font-size: 12px;
  padding-top: 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const Bottom = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

const ContactsCounter = styled.span`
  font-size: 12px;
  background-color: #f1f2f4;
  padding: 2px 4px;
  position: relative;
  top: 2px;
  border-radius: 4px;

  :hover {
    background-color: #e2e2e2;
  }
`

const Row = styled.div`
  display: flex;
  align-items: center;
`

const AttachedProjects = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
  opacity: 50%;
  font-size: 14px;
  margin-left: auto;
  margin-right: 10px;

  svg {
    width: 16px;
  }
`

const Total = styled.p`
  font-weight: 500;
`

const WONBadge = styled.span`
  border-radius: 16px;
  background-color: #d9f7be;
  color: #237804;
  font-size: 12px;
  padding: 0 4px;
`
