import {
  EntityLevel,
  EntityType,
} from 'features/CRM/components/Opportunity/types'
import { ClientType } from 'types/Client'

export const prepareData = (
  data,
  contacts = {},
  level = EntityLevel.PARENT,
  parent_id = null,
  parent_type = null,
  relations = {},
  entity_id = null,
  entities = {},
  countClient = 0,
  countOrganization = 0,
  countProperties = 0,
  counts = {},
  // opportunity_main_relation_id = 0,
) => {
  let index = 0

  const normalize = (
    items,
    parent_id,
    parent_type,
    currentLevel,
    entity_id,
    entities,
    entity_parent_type,
    countClient,
    countOrganization,
    countProperties,
    opportunity_main_relation_id,
  ) => {
    items.forEach(item => {
      const {
        id,
        opportunity_relation,
        entity_type,
        opportunity_relation_id,
        children,
        nested_contacts,
        nested_properties,
        ...rest
      } = item
      const uniqueIndex = ++index
      const currentEntityId = entity_id || id
      const currentParentType = entity_parent_type || entity_type
      const parentRelationId =
        opportunity_main_relation_id || opportunity_relation_id

      if (currentLevel === EntityLevel.CHILD) {
        if (item.entity_type === EntityType.PROPERTY) {
          countProperties = countProperties + 1
        }
        if (item.entity_type === EntityType.CLIENT) {
          if (item.type === ClientType.ORGANIZATION) {
            countOrganization = countOrganization + 1
          } else {
            countClient = countClient + 1
          }
        }
      }

      const entityItem = {
        id,
        level: currentLevel,
        parent_id,
        parent_type,
        entity_type,
        entity_id: currentEntityId,
        entity_parent_type: currentParentType,
        index: uniqueIndex,
        opportunity_relation_id,
        parent_opportunity_relation_id: parentRelationId,
        ...rest,
      }

      contacts[uniqueIndex] = entityItem
      counts[currentEntityId] = {
        countClient,
        countOrganization,
        countProperties,
      }

      if (opportunity_relation_id) {
        relations[opportunity_relation_id] = {
          opportunity_relation,
          opportunity_relation_id,
          entity_id: id,
          entity_type: entity_type,
        }
      }

      if (children && children.length > 0) {
        normalize(
          item.children,
          id,
          entity_type,
          EntityLevel.CHILD,
          currentEntityId,
          entities,
          currentParentType,
          countClient,
          countOrganization,
          countProperties,
          parentRelationId,
        )
      }

      if (nested_contacts && nested_contacts.length > 0) {
        normalize(
          item.nested_contacts,
          id,
          entity_type,
          EntityLevel.NESTED_CHILD,
          currentEntityId,
          entities,
          currentParentType,
          countClient,
          countOrganization,
          countProperties,
          parentRelationId,
        )
      }

      if (nested_properties && nested_properties.length > 0) {
        normalize(
          item.nested_properties,
          id,
          entity_type,
          EntityLevel.NESTED_CHILD,
          currentEntityId,
          entities,
          currentParentType,
          countClient,
          countOrganization,
          countProperties,
          parentRelationId,
        )
      }
    })
  }

  normalize(
    data,
    parent_id,
    parent_type,
    level,
    entity_id,
    entities,
    '',
    countClient,
    countOrganization,
    countProperties,
  )

  return {
    contacts,
    relations,
    entities,
    counts,
  }
}

export const normalizeData = data => {
  const contacts = {
    client: {},
    property: {},
  }
  const allIds = []

  let relations = {}

  let counts = {}

  data?.forEach(item => {
    const {
      contacts: entityContacts,
      relations: entityRelations,
      counts: entitycounts,
    } = prepareData([item])

    contacts[item.entity_type][item.opportunity_relation_id] = entityContacts

    relations = { ...relations, ...entityRelations }

    counts = { ...counts, ...entitycounts }
    allIds.push({
      id: item.opportunity_relation_id,
      entity_type: item.entity_type,
    })
  })

  return {
    contacts: { ...contacts, allIds, counts },
    entities: {
      relations,
    },
  }
}
