import crmAPI from 'features/CRM/api'
import CRMBoard from 'features/CRM/components/CRMBoard'
import CRMTable from 'features/CRM/components/CRMTable'
import OpportunityModal from 'features/CRM/components/Opportunity'
import { Opportunity } from 'features/CRM/crm.interface'
import {
  getBoardList,
  selectActiveBoard,
  selectStagesByBoard,
} from 'features/CRM/store/crmSlice'
import { useDefaultIndustry } from 'hooks/useDefaultIndustry'
import useLocalStorage from 'hooks/useLocalStorage'
import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'store/Orcatec/hooks'

const initialState: Omit<Opportunity, 'id'> = {
  contact_id: null,
  contact_relation: '',
  name: 'New opportunity',
  type_id: null,
  source_id: null,
  board_id: 1,
  stage_id: 1,
  industry_id: 1,
  user_id: null,
  notes: '',
}

export const CRMPage = () => {
  const dispatch = useAppDispatch()
  const [value, setValue] = useLocalStorage('CRMBoardView', true)

  const activeBoard = useAppSelector(selectActiveBoard)
  const stages = useAppSelector(state =>
    selectStagesByBoard(state, activeBoard?.id),
  )
  const defaultIndustry = useDefaultIndustry()

  const [boardView, setBoardView] = useState(value ?? true)
  const [showModal, setShowModal] = useState(false)
  const [invalidateValue, setInvalidateValue] = useState(0)
  const [isCreating, setIsCreating] = useState(false)

  const [opportunityParams, setOpportunityParams] = useState<{
    id?: number
    stageId?: number
    isNew?: boolean
  } | null>(null)

  useEffect(() => {
    dispatch(getBoardList())
  }, [])

  function handleViewChange(value: boolean) {
    setBoardView(value)
    setValue(value)
  }

  async function handleOpenModal(
    params: { id?: number; stageId?: number } | null,
  ) {
    if (isCreating) return

    if (!params?.id) {
      const newOpportunity: Omit<Opportunity, 'id'> = {
        ...initialState,
        board_id: activeBoard?.id || 1,
        stage_id: params?.stageId || stages[0].id,
        industry_id: defaultIndustry || 1,
      }

      setIsCreating(true)
      const createdOpportunity = await crmAPI.createOpportunity(newOpportunity)
      setIsCreating(false)

      setShowModal(true)

      setOpportunityParams({ id: createdOpportunity.id, isNew: true })

      return
    }

    setShowModal(true)

    setOpportunityParams(params)
  }

  function handleOpportunityClose(isCancel?: boolean) {
    if (opportunityParams?.isNew && opportunityParams.id && isCancel) {
      crmAPI.deleteOpportunity(opportunityParams.id, {
        hard_delete: true,
      })
    }

    setOpportunityParams(null)
    setShowModal(false)
  }

  return (
    <>
      {boardView ? (
        <CRMBoard
          boardView={boardView}
          onViewChange={handleViewChange}
          onOpen={handleOpenModal}
          invalidate={invalidateValue}
        />
      ) : (
        <CRMTable
          boardView={boardView}
          onViewChange={handleViewChange}
          onOpen={handleOpenModal}
          invalidate={invalidateValue}
        />
      )}

      {showModal && (
        <OpportunityModal
          id={opportunityParams?.id || null}
          stageId={opportunityParams?.stageId}
          isNew={opportunityParams?.isNew}
          onClose={handleOpportunityClose}
          onSaved={() => setInvalidateValue(invalidateValue + 1)}
        />
      )}
    </>
  )
}
