import TableLayout from '../../../../containers/MainContent/Orcatec/Layouts/TableLayout/TableLayout'
import { Button, Search } from 'components/UIKit'
import {
  ContentSwitcher,
  SwitcherButton,
} from 'features/Project/ProjectLayout/ProjectLayout.styles'
import {
  IconBoard,
  IconTable,
  IconUserv2,
  OrganizationsIcon,
} from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
import Table from 'containers/MainContent/Orcatec/components/Table'
import { useEffect, useMemo, useState } from 'react'
import { ColumnProps } from 'antd/lib/table'
import { ellipsisStyle } from 'containers/MainContent/Orcatec/Properties'
import { PaginationConfig } from 'antd/lib/pagination'
import { FilterDropdownProps, SorterResult } from 'antd/lib/table/interface'
import useDebounce from 'hooks/useDebounce'
import { getValueFromLS } from 'hooks/useLocalStorage'
import crmAPI from 'features/CRM/api'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import { GeneralTableParams } from 'containers/MainContent/Orcatec/components/Table/types'
import { OpportunityTableItem } from 'features/CRM/crm.interface'
import styled from 'styled-components'
import { Tooltip } from 'antd'
import { CheckCircleOutlined, SettingOutlined } from '@ant-design/icons'
import AutocompliteFilter from 'containers/MainContent/Orcatec/components/Table/components/TableFilters/AutocompliteFilter/AutocompliteFilter'
import useTechnicians from 'hooks/useTechnicians'
import { useAppSelector } from 'store/Orcatec/hooks'
import { selectAllStages, selectBoardsList } from 'features/CRM/store/crmSlice'
import { getTagsStatistics } from 'api/Proposal'
import { ContactType } from 'features/Contacts/types'

const tableName = 'opportunity_list'

interface Props {
  invalidate: number
  boardView: boolean
  onViewChange: (value: boolean) => void
  onOpen: (params: { id?: number; stageId?: number } | null) => void
}

export const CRMTable = ({
  onViewChange,
  boardView,
  onOpen,
  invalidate,
}: Props) => {
  const settingsFromLC = getValueFromLS(`${tableName}-table_v1`)

  const { technicians } = useTechnicians()
  const boards = useAppSelector(selectBoardsList)
  const boardsFilter = boards?.map(board => ({
    text: board.title,
    value: board.title,
  }))

  const stages = useAppSelector(selectAllStages)
  const stagesFilter = stages?.map(stage => ({
    text: stage.name,
    value: stage.name,
  }))

  const isWonFilter = [
    {
      text: 'WON',
      value: true,
    },
    {
      text: 'Not WON',
      value: false,
    },
  ]

  const [opportunities, setOpportunities] = useState([])
  const [clickedRowIndex, setClickedRowIndex] = useState<number | null>(null)
  const [loading, setLoading] = useState(false)
  const [tagData, setTagData] = useState([])
  const [sortField, setSortField] = useState<string | undefined>(
    settingsFromLC?.sorter?.sort_field || undefined,
  )
  const [sortOrder, setSortOrder] = useState<string | undefined>(
    settingsFromLC?.sorter?.order || undefined,
  )
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: settingsFromLC?.page_size || 25,
    total: 10,
  })
  const [search, setSearch] = useState(settingsFromLC?.search || '')
  const [openTableSettings, setOpenTableSettings] = useState(false)
  const [tableFilters, setTableFilters] = useState({
    ...settingsFromLC?.filters,
  })

  const debouncedSearchValue = useDebounce(search, 500)

  async function getData(params: GeneralTableParams) {
    setLoading(true)

    try {
      const { data, meta } = await crmAPI.getOpportunityList(params)

      setPagination({
        page: meta.current_page,
        per_page: meta.per_page,
        total: meta.total,
      })

      setOpportunities(
        data.map((opp: OpportunityTableItem, index: number) => ({
          ...opp,
          key: index,
        })),
      )
    } catch (error) {
      openNotificationWithIcon('error', {
        message: 'Oops',
        description: error.response?.data?.message || 'Something went wrong',
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getData({
      per_page: pagination.per_page,
      sort_field: sortField,
      order: sortOrder,
      search: debouncedSearchValue || undefined,
      ...tableFilters,
    })
  }, [debouncedSearchValue, invalidate])

  useEffect(() => {
    getTagsStatistics({}, 'crm/opportunities').then(({ tags }) =>
      setTagData(
        tags
          ?.filter(el => !!el?.count)
          .map(tag => ({ ...tag, text: tag.name, value: tag.id }))
          ?.sort((a, b) => b.count - a.count),
      ),
    )
  }, [])

  /* const tagRender = tags => {
    return tags?.map(({ name, color, id }) => (
      <CheckableTag
        // onChange={checked => handleChange(id, checked)}
        style={{
          margin: '4px',
          cursor: 'pointer',
          backgroundColor: `${
            tableFilters?.tags?.includes(id) ? '#1890ff' : color
          }`,
        }}
        // checked={tableFilters?.tags?.includes(id)}
        key={id}
      >
        {name.length > 10 ? (
          <Tooltip title={name}>{`${name.slice(0, 10)}...`}</Tooltip>
        ) : (
          name
        )}
      </CheckableTag>
    ))
  } */

  const columns: ColumnProps<OpportunityTableItem>[] = useMemo(
    () => [
      {
        title: 'Opportunity name',
        name: 'Opportunity name',
        dataIndex: 'name',
        sorter: true,
        defaultWidth: 150,
        render: (text: string | null, record) => {
          return (
            <Link
              onClick={e => {
                e.stopPropagation()
                onOpen({ id: record.id })
              }}
            >
              {text || 'Opportunity name'}
            </Link>
          )
        },
        noFilter: true,
        onCell: (record: OpportunityTableItem) =>
          record.key === clickedRowIndex ? undefined : ellipsisStyle,
      },
      {
        title: 'Assignee',
        name: 'Assignee',
        dataIndex: 'full_name',
        sorter: true,
        defaultWidth: 150,
        onCell: (record: OpportunityTableItem) =>
          record.key === clickedRowIndex ? undefined : ellipsisStyle,
        filterDropdown: (props: FilterDropdownProps) => (
          <AutocompliteFilter
            title='User Name'
            values={technicians?.map(t => ({ id: t.id, name: t.name }))}
            {...props}
          />
        ),
      },
      {
        title: 'Board',
        name: 'Board',
        dataIndex: 'board',
        sorter: true,
        defaultWidth: 200,
        filters: boardsFilter,
        filterMode: 'tree',
        onCell: (record: OpportunityTableItem) =>
          record.key === clickedRowIndex ? undefined : ellipsisStyle,
      },
      {
        title: 'Stage',
        name: 'Stage',
        dataIndex: 'stage',
        sorter: true,
        onCell: (record: OpportunityTableItem) =>
          record.key === clickedRowIndex ? undefined : ellipsisStyle,
        filters: stagesFilter,
        filterMode: 'tree',
        defaultWidth: 200,
        render: (stage, record) => (
          <Stage color={record.stage_color || '#39A5BD'}>{stage}</Stage>
        ),
      },
      {
        title: 'WON',
        name: 'WON',
        dataIndex: 'is_won',
        sorter: true,
        // onCell: (record: OpportunityTableItem) =>
        //   record.key === clickedRowIndex ? undefined : ellipsisStyle,
        filters: isWonFilter,
        // filterMode: 'tree',

        // noFilter: true,
        align: 'center',
        defaultWidth: 80,
        render: (is_won: boolean) =>
          is_won ? (
            <CheckCircleOutlined style={{ color: '#2ebda3', fontSize: 18 }} />
          ) : (
            '-'
          ),
      },
      {
        title: 'Type',
        name: 'Type',
        dataIndex: 'type',
        onCell: (record: OpportunityTableItem) =>
          record.key === clickedRowIndex ? undefined : ellipsisStyle,
        sorter: true,
        defaultWidth: 150,
        noFilter: true,
      },
      {
        title: 'Source',
        name: 'Source',
        dataIndex: 'source',
        onCell: (record: OpportunityTableItem) =>
          record.key === clickedRowIndex ? undefined : ellipsisStyle,
        sorter: true,
        defaultWidth: 150,
        noFilter: true,
      },
      {
        title: 'Contacts',
        name: 'Contacts',
        dataIndex: 'client_name',
        onCell: (record: OpportunityTableItem) =>
          record.key === clickedRowIndex ? undefined : ellipsisStyle,
        sorter: true,
        defaultWidth: 150,
        noFilter: true,
        render: (_: string, record) => {
          const { contacts } = record
          if (!contacts?.length) return '-'
          return contacts.map((c, idx) => (
            <span key={idx} style={ContactColumnStyle}>
              {ContactType.ORGANIZATION === c?.type ? (
                <OrganizationsIcon width={20} height={20} />
              ) : (
                <IconUserv2 style={{ fontSize: 20 }} />
              )}
              {c?.name}
            </span>
          ))
        },
      },
      {
        title: 'Property',
        name: 'Property',
        dataIndex: 'full_address',
        onCell: (record: OpportunityTableItem) =>
          record.key === clickedRowIndex ? undefined : ellipsisStyle,
        sorter: true,
        defaultWidth: 150,
        noFilter: true,
      },
      {
        title: 'Notes',
        name: 'Notes',
        dataIndex: 'notes',
        onCell: (record: OpportunityTableItem) =>
          record.key === clickedRowIndex ? undefined : ellipsisStyle,
        sorter: true,
        defaultWidth: 150,
        render: (notes: string[]) => (notes.length ? notes.join(', ') : '-'),
      },
      {
        title: 'Tags',
        name: 'Tags',
        dataIndex: 'tags',
        render: tags =>
          tags.length
            ? tags.map(tag => (
                <Tag key={tag.id} color={tag.color} small>
                  {tag.name}
                </Tag>
              ))
            : '-',
        onCell: (record: OpportunityTableItem) =>
          record.key === clickedRowIndex ? undefined : ellipsisStyle,
        sorter: true,
        defaultWidth: 200,
        filterMode: 'tree',
        filters: tagData,
        filterSearch: true,
      },
    ],
    [clickedRowIndex, boardsFilter, stagesFilter, technicians, tagData?.length],
  )

  const handleTableChange = async (
    pagination: PaginationConfig,
    filters: Partial<Record<keyof OpportunityTableItem, string[]>>,
    sorter: SorterResult<OpportunityTableItem>,
  ) => {
    try {
      await getData({
        search: debouncedSearchValue || undefined,
        page: pagination.current,
        sort_field: sorter.order ? sorter.field : undefined,
        order:
          sorter.order === 'ascend'
            ? 'asc'
            : sorter.order === 'descend'
            ? 'desc'
            : undefined,
        per_page: pagination.pageSize,
        ...filters,
        // ...tableFilters,
        // ...additionalFilters,
      })

      setTableFilters(filters)

      setSortOrder(
        sorter.order === 'ascend'
          ? 'asc'
          : sorter.order === 'descend'
          ? 'desc'
          : undefined,
      )
      setSortField(sorter.order ? sorter.field : undefined)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const onRow = (record: OpportunityTableItem, rowIndex: number) => ({
    onClick: () => {
      setClickedRowIndex(rowIndex)
    },
  })

  return (
    <TableLayout
      title='Opportunity'
      addButton={
        <Button type='primary' onClick={() => onOpen(null)}>
          + Add Opportunity
        </Button>
      }
      center={
        <Center>
          <ContentSwitcher>
            <SwitcherButton
              active={!boardView}
              onClick={() => onViewChange(false)}
            >
              <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                <IconTable style={{ fontSize: 20 }} />
                Table
              </div>
            </SwitcherButton>
            <SwitcherButton
              active={boardView}
              onClick={() => onViewChange(true)}
            >
              <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                <IconBoard style={{ fontSize: 20 }} />
                Board
              </div>
            </SwitcherButton>
          </ContentSwitcher>
        </Center>
      }
      actions={
        <Actions>
          <Search
            value={search}
            onChange={e => setSearch(e.target.value)}
            placeholder='Search...'
          />

          {/* <IconWrapper  */}
          <Tooltip placement='left' title='Columns settings'>
            <SettingOutlined
              style={{ cursor: 'pointer', fontSize: 20 }}
              onClick={() => setOpenTableSettings(true)}
            />
          </Tooltip>
          {/* </IconWrapper> */}
        </Actions>
      }
    >
      <Table
        name={tableName}
        columns={columns}
        data={opportunities}
        pagination={pagination}
        loading={loading}
        onChange={handleTableChange}
        onRow={onRow}
        // hiddenColumnsByDefault={hiddenColumnsByDefault}
        // hiddenColumns={!isAccountingSubscription ? additionalColumn : []}
        // rowSelection={rowSelection}
        search={debouncedSearchValue}
        openTableSettings={openTableSettings}
        onCloseTableSettings={() => setOpenTableSettings(false)}
        locale={{
          emptyText: debouncedSearchValue
            ? 'Search result not found'
            : 'No data',
        }}
      />
    </TableLayout>
  )
}

const Link = styled.p`
  color: #1890ff;
  cursor: pointer;
  display: inline;
`
const Tag = styled.span<{ color: string; small?: boolean }>`
  background-color: ${props => props.color}33;
  color: ${props => props.color};
  padding: ${props => (props.small ? '2px 4px' : '4px 8px')};
  border-radius: 4px;
  display: inline-block;
  border: ${props => (props.small ? `1px solid ${props.color}` : 'none')};
  margin: 0 5px 2px 0;
`
const Stage = styled.span<{ color: string; small?: boolean }>`
  background-color: ${props => props.color}33;
  color: ${props => props.color};
  padding: ${props => (props.small ? '2px 4px' : '4px 8px')};
  border-radius: 4px;
  border: ${props => (props.small ? `1px solid ${props.color}` : 'none')};
  margin: 0 5px 2px 0;
`

const Actions = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`

const Center = styled.div`
  width: 300px;

  @media (max-width: 500px) {
    margin-bottom: 10px;
  }
`

const ContactColumnStyle = {
  padding: '0 3px',
  whiteSpace: 'nowrap',
  display: 'inline-flex',
  alignItems: 'flex-start',
  gap: '2px',
}
