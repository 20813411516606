import { CloseOutlined } from '@ant-design/icons'
import { Empty, Popconfirm, Table, TablePaginationConfig, Tooltip } from 'antd'
import { Button } from 'components/UIKit'
import { priceToView } from 'helpers/thousandSeparator'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

interface Props {
  data: object[]
  pagination: object
  loading: boolean
  onTableChange: (pagination: TablePaginationConfig) => void
  onCreate: () => void
  onAttach: () => void
  onDetach: (projectId: number) => void
}

export const LinkedProjects = ({
  data,
  pagination,
  loading,
  onTableChange,
  onCreate,
  onAttach,
  onDetach,
}: Props) => {
  const columns = [
    {
      title: 'Project ID',
      dataIndex: 'code',
      key: 'code',
      render: (code, record) => (
        <Link to={`/projects/${record.id}`}>{code}</Link>
      ),
      width: 120,
    },
    {
      title: 'Project Name',
      dataIndex: 'name',
      key: 'name',
      render: (text: string | null) => text || '-',
    },
    {
      title: 'Status',
      dataIndex: 'status_name',
      key: 'status_name',
      render: (_: number, record) => (
        <ProjectStatus color={record.status_color}>
          {record.status_name}
        </ProjectStatus>
      ),
      width: 135,
    },
    {
      title: 'Assignee',
      dataIndex: 'assignee',
      key: 'assignee',
      width: 140,
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      render: (total: number | null) =>
        total !== null ? <Bold>{priceToView(total)}</Bold> : '-',
      width: 112,
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      render: (_, record) => (
        <Tooltip title='Detach Project'>
          <Popconfirm
            title='Are you sure you want to detach this project?'
            onConfirm={() => onDetach(record.id)}
          >
            <CloseOutlined style={{ cursor: 'pointer' }} />
          </Popconfirm>
        </Tooltip>
      ),
      width: 90,
    },
  ]

  function renderContent() {
    if (!data.length) {
      return <Empty description='No projects yet' />
    }

    return (
      <Table
        size='small'
        columns={columns}
        dataSource={data}
        locale={{ emptyText: <Empty description='No projects yet' /> }}
        onChange={onTableChange}
        loading={loading}
        pagination={
          pagination?.total > 10
            ? {
                current: pagination.current_page,
                total: pagination.total,
                pageSize: pagination.per_page,
                showSizeChanger: true,
              }
            : false
        }
      />
    )
  }

  return (
    <Wrapper>
      <Heading>
        <Bold>Projects</Bold>
        <Button
          type='primary'
          onClick={onCreate}
          style={{ marginLeft: 'auto' }}
        >
          + Create new Project
        </Button>

        <Button type='primary' onClick={onAttach}>
          Attach existing Project
        </Button>
      </Heading>

      <Content>{renderContent()}</Content>
    </Wrapper>
  )
}

const Wrapper = styled.div``
const Heading = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
`
const Content = styled.div``
const Bold = styled.p`
  font-weight: 500;
`

const ProjectStatus = styled.span<{ color: string }>`
  background-color: ${props => props.color}33;
  color: ${props => props.color};
  padding: 4px 8px;
  border-radius: 4px;
  white-space: nowrap;
`
