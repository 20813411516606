import styled from 'styled-components'
import { FULL_DATE_FORMAT } from 'constants/dateFormats'
import moment from 'moment-timezone'
import {
  EditOutlined,
  IconEnvelope,
  VerifiedIcon,
  NotVerifiedIcon,
} from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
import { CustomForm } from 'features/Forms/types'
import { Link } from 'react-router-dom'
import Progress from 'components/UIKit/Progress'
import { useAppSelector } from 'store/Orcatec/hooks'
import {
  selectCompanyTimezone,
  selectJobDictionary,
} from 'store/Orcatec/selectors/company'
import { canVerifyFormSelect } from 'features/Settings/UsersAndGroups/permissionSlice'

import { Tooltip } from 'antd'
// import { CheckOutlined } from '@ant-design/icons'
// import { PanToolTwoTone } from '@material-ui/icons'

interface Props {
  data: CustomForm
  onClick: (data: CustomForm) => void
  showRelatedInfo?: boolean
  isPublicView: boolean
}

export const FormCard = ({
  data,
  onClick,
  showRelatedInfo,
}: // isPublicView,
Props) => {
  const companyTimezone = useAppSelector(selectCompanyTimezone)
  const dictionary = useAppSelector(selectJobDictionary)
  const canFormVerify = useAppSelector(canVerifyFormSelect)

  return (
    <Wrapper onClick={() => onClick(data)}>
      <Data>
        <Title>
          <p className='title-name'>{data.name}</p>
          {canFormVerify && (
            <Tooltip
              title={
                showRelatedInfo
                  ? data.is_verified
                    ? `Verified by ${data.verified_name}`
                    : 'This form has not been verified yet'
                  : ''
              }
            >
              <IconWrapper>
                {data?.is_verified ? <VerifiedIcon /> : <NotVerifiedIcon />}
              </IconWrapper>
            </Tooltip>
          )}
        </Title>
        <Info>
          {!!data.main_field_id && (
            <p>
              <span>{data.main_field_label}:</span>
              <span>{data?.main_field_value || '-'}</span>
            </p>
          )}

          <p>
            <span>Updated:</span>
            <span>
              {moment(data.updated_at || data.created_at)
                .tz(companyTimezone)
                .format(FULL_DATE_FORMAT)}
            </span>
          </p>
          {data?.submitted_by && (
            <Container>
              <Tooltip
                title={`Submitted by ${data?.submitted_by?.full_name} at
                      ${moment(data?.submitted_at)
                        .tz(companyTimezone)
                        .format(FULL_DATE_FORMAT)}`}
              >
                <Label>Submitted by:</Label>
                <Text>{`${data?.submitted_by?.full_name} ${moment(
                  data?.submitted_at,
                )
                  .tz(companyTimezone)
                  .format(FULL_DATE_FORMAT)}`}</Text>
              </Tooltip>
            </Container>
          )}

          {showRelatedInfo && (
            <>
              {!!data.job_id ? (
                <p>
                  <span>{`Related ${dictionary.singular}:`} </span>
                  <Link
                    to={`/jobs/${data.job_id}`}
                    target='_blank'
                    onClick={e => e.stopPropagation()}
                  >
                    {data.code}
                  </Link>
                </p>
              ) : !!data.wo_proposal_id ? (
                <p>
                  <span>{`Related work order:`} </span>
                  {data.code}
                </p>
              ) : null}
            </>
          )}
        </Info>
      </Data>

      <Actions>
        <EditOutlined style={{ color: 'transparent', fontSize: 14 }} />
      </Actions>

      <ProgressWrapper>
        <Progress
          percent={data.fill_percentage === 100 ? 99 : data.fill_percentage}
          width={20}
          isCompleted={data.completed}
        />

        <Tooltip
          title={
            showRelatedInfo
              ? data?.last_notification
                ? `Sent to  ${data.last_notification?.recipients
                    ?.map(email => email.email)
                    .join(' , ')} at ${moment(data.last_notification?.send_at)
                    .tz(companyTimezone)
                    .format(FULL_DATE_FORMAT)}`
                : 'This form has not been sent yet'
              : ''
          }
        >
          <IconWrapper>
            <IconEnvelope
              style={{
                color: data?.last_notification ? '#28a745' : '#4D4D4D',
                width: '22px',
                height: '22px',
              }}
            />
          </IconWrapper>
        </Tooltip>
      </ProgressWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  border: 1px solid #d6dbed;
  border-radius: 6px;
  padding: 10px;
  cursor: pointer;
  position: relative;
  flex: 0 0 300px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  &:hover {
    svg {
      opacity: 1;
    }
  }
`

const Data = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
`
const Title = styled.p`
  color: #4688f1;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  gap: 10px;
  & .title-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 230px;
  }
`
const Info = styled.div`
  font-size: 0.8rem;
  max-width: 280px;

  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    span {
      &:first-of-type {
        color: #707683;
        margin-right: 10px;
      }
    }
  }
`
const Actions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: end;
  position: absolute;
  right: 10px;
  top: 13px;

  svg {
    opacity: 0;
  }
`

const ProgressWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

const IconWrapper = styled.div`
  display: flex;
  & svg {
    width: 22px;
    height: 22px;
  }
`

const Container = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
`

const Label = styled.span`
  flex-shrink: 0;
  margin-right: 5px;
`

const Text = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: black;
`
