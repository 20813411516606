import React, { useState, useEffect, useRef } from 'react'
import { CirclePicker } from 'react-color'
import {
  getUserTags,
  createTag,
  getAllTags,
  setTagsSync,
} from '../../../../../api/Tag'
import style from './Tag.module.css'
import { Select, Tag, Form, Spin, Divider, Input, Tooltip, Space } from 'antd'
import { openNotificationWithIcon } from '../../../../../helpers/notifications/openNotificationWithIcon'
import useOnClickOutside from 'hooks/useOnClickOutside'
import MainButton from '../buttons/MainButton'
import { useAppSelector } from 'store/Orcatec/hooks'

const { Option } = Select

export interface TagsProps {
  route: string
  title?: string
  showContent: boolean
  maxWidth?: string | null
  id?: number
  onChange?: (tags: number[]) => void
}

const Tags: React.FC<TagsProps> = ({
  id,
  route,
  title,
  showContent = false,
  maxWidth = null,
  onChange,
}) => {
  const path = `${route}${id}`
  const [tags, setTags] = useState(null)
  const [options, setOptions] = useState([])
  const [tagName, setTagName] = useState(null)
  const [tagColor, setTagColor] = useState(null)
  const [isOpen, setIsOpen] = useState(true)
  const [ids, setIds] = useState([])
  const [loading, setLoading] = useState(false)
  const [selectedId, setSelectedId] = useState(false)
  const [state, setState] = useState(null)
  const [deletedTag, setDeletedTag] = useState([])
  const [error, setError] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [initiating, setInitiating] = useState(false)
  const [tagCreating, setTagCreating] = useState(false)
  const ref = useRef()

  const triger = useAppSelector(
    state => state.orcatec?.proposalForm?.renderCount,
  )

  const templateChanged = useAppSelector(
    state => state.orcatec?.proposalForm?.tempalteSet,
  )

  useOnClickOutside(ref, () => setSelectedId(false))

  const changeColorHeandler = hex => {
    setTagColor(hex)
  }
  const onCancelHeandler = () => {
    setTagColor(null)
    setTagName(null)
    setError(false)
  }
  const openColorPickHeandler = () => {
    setSelectedId(prev => !prev)
  }
  const addItem = async e => {
    e.preventDefault()

    setTagCreating(true)
    const tag = await createTag({ name: tagName, color: tagColor || null })
    setTagCreating(false)
    const { name, id, color } = tag.data.data
    setOptions(prev => [{ name, id, color }, ...prev])
    setTagName('')
    setTagColor(null)
  }
  const isOpenHeandler = open => {
    setIsOpen(open)
  }
  const onChangeHeandler = e => {
    const newState = options?.filter(({ id }) => e.includes(id))

    setState(newState)
    setTags(newState)
    setIds(e)
    setSearchValue('')

    onChange?.(e)
  }
  const onNameChange = e => {
    const { value } = e.target
    const nameInOptions = options.map(({ name }) => name.trim())
    setTagName(e.target.value)
    if (nameInOptions.includes(value.trim())) {
      setError(true)
      return
    }
    setError(false)
  }
  const setSelectedTags = async arrIds => {
    if (!id) return

    setLoading(true)
    try {
      await setTagsSync(path, { ids: arrIds })
    } catch (error) {
      openNotificationWithIcon('error', {
        message: `Error: ${error?.error?.message}`,
      })
    } finally {
      setLoading(false)
    }
  }
  const hex2rgba = (hex, alpha = 1) => {
    const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16))
    return `rgba(${r},${g},${b},${alpha})`
  }
  const showTagPlaceholder = () => {
    if (!state) {
      return <div> Selected any tags</div>
    }

    return <div>{`Selected ${state.length} tags`}</div>
  }
  const filteredOptionHeandler = (inputValue, options) => {
    setSearchValue(inputValue)
    return options?.label?.toLowerCase()?.includes(inputValue?.toLowerCase())
  }
  const margeOptionsIfDeletedTags = (allUserTags, allTags) => {
    const deletedItem = allUserTags?.filter(el => {
      if (allTags?.map(e => e.id).includes(el.id)) {
        return false
      }
      return true
    })

    setDeletedTag(deletedItem.map(el => el.id))
    const hash = new Map()
    allUserTags.concat(allTags).forEach(obj => {
      hash.set(obj.id, Object.assign(hash.get(obj.id) || {}, obj))
    })
    const arrOptions = Array.from(hash.values())
    setOptions(arrOptions)
  }
  const getData = async () => {
    setInitiating(true)
    try {
      const allTags = await getAllTags()
      setOptions(allTags.tags)

      if (id) {
        const allUserTags = await getUserTags(path)
        setTags(allUserTags.tags)
        setState(allUserTags.tags)
        setIds(allUserTags.tags.map(el => el.id))
        margeOptionsIfDeletedTags(allUserTags.tags, allTags.tags)
      }
    } catch (error) {
      openNotificationWithIcon('error', {
        message: `Error: ${error?.error?.message}`,
      })
    } finally {
      setInitiating(false)
    }
  }

  const refreshData = async () => {
    try {
      const allUserTags = await getUserTags(path)
      setTags(allUserTags.tags)
      setState(allUserTags.tags)
      setIds(allUserTags.tags.map(el => el.id))
    } catch (error) {
      openNotificationWithIcon('error', {
        message: `Error: ${error?.error?.message}`,
      })
    }
  }

  const onKeyDownHeandler = e => {
    e.stopPropagation()
  }
  const deleteItemIfWasDeleted = id => {
    if (deletedTag?.includes(id) && !state?.map(el => el.id).includes(id)) {
      setOptions(prev => prev?.filter(el => el.id !== id))
      setDeletedTag(prev => prev?.filter(el => el !== id))
    }
  }
  const searchInputHeandler = value => {
    setSearchValue(value)
  }

  useEffect(() => {
    getData()
  }, [id])

  useEffect(() => {
    if (templateChanged) refreshData()
  }, [triger, templateChanged])

  useEffect(() => {
    if (!isOpen && isOpen !== null) {
      setSelectedTags(ids)
      setIsOpen(null)
      setTagName(null)
      setTagColor(null)
      setError(false)
      searchInputHeandler('')
    }
  }, [isOpen])

  return (
    <>
      {title && <Divider orientation='left'>{title}</Divider>}
      {!initiating ? (
        <div className={style.tagsWrapper}>
          {state?.length ? (
            <div className={style.tags}>
              {state?.map(({ id, color, name }) => (
                <Tag
                  key={id}
                  color={color}
                  style={{
                    height: 30,
                    margin: '4px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {name.length > 40 ? (
                    <Tooltip title={name}>{`${name.slice(0, 40)}...`}</Tooltip>
                  ) : (
                    name
                  )}
                </Tag>
              ))}
            </div>
          ) : (
            <p> {!showContent ? 'No tags selected' : ''}</p>
          )}

          {showContent && (
            <div className={style.tagSelectWrapper}>
              <Select
                className={style.tagSelect}
                getPopupContainer={trigger => trigger.parentNode}
                mode='multiple'
                showArrow
                style={{
                  width: '100%',
                  maxWidth: maxWidth ? maxWidth : '290px',
                }}
                onChange={onChangeHeandler}
                defaultValue={tags?.map(el => el.id)}
                placeholder='Select tags'
                onDropdownVisibleChange={isOpenHeandler}
                allowClear={false}
                dropdownStyle={{ padding: '10px 2px 10px 10px', top: '34px' }}
                loading={loading}
                maxTagCount={0}
                autoFocus={false}
                maxTagPlaceholder={showTagPlaceholder}
                filterOption={filteredOptionHeandler}
                onInputKeyDown={onKeyDownHeandler}
                virtual={false}
                showSearch={true}
                searchValue={searchValue}
                onSearch={searchInputHeandler}
                dropdownMatchSelectWidth={false}
                dropdownRender={menu => (
                  <>
                    {menu}
                    <Divider orientation='left' style={{ margin: '8px 0' }}>
                      {'Create new  tag'}
                    </Divider>
                    <Space
                      align='center'
                      style={{
                        padding: '0 8px 4px',
                        display: 'flex',
                        alignItems: 'flex-start',
                      }}
                    >
                      <Form>
                        <Form.Item
                          validateStatus={error ? 'error' : 'success'}
                          help={error ? 'This tag already exists!' : ''}
                        >
                          <Input
                            placeholder='Please enter tag name'
                            style={{ width: '200px' }}
                            value={tagName}
                            onChange={onNameChange}
                            onKeyDown={onKeyDownHeandler}
                          />
                        </Form.Item>
                      </Form>

                      <Tooltip title={'Please choose a color'}>
                        <div
                          style={{ backgroundColor: tagColor }}
                          onClick={openColorPickHeandler}
                          className={style.chooseColor}
                        ></div>
                      </Tooltip>
                    </Space>
                    {tagName || tagColor ? (
                      <div className={style.btnWrapper}>
                        <MainButton
                          title='Cancel'
                          onClick={onCancelHeandler}
                          type='cancel'
                        />
                        <MainButton
                          disabled={
                            !(tagName && !!tagName.trim()) ||
                            error ||
                            tagCreating
                          }
                          title='Create'
                          onClick={addItem}
                        />
                      </div>
                    ) : null}
                    {!!selectedId && (
                      <div className={style.pickerWrapper} ref={ref}>
                        <CirclePicker
                          width='100%'
                          color={tagColor || '#626ed4'}
                          onChangeComplete={({ hex }) =>
                            changeColorHeandler(hex)
                          }
                        />
                      </div>
                    )}
                  </>
                )}
              >
                {/* selected item is top of list */}
                {[
                  ...options?.filter(item => ids?.includes(item?.id)),
                  ...options?.filter(item => !ids?.includes(item?.id)),
                ]?.map(({ color, id, name }) => {
                  deleteItemIfWasDeleted(id)
                  return (
                    <Option
                      style={{
                        backgroundColor: hex2rgba(color, 0.5),
                        margin: '4px',
                        borderRadius: '10px',
                      }}
                      key={id + color}
                      value={id}
                      label={name}
                    >
                      <div className='demo-option-label-item'>
                        {name.length > 30 ? (
                          <Tooltip title={name}>{`${name.slice(
                            0,
                            30,
                          )}...`}</Tooltip>
                        ) : (
                          name
                        )}
                      </div>
                    </Option>
                  )
                })}
              </Select>
            </div>
          )}
        </div>
      ) : (
        <Spin
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        />
      )}
    </>
  )
}

export default Tags
