import { CustomSelect } from 'components/UIKit'
import { selectDispatchSettings } from 'features/Dispatch/dispatchSlice'
import useSources from 'hooks/useSources'
import { useAppDispatch, useAppSelector } from 'store/Orcatec/hooks'
import {
  createCompanySource,
  deleteCompanySource,
} from 'store/Orcatec/operation/settings/company'

interface Props {
  label?: string
  value: number | null
  onChange: (value: number | null) => void
}

export const EventSources = ({ label = 'Source', value, onChange }: Props) => {
  const dispatch = useAppDispatch()

  // const owner = useAppSelector(isOwner)
  const sources = useSources(true)
  const { restrict_crud_source_type: disableCrud } = useAppSelector(
    selectDispatchSettings,
  )

  async function handleOptionAdd(value: string) {
    return await dispatch(createCompanySource({ name: value }))
  }

  async function handleOptionDelete(optionId: number) {
    dispatch(deleteCompanySource(optionId))
  }

  return (
    <CustomSelect
      label={label}
      value={value}
      onChange={onChange}
      options={sources.map(type => ({
        label: type.name,
        value: type.id,
        deleted: !!type.deleted_at,
      }))}
      allowClear
      onAddOption={disableCrud ? undefined : handleOptionAdd}
      onDeleteOption={disableCrud ? undefined : handleOptionDelete}
    />
  )
}
