import React, { useEffect, useState } from 'react'
import { Popover, Spin, Tooltip } from 'antd'
import { useBoard } from 'features/CRM/hooks/useBoard'
import { useOpportunity } from 'features/CRM/hooks/useOpportunity'
import styled from 'styled-components'
import crmAPI from 'features/CRM/api'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import { InfoCircleOutlined } from '@ant-design/icons'

interface Props {
  opportunityId: number
  counter: number
}

const OpportunityStatusComponent = ({ opportunityId, counter }: Props) => {
  const { opportunity, loading } = useOpportunity(opportunityId)
  const { board, loading: boardLoading } = useBoard(opportunity?.board_id)

  const [selectedStatus, setSelectedStatus] = useState(1)

  useEffect(() => {
    setSelectedStatus(opportunity.stage_id)
  }, [opportunity.stage_id])

  async function onChangeStatus(value: number) {
    setSelectedStatus(value)

    try {
      await crmAPI.patchOpportunity(opportunityId, { stage_id: value })
    } catch (error) {
      openNotificationWithIcon('error', {
        message: 'Oops',
        description: error?.response?.data?.message || 'Something went wrong',
      })
    }
  }

  return (
    <Spin spinning={loading || boardLoading}>
      <Wrapper>
        <StatusTitle>
          Opportunity status:
          <Popover
            placement='right'
            title={`Opportunity info`}
            content={
              <>
                <p>
                  <span style={{ fontWeight: 500 }}>Name:</span>{' '}
                  {opportunity.name}
                </p>
                <span style={{ fontWeight: 500 }}>Attached projects:</span>{' '}
                {counter}
              </>
            }
          >
            <InfoCircleOutlined style={{ color: 'blue' }} />
          </Popover>
        </StatusTitle>

        <StagesList>
          {board?.stages?.map(stage => (
            <Tooltip title={stage.name} key={stage.id} mouseLeaveDelay={0}>
              <Stage
                key={stage.id}
                color={stage.color}
                active={stage.id === selectedStatus}
                onClick={() => onChangeStatus(stage.id)}
              >
                <Title>{stage.name}</Title>
              </Stage>
            </Tooltip>
          ))}
        </StagesList>
      </Wrapper>
    </Spin>
  )
}

export const OpportunityStatus = React.memo(OpportunityStatusComponent)

const Wrapper = styled.div`
  margin-bottom: 20px;
  margin-top: -27px;
`

const StagesList = styled.div`
  display: grid;
  gap: 5px;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  align-items: center;
  font-weight: 400;
`

const Stage = styled.div<{ color: string; active: boolean }>`
  font-size: 14px;
  background-color: ${props =>
    props.active ? `${props.color}ab` || '#40A9FF' : '#D9D9D9ab'};
  color: ${props => (props.active ? '#fff' : 'initial')};
  cursor: pointer;
  padding: 6px 5px 6px 15px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    height: 0;
    width: 0;
    left: 0;
    top: 0;
    border: 17px solid transparent;
    border-left: 5px solid #fff;
    display: block;
  }

  &::after {
    content: '';
    position: absolute;
    height: 0;
    width: 0;
    right: -22px;
    top: 0;
    border: 17px solid transparent;
    border-left: 5px solid
      ${props => (props.active ? `${props.color}ab` || '#40A9FF' : '#D9D9D9ab')};
    display: block;
  }
`

const StatusTitle = styled.p`
  /* overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 0 0 200px;
  display: flex;
  align-items: center; */
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 5px;
`

const Title = styled.p`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 0 0 200px;
`
