export const formatRequestData = (clients, key) => {
  return {
    data:
      clients &&
      clients
        .filter(item => item?.display)
        .map((el, idx) => ({
          [key]: el.opportunity_relation_id,
          position: idx + 1,
          is_primary: !!(idx === 0),
        })),
  }
}
