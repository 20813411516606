export enum Module {
  PROJECT = 'project_can_read',
  ACCOUNTING = 'accounting_can_read',
  SCHEDULE = 'schedule_can_read',
  BALANCE = 'accounting_can_read_balance_table',
  EXPENSES = 'accounting_can_read_expenses_table',
  WORK_PROGRESS = 'accounting_can_read_entries_table',
  CONTACTS = 'contacts_list_can_read',
  CRM = 'crm_can_view_opportunities_list',
  DASHBOARD = 'dashboard_can_read',
  DISPATCH = 'dispatch_can_read',
  FORMS = 'forms_list_can_read',
  FORMS_TEMPLATE = 'forms_template_list_can_read',
  FORMS_DETAILED = 'forms_detailed_list_can_read',
  MAP = 'map_can_read',
  NOTES = 'notes_can_read',
  PROPERTIES = 'properties_list_can_read',
  TIME_CARDS = 'time_cards_list_can_read',
  FAST_PAYMENT = 'fast_payment_can_create',
  INSTANT_APPOINTMENT = 'instant_appointment_can_create',
  JOBS = 'jobs_list_can_read',
  QUICKBOOKS = 'quickbooks_can_read',
  TODO = 'todo_can_read',
  REVIEWS = 'reviews_list_can_read',
  MEMBERSHIPS = 'memberships_can_read',
  MESSAGING = 'messaging_can_read',
  SETTINGS = 'settings_can_read',
  CALL_TRACKING = 'call_tracking_can_read',
  TYPE_SOURCE = 'type_source_can_create',
  ESTIMATES = 'estimates_list_can_read',
}
export enum ModuleName {
  PROJECT = 'project',
  SCHEDULE = 'schedule',
  ACCOUNTING = 'accounting',
  CONTACTS = 'contacts',
  CRM = 'crm',
  DASHBOARD = 'dashboard',
  DISPATCH = 'dispatch',
  FORMS = 'forms',
  MAP = 'map',
  NOTES = 'notes',
  PROPERTIES = 'properties',
  TIME_CARDS = 'time_cards',
  FAST_PAYMENT = 'fast_payment',
  INSTANT_APPOINTMENT = 'instant_appointment',
  JOBS = 'jobs',
  QUICKBOOKS = 'quickbooks',
  TODO = 'todo',
  REVIEWS = 'reviews',
  SETTINGS = 'settings',
  MEMBERSHIPS = 'memberships',
  MESSAGING = 'messaging',
  CALL_TRACKING = 'call_tracking',
  TYPE_SOURCE = 'type_source',
  ESTIMATES = 'estimates',
}
