import { Spin } from 'antd'
import { ProjectAPI } from 'api/Project'
import { EventSources } from 'components/UI/EventSources/EventSources'
import { EventTypes } from 'components/UI/EventTypes/EventTypes'
import { Button, Select } from 'components/UIKit'
import { Project } from 'features/Project/types'
import { PlanLimits } from 'features/Settings/Subscription/components/PlanLimits/PlanLimits'
import {
  ModuleName,
  ProjectPermissions,
} from 'features/Settings/UsersAndGroups'
import { selectUserPermissionsByModule } from 'features/Settings/UsersAndGroups/permissionSlice'
import { IScope } from 'features/Settings/UsersAndGroups/types'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import { useDefaultIndustry } from 'hooks/useDefaultIndustry'
import { useEffect, useState } from 'react'
import { useAppSelector } from 'store/Orcatec/hooks'
import { selectCompanyTechnicians } from 'store/Orcatec/reducers/settings/company'
import { selectAuthUser, selectIndustries } from 'store/Orcatec/selectors/user'
import styled from 'styled-components'

interface Props {
  route: string
  initialData?: Partial<{
    industry_id: number
    source_id: number | null
    type_id: number | null
    user_id: number | null
  }>
  onCancel: () => void
  onCreated: (data: Project) => void
}

export const CreateProject = ({
  route,
  initialData,
  onCancel,
  onCreated,
}: Props) => {
  const authUser = useAppSelector(selectAuthUser)
  const industriesList = useAppSelector(selectIndustries)
  const permissions = useAppSelector(
    selectUserPermissionsByModule(ModuleName.PROJECT),
  )
  const assignee = useAppSelector(selectCompanyTechnicians)

  const CAN_CREATE_PROJECT =
    permissions[ProjectPermissions.PROJECT_CAN_CREATE] === IScope.ALL

  const defaultIndustry = useDefaultIndustry()

  const [projectData, setProjectData] = useState({
    industry_id: initialData?.industry_id || industriesList?.[0]?.id,
    source_id: initialData?.source_id || null,
    type_id: initialData?.type_id || null,
    user_id: CAN_CREATE_PROJECT
      ? initialData?.user_id || authUser.id
      : authUser.id,
    status: 1,
  })
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!!defaultIndustry && !initialData?.industry_id)
      setProjectData(prev => ({
        ...prev,
        industry_id: defaultIndustry,
      }))
  }, [defaultIndustry])

  function handleProjectDataChange(e) {
    const { name, value } = e.target

    setProjectData(prev => ({ ...prev, [name]: value ?? null }))
  }

  async function handleCreate() {
    setLoading(true)
    try {
      const data = await ProjectAPI.createProject(route, projectData)
      onCreated?.(data)
    } catch (error) {
      openNotificationWithIcon('error', {
        message: 'Oops',
        description: error?.response?.data?.message || 'Something went wrong',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Spin spinning={loading}>
      <Wrapper>
        <PlanLimits entity='projects' />

        <Select
          fieldNames={{ label: 'full_name', value: 'id' }}
          label='Assigned to'
          name='user_id'
          value={projectData?.user_id}
          options={assignee}
          onChange={handleProjectDataChange}
          listItemHeight={32}
          disabled={!CAN_CREATE_PROJECT}
          disabledText='You have no permissions to perform this action'
        />

        <EventSources
          label='Lead Source'
          value={projectData?.source_id}
          onChange={value =>
            handleProjectDataChange({ target: { name: 'source_id', value } })
          }
        />

        <EventTypes
          value={projectData?.type_id}
          onChange={value =>
            handleProjectDataChange({ target: { name: 'type_id', value } })
          }
        />

        <Select
          fieldNames={{ label: 'name', value: 'id' }}
          name='industry_id'
          label='Industry'
          value={projectData?.industry_id}
          onChange={handleProjectDataChange}
          options={industriesList}
        />

        <Footer>
          <Button onClick={onCancel}>Cancel</Button>
          <Button type='primary' onClick={handleCreate} loading={loading}>
            Create
          </Button>
        </Footer>
      </Wrapper>
    </Spin>
  )
}

const Wrapper = styled.div`
  & > * {
    margin-bottom: 12px;
  }
`

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  gap: 16px;
`
