import {
  PayloadAction,
  createAsyncThunk,
  createSlice,
  createSelector,
  isAnyOf,
} from '@reduxjs/toolkit'
import { startAppListening } from 'store/listenerMiddleware'
import {
  EntityType,
  Contact,
  ProjectProperty,
  PropertyWithAdditionalData,
  ContactWithAdditionalData,
} from 'features/CRM/components/Opportunity/types'
// import { ProjectContactsAPI } from 'api/Project'
import { AsyncThunkAPI, DefaultError } from 'store/types'

import { AppStateType } from 'store'
import { normalizeData } from 'features/CRM/components/Opportunity/helpers/normalizeData'
import { reorderItems } from 'features/Project/Contacts/helpers/reorderItems'
import {
  attachPropertyToContact,
  attachContactToOrganization,
} from 'api/Contacts/Contacts'
import { attachClientOrOrganization } from 'api/Property'

import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import { Client, ClientType } from 'types/Client'
import { OpportunityContactsAPI } from '../api'
import { formatRequestData } from '../components/Opportunity/components/Contacts/helpers/fromatRequestData'

type BaseItem = ContactWithAdditionalData | PropertyWithAdditionalData

interface ContactsState {
  client: {
    [id: BaseItem['opportunity_relation_id']]: {
      [index: number]: ContactWithAdditionalData
    }
  }
  property: {
    [id: BaseItem['opportunity_relation_id']]: {
      [index: number]: PropertyWithAdditionalData
    }
  }
  allIds: { id: number; entity_type: EntityType }[]
  counts: {
    [id: number]: {
      countClient: number
      countOrganization: number
      countProperties: number
    }
  }
}

interface TilesState {
  client: Contact[]
  property: ProjectProperty[]
}

interface EntitiesState {
  relations: { [id: number]: RelationEntity }
}

interface RelationEntity {
  opportunity_relation: null | string
  opportunity_relation_id: number | null
  entity_id: number
  entity_type: EntityType
}

interface DataState {
  entities: EntitiesState
  contacts: ContactsState
  tiles: TilesState
}

interface ProjectContactsState {
  status: 'idle' | 'loading' | 'error' | 'success' | 'processing'
  error: DefaultError | undefined
  open: boolean
  in_process: BaseItem | null
  data: DataState
}

interface AddContactToOpportunityArgs {
  opportunityId: number
  data: {
    entity_type: EntityType
    entity_id: number
  }
}

type AttachChildToProjectArgs = {
  opportunityId: number
  item: BaseItem
}

type DetachContactFromProjectArgs = {
  opportunityId: number
  item: BaseItem
}

type UpdateProjectContactRelationArgs = {
  data: {
    opportunity_relation_id: number
    opportunity_relation_type: EntityType
    opportunity_relation: string
  }
  opportunityId: number
}

export type SetPositionArgs = {
  sourceIndex: number
  destinationIndex: number
  entity_type?: EntityType
}

type AddChildContactToProjectArgs = {
  idToAttach: number
  item: BaseItem
  typeToAttach: EntityType
  opportunityId: number
}

type InvalidateParentTreeArgs = {
  parent_entity_id: number
  parent_entity_type: EntityType
  opportunityId: number
}

const initialContactsState: ContactsState = {
  client: {},
  property: {},
  allIds: [],
  counts: {},
}

const initialState: ProjectContactsState = {
  status: 'idle',
  error: null,
  open: false,
  in_process: null,
  data: {
    entities: {
      relations: {},
    },
    contacts: initialContactsState,
    tiles: {
      client: [],
      property: [],
    },
  },
}

type IndexedItem<T> = {
  item: T
}

const setRelationReducer = (
  state: ProjectContactsState,
  { payload }: PayloadAction<RelationEntity>,
) => {
  state.data.entities.relations[payload.id] = payload.data
}

const setItemToTilesReducer = (
  state: ProjectContactsState,
  { payload }: PayloadAction<IndexedItem<BaseItem>>,
) => {
  const position = state.data.tiles[payload.item.entity_type]

  state.data.tiles[payload.item.entity_type] = [
    ...state.data.tiles[payload.item.entity_type],
    {
      ...payload.item,
      position: position.length + 1,
      is_primary: !position,
    },
  ]
}

const toggleContactSectionReducer = (state: ProjectContactsState) => {
  state.open = !state.open
}
const onChangeSelectedItemReducer = (
  state: ProjectContactsState,
  { payload }: PayloadAction<{ item: BaseItem; checked: boolean }>,
) => {
  const { item, checked } = payload

  const { index, entity_parent_type, parent_opportunity_relation_id } = item

  state.data.contacts[entity_parent_type][parent_opportunity_relation_id][
    index
  ].checked = checked
}

const onChangeSelectedParentItemReducer = (
  state: ProjectContactsState,
  { payload }: PayloadAction<{ item: BaseItem; checked: boolean }>,
) => {
  const { item, checked } = payload

  const { index, entity_type, parent_opportunity_relation_id } = item

  state.data.contacts[entity_type][parent_opportunity_relation_id][
    index
  ].display = checked
}

const setItemToIdsReducer = (
  state: ProjectContactsState,
  { payload }: PayloadAction<{ id: number; entity_type: EntityType }>,
) => {
  state.data.contacts.allIds = [payload, ...state.data.contacts.allIds]
}

const setItemToCountsReducer = (
  state: ProjectContactsState,
  {
    payload,
  }: PayloadAction<{
    [id: number]: {
      countClient: number
      countOrganization: number
      countProperties: number
    }
  }>,
) => {
  state.data.contacts.counts = { ...state.data.contacts.counts, ...payload }
}

const removeItemFromIdsReducer = (
  state: ProjectContactsState,
  { payload }: PayloadAction<{ id: number }>,
) => {
  state.data.contacts.allIds = state.data.contacts.allIds.filter(
    item => item.id !== payload.id,
  )
}

const setContactReducer = (
  state: ProjectContactsState,
  {
    payload,
  }: PayloadAction<{
    id: number
    entity_type: EntityType
    item: IndexedItem<BaseItem>
  }>,
) => {
  state.data.contacts[payload.entity_type][payload.id] = payload.item
}

const setContactsReducer = (
  state: ProjectContactsState,
  { payload }: PayloadAction<ContactsState>,
) => {
  state.data.contacts.property = {
    ...state.data.contacts.property,
    ...payload.property,
  }
  state.data.contacts.client = {
    ...state.data.contacts.client,
    ...payload.client,
  }

  state.data.contacts.counts = {
    ...state.data.contacts.counts,
    ...payload.counts,
  }

  state.data.contacts.allIds = [
    ...payload.allIds,
    ...state.data.contacts.allIds,
  ]
}

const removeContactReducer = (
  state: ProjectContactsState,
  { payload }: PayloadAction<{ id: number; entity_type: EntityType }>,
) => {
  delete state.data.contacts[payload.entity_type][payload.id]
}

const setRelationsReducer = (
  state: ProjectContactsState,
  {
    payload,
  }: PayloadAction<{
    [x: number]: RelationEntity
  }>,
) => {
  state.data.entities.relations = {
    ...state.data.entities.relations,
    ...payload,
  }
}

const removeRelationReducer = (
  state: ProjectContactsState,
  { payload }: PayloadAction<{ id: number }>,
) => {
  delete state.data.entities.relations[payload.id]
}

const setEntitiesToTilesReducer = (
  state: ProjectContactsState,
  {
    payload,
  }: PayloadAction<{
    client: Contact[]
    property: ProjectProperty[]
  }>,
) => {
  state.data.tiles = payload
}

const removeItemFromTilesReducer = (
  state: ProjectContactsState,
  { payload }: PayloadAction<{ item: BaseItem }>,
) => {
  const newData = state.data.tiles[payload.item.entity_type].filter(
    item =>
      item.opportunity_relation_id !== payload.item.opportunity_relation_id,
  )
  state.data.tiles[payload.item.entity_type] = newData
}

const updateOrderReducer = (state: ProjectContactsState, { payload }) => {
  state.data.tiles = { ...state.data.tiles, ...payload }
}

const resetContactSliceReducer = (state: ProjectContactsState) => {
  state.data = initialState.data
  state.open = false
}

const setItemInProcessReducer = (state: ProjectContactsState, { payload }) => {
  state.in_process = payload
}

const opportunityContacts = createSlice({
  name: 'oportunityContacts',
  initialState,
  reducers: {
    onChangeSelectedParentItem: onChangeSelectedParentItemReducer,
    toggleContactSection: toggleContactSectionReducer,
    onChangeSelectedItem: onChangeSelectedItemReducer,
    setItemToIds: setItemToIdsReducer,
    setItemToCounts: setItemToCountsReducer,
    removeItemFromIds: removeItemFromIdsReducer,
    setContact: setContactReducer,
    removeContact: removeContactReducer,
    setRelation: setRelationReducer,
    setRelations: setRelationsReducer,
    removeRelation: removeRelationReducer,
    setItemToTiles: setItemToTilesReducer,
    setEntitiesToTiles: setEntitiesToTilesReducer,
    removeItemFromTiles: removeItemFromTilesReducer,
    updateOrder: updateOrderReducer,
    resetOpportunityContactSliceReducer: resetContactSliceReducer,
    setItemInProcess: setItemInProcessReducer,
    setContacts: setContactsReducer,
  },
  extraReducers: builder => {
    builder.addCase(getOpportunityContacts.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(getOpportunityContacts.fulfilled, (state, { payload }) => {
      state.status = 'success'
      state.data = {
        ...state.data,
        ...normalizeData(payload),
      }
    })
    builder.addCase(
      getOpportunityContacts.rejected,
      (state, { payload }: PayloadAction<any>) => {
        state.status = 'error'
        state.error = payload
      },
    )

    builder.addCase(addChildContactToOpportunity.pending, state => {
      state.status = 'processing'
    })

    builder.addCase(addChildContactToOpportunity.fulfilled, state => {
      state.status = 'success'
      state.open = true
    })
    builder.addCase(
      addChildContactToOpportunity.rejected,
      (state, { payload }) => {
        state.status = 'error'
        state.error = payload
      },
    )

    builder.addCase(addContactToOpportunity.pending, state => {
      state.status = 'processing'
    })

    builder.addCase(addContactToOpportunity.fulfilled, state => {
      state.status = 'success'
      state.open = true
    })
    builder.addCase(addContactToOpportunity.rejected, (state, { payload }) => {
      state.status = 'error'
      state.error = payload
    })

    builder.addCase(attachChildToOpportunity.pending, state => {
      state.status = 'processing'
    })

    builder.addCase(
      attachChildToOpportunity.fulfilled,
      (state, { payload }) => {
        const {
          entity_parent_type,
          index,
          parent_opportunity_relation_id,
        } = payload.item

        state.data.contacts[entity_parent_type][parent_opportunity_relation_id][
          index
        ].opportunity_relation_id = payload.opportunity_relation_id

        state.status = 'idle'

        state.in_process = null
      },
    )
    builder.addCase(
      attachChildToOpportunity.rejected,
      (state, { payload, meta }) => {
        const {
          index,
          entity_parent_type,
          parent_opportunity_relation_id,
        } = meta.arg.item
        state.data.contacts[entity_parent_type][parent_opportunity_relation_id][
          index
        ].checked = false
        state.status = 'error'
        state.in_process = null
        state.error = payload
        openNotificationWithIcon('error', { message: 'Something went wrong!' })
      },
    )

    builder.addCase(detachContactFromOpportunity.pending, state => {
      state.status = 'processing'
    })

    builder.addCase(
      detachContactFromOpportunity.fulfilled,
      (state, { payload }) => {
        const { clients_relations, properties_relations } = payload
        state.data.tiles = {
          client: state.data.tiles.client.filter(
            item => !clients_relations.includes(item.opportunity_relation_id),
          ),
          property: state.data.tiles.property.filter(
            item =>
              !properties_relations.includes(item.opportunity_relation_id),
          ),
        }
        state.status = 'success'
        state.in_process = null
      },
    )
    builder.addCase(
      detachContactFromOpportunity.rejected,
      (state, { payload }) => {
        state.status = 'error'
        state.in_process = null
        state.error = payload
        openNotificationWithIcon('error', { message: 'Something went wrong!' })
      },
    )

    builder.addCase(updateOpportunityContactRelation.pending, state => {
      state.status = 'processing'
    })

    builder.addCase(
      updateOpportunityContactRelation.fulfilled,
      (state, { payload }) => {
        const {
          opportunity_relation_id,
          opportunity_relation,
          opportunity_relation_type,
        } = payload

        const oldData = state.data.tiles[opportunity_relation_type]
        const newData = oldData.map(item =>
          item.opportunity_relation_id === opportunity_relation_id
            ? { ...item, opportunity_relation }
            : item,
        )

        state.data.tiles[opportunity_relation_type] = newData
        state.status = 'idle'
        state.in_process = null
      },
    )
    builder.addCase(
      updateOpportunityContactRelation.rejected,
      (state, { payload }) => {
        state.status = 'error'
        state.error = payload
        state.in_process = null
      },
    )

    builder.addCase(
      getRelationContactToOpportunity.fulfilled,
      (state, { payload }) => {
        const { properties, contacts } = payload
        state.data.tiles = {
          [EntityType.CLIENT]: contacts,
          [EntityType.PROPERTY]: properties,
        }
      },
    )
    builder.addCase(
      getRelationContactToOpportunity.rejected,
      (state, { payload }) => {
        state.status = 'error'
        state.error = payload
      },
    )

    builder.addCase(selectParentOpportunityContact.pending, state => {
      state.status = 'processing'
    })

    builder.addCase(
      selectParentOpportunityContact.fulfilled,
      (state, { payload }) => {
        const { entity_type, opportunity_relation_id } = payload.item

        state.data.tiles[entity_type] = state.data.tiles[
          entity_type
        ].map(item =>
          item.opportunity_relation_id === opportunity_relation_id
            ? { ...item, display: payload.checked }
            : item,
        )

        state.status = 'idle'

        state.in_process = null
      },
    )
    builder.addCase(
      selectParentOpportunityContact.rejected,
      (state, { payload, meta }) => {
        const {
          index,
          entity_type,
          parent_opportunity_relation_id,
        } = meta.arg.item
        state.data.contacts[entity_type][parent_opportunity_relation_id][
          index
        ].display = false
        state.status = 'error'
        state.in_process = null
        state.error = payload
      },
    )
  },
})

export const getOpportunityContacts = createAsyncThunk<
  { data: Client[] },
  number,
  AsyncThunkAPI
>(
  'opportunity/getOpportunityContacts',
  async (opportunityId, { rejectWithValue }) => {
    try {
      const res = await OpportunityContactsAPI.getOpportunityContacts(
        opportunityId,
      )
      return res
    } catch (error) {
      rejectWithValue(error.response.data)
    }
  },
)

export const addContactToOpportunity = createAsyncThunk<
  Contact,
  AddContactToOpportunityArgs,
  AsyncThunkAPI
>(
  'opportunity/addParentContact',
  async ({ opportunityId, data }, { rejectWithValue, getState, dispatch }) => {
    const tiles = getState().orcatec.opportunityContactsSlice.data.tiles
    try {
      const res = await OpportunityContactsAPI.attachContactToOpportunity(
        opportunityId,
        data,
      )

      const { contacts, entities } = normalizeData([res.data])

      const { entity_type, opportunity_relation_id } = res.data

      dispatch(
        setContact({
          id: opportunity_relation_id,
          entity_type,
          item: Object.values(contacts[entity_type])[0],
        }),
      )

      dispatch(setItemToIds({ id: opportunity_relation_id, entity_type }))

      dispatch(setRelations(entities.relations))

      dispatch(setItemToCounts(contacts.counts))

      dispatch(
        setEntitiesToTiles({
          client: [...tiles.client, ...res.related_contacts.contacts],
          property: [...tiles.property, ...res.related_contacts.properties],
        }),
      )

      return res.data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const attachChildToOpportunity = createAsyncThunk<
  { opportunity_relation_id: number; item: BaseItem },
  AttachChildToProjectArgs,
  AsyncThunkAPI
>(
  'opportunity/attachChild',
  async ({ opportunityId, item }, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState().orcatec.opportunityContactsSlice.data.contacts

      const parent =
        state[item.entity_parent_type][item.parent_opportunity_relation_id]['1']

      const data = {
        opportunity_parent_relation_id: parent?.opportunity_relation_id,
        opportunity_parent_id: parent?.id,
        opportunity_parent_type: parent?.entity_type,
        parent_entity_id: item?.parent_id,
        parent_entity_type: item?.parent_type,
        entity_id: item?.id,
        entity_type: item?.entity_type,
      }

      const {
        data: tiles,
        opportunity_relation_id,
      } = await OpportunityContactsAPI.attachChildContactToOpportunity(
        opportunityId,
        data,
      )

      dispatch(
        setItemToTiles({
          item: { ...tiles, entity_type: item?.entity_type },
        }),
      )

      dispatch(
        setRelation({
          id: opportunity_relation_id,
          data: {
            opportunity_relation_id,
            opportunity_relation: item.opportunity_relation,
            entity_type: item.entity_type,
            entity_id: item.id,
          },
        }),
      )

      return { opportunity_relation_id, item }
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const detachContactFromOpportunity = createAsyncThunk<
  {
    item: BaseItem
    clients_relations: number[]
    properties_relations: number[]
  },
  DetachContactFromProjectArgs,
  AsyncThunkAPI
>(
  'opportunity/detachContact',
  async ({ opportunityId, item }, { rejectWithValue, dispatch }) => {
    const data = {
      entity_id: item.id,
      entity_type: item.entity_type,
      opportunity_relation_id: item.opportunity_relation_id,
    }
    try {
      dispatch(
        removeItemFromIds({
          id: item.opportunity_relation_id,
        }),
      )
      dispatch(removeItemFromTiles({ item }))
      dispatch(
        removeContact({
          id: item.opportunity_relation_id,
          entity_type: item.entity_parent_type,
        }),
      )

      dispatch(removeRelation({ id: item.opportunity_relation_id }))

      const {
        clients_relations,
        properties_relations,
      } = await OpportunityContactsAPI.detachContactFromOpportunity(
        opportunityId,
        data,
      )

      return { item, clients_relations, properties_relations }
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const updateOpportunityContactRelation = createAsyncThunk<
  UpdateProjectContactRelationArgs['data'],
  UpdateProjectContactRelationArgs,
  AsyncThunkAPI
>(
  'opportunity/updateContactRelation',
  async ({ data, opportunityId }, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setRelation({ id: data.opportunity_relation_id, data }))
      await OpportunityContactsAPI.updateContactOpportunityRelation(
        opportunityId,
        data,
      )

      return data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const getRelationContactToOpportunity = createAsyncThunk<
  { properties: []; contacts: [] },
  number,
  AsyncThunkAPI
>(
  'opportunity/getRelationContactToOpportunity',
  async (opportunityId, { rejectWithValue }) => {
    try {
      const {
        data,
      } = await OpportunityContactsAPI.getRelationContactToOpportunity(
        opportunityId,
      )

      return data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const setOpportunityClientsPosition = createAsyncThunk<
  { data: [] },
  SetPositionArgs & { opportunityId: number },
  AsyncThunkAPI
>(
  'opportunity/setClientsPosition',
  async (
    { opportunityId, sourceIndex, destinationIndex },
    { rejectWithValue, dispatch, getState },
  ) => {
    const state = getState().orcatec.opportunityContactsSlice.data.tiles.client

    const reorderedItems = reorderItems(state, sourceIndex, destinationIndex)

    try {
      dispatch(updateOrder({ client: reorderedItems }))
      const data = formatRequestData(reorderedItems, 'client_relation_id')
      await OpportunityContactsAPI.setClientsPositions(opportunityId, data)

      return data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const setOpportunityPropertiesPosition = createAsyncThunk<
  { data: [] },
  SetPositionArgs & { opportunityId: number },
  AsyncThunkAPI
>(
  'opportunity/setPropertiesPosition',
  async (
    { opportunityId, sourceIndex, destinationIndex },
    { rejectWithValue, dispatch, getState },
  ) => {
    const state = getState().orcatec.opportunityContactsSlice.data.tiles
      .property

    const reorderedItems = reorderItems(state, sourceIndex, destinationIndex)
    try {
      dispatch(updateOrder({ property: reorderedItems }))
      const data = formatRequestData(reorderedItems, 'property_relation_id')
      await OpportunityContactsAPI.setPropertiessPositions(opportunityId, data)

      return data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const opportunitySetPosition = createAsyncThunk<
  { data: [] },
  {
    opportunityId: number
    entity_type: EntityType
  },
  AsyncThunkAPI
>(
  'opportunity/setPosition',
  async ({ entity_type, opportunityId }, { rejectWithValue, getState }) => {
    const state = getState().orcatec.opportunityContactsSlice.data.tiles[
      entity_type
    ]

    try {
      const type =
        entity_type === EntityType.CLIENT
          ? 'client_relation_id'
          : 'property_relation_id'
      const data = formatRequestData(state, type)
      if (entity_type === EntityType.CLIENT) {
        await OpportunityContactsAPI.setClientsPositions(opportunityId, data)
      } else {
        await OpportunityContactsAPI.setPropertiessPositions(
          opportunityId,
          data,
        )
      }

      return data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const addChildContactToOpportunity = createAsyncThunk<
  null,
  AddChildContactToProjectArgs,
  AsyncThunkAPI
>(
  'opportunity/addChildContact',
  async (data, { rejectWithValue, dispatch }) => {
    const { idToAttach, item, typeToAttach, opportunityId } = data

    try {
      if (typeToAttach && item.type === ClientType.ORGANIZATION) {
        await attachContactToOrganization(item.id, idToAttach, '')
      } else {
        if (item.entity_type === EntityType.PROPERTY) {
          await attachClientOrOrganization(item.id, idToAttach, { note: '' })
        }

        if (item.entity_type === EntityType.CLIENT) {
          if (!typeToAttach) {
            await attachPropertyToContact(item.id, idToAttach, { note: '' })
          }
        }
      }

      dispatch(
        invalidateOpportunityParentTree({
          opportunityId: opportunityId,
          parent_entity_id: item.entity_id,
          parent_entity_type: item.entity_parent_type,
        }),
      )
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const invalidateOpportunityParentTree = createAsyncThunk<
  object,
  InvalidateParentTreeArgs,
  AsyncThunkAPI
>(
  'opportunity/invalidateParentTree',
  async (data, { rejectWithValue, dispatch }) => {
    const { parent_entity_id, parent_entity_type, opportunityId } = data

    try {
      const res = await OpportunityContactsAPI.getParentContactById(
        opportunityId,
        {
          entity_type: parent_entity_type,
          entity_id: parent_entity_id,
        },
      )

      const { contacts, entities } = normalizeData([res.data])

      const { opportunity_relation_id, entity_type } = res.data

      dispatch(
        setContact({
          id: opportunity_relation_id,
          entity_type,
          item: Object.values(contacts[entity_type])[0],
        }),
      )

      dispatch(setRelations(entities.relations))

      return data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const selectParentOpportunityContact = createAsyncThunk<
  { checked: boolean; item: BaseItem },
  { checked: boolean; item: BaseItem; opportunityId: number },
  AsyncThunkAPI
>('opportunity/selectParentContact', async (data, { rejectWithValue }) => {
  const req = {
    main_relation_id: data.item.opportunity_relation_id,
    entity_type: data.item.entity_type,
    display: data.checked,
  }

  try {
    await OpportunityContactsAPI.selectParentContact(data.opportunityId, req)

    return data
  } catch (error) {
    return rejectWithValue(error.response.data)
  }
})

startAppListening({
  matcher: isAnyOf(
    detachContactFromOpportunity.fulfilled,
    attachChildToOpportunity.fulfilled,
    selectParentOpportunityContact.fulfilled,
    setOpportunityClientsPosition.fulfilled,
    setOpportunityPropertiesPosition.fulfilled,
    addContactToOpportunity.fulfilled,
    invalidateOpportunityParentTree.fulfilled,
  ),
  effect: (action, { dispatch }) => {
    if (
      isDetachContactAction(action) ||
      isAttachChildContactAction(action) ||
      isParentSelectedAction(action)
    ) {
      const { item } = action.payload

      dispatch(
        opportunitySetPosition({
          entity_type: item.entity_type,
          opportunityId: action?.meta?.arg?.opportunityId,
        }),
      )
    }
  },
})

const isDetachContactAction = (
  action: any,
): action is typeof detachContactFromOpportunity.fulfilled => {
  return action.type === detachContactFromOpportunity.fulfilled.type
}

const isAttachChildContactAction = (
  action: any,
): action is typeof attachChildToOpportunity.fulfilled => {
  return action.type === attachChildToOpportunity.fulfilled.type
}

const isParentSelectedAction = (
  action: any,
): action is typeof selectParentOpportunityContact.fulfilled => {
  return action.type === selectParentOpportunityContact.fulfilled.type
}

/* const isAddContactAction = (
  action: any,
): action is typeof addContactToOpportunity.fulfilled.type => {
  return action.type === addContactToOpportunity.fulfilled.type
}

const isUpdatePropertyOrderAction = (
  action: any,
): action is typeof setOpportunityPropertiesPosition.fulfilled => {
  return action.type === setOpportunityPropertiesPosition.fulfilled.type
}

const isUpdateClientOrderAction = (
  action: any,
): action is typeof setOpportunityClientsPosition.fulfilled.type => {
  return action.type === setOpportunityClientsPosition.fulfilled.type
}

const isInvalidateParentAction = (
  action: any,
): action is typeof invalidateOpportunityParentTree.fulfilled.type => {
  return action.type === invalidateOpportunityParentTree.fulfilled.type
} */

const selectContactsIds = (state: AppStateType) =>
  state.orcatec.opportunityContactsSlice.data.contacts.allIds

const selectContacts = (state: AppStateType) =>
  state.orcatec.opportunityContactsSlice.data.contacts

export const selectOpportunitySummaryContact = (state: AppStateType) =>
  state.orcatec.opportunityContactsSlice.data.tiles

const selectRelations = (state: AppStateType) =>
  state.orcatec.opportunityContactsSlice.data.entities.relations

const selectSlice = (state: AppStateType) =>
  state.orcatec.opportunityContactsSlice

const selectPropertyTiles = (state: AppStateType) =>
  state.orcatec.opportunityContactsSlice.data.tiles.property

const selectContactTiles = (state: AppStateType) =>
  state.orcatec.opportunityContactsSlice.data.tiles.client

const selectIsModalOpen = (state: AppStateType) =>
  state.orcatec.opportunityContactsSlice.open

const selectIsHasContacts = (state: AppStateType) =>
  state.orcatec.opportunityContactsSlice.data.contacts.allIds.length

const selectStatus = (state: AppStateType) =>
  state.orcatec.opportunityContactsSlice.status

const selectCounts = (state: AppStateType) =>
  state.orcatec.opportunityContactsSlice.data.contacts.counts

export const selectCountsByParentId = id =>
  createSelector(selectCounts, counts => counts[id])

const selectProcessing = (state: AppStateType) =>
  state.orcatec.opportunityContactsSlice.in_process

export const selectIsProjectHasContacts = createSelector(
  selectIsHasContacts,
  bool => bool,
)

export const selectProcessItem = createSelector(selectProcessing, item => item)

export const selectContactStatus = createSelector(
  selectStatus,
  status => status,
)

export const selectOpportunityContactsModalOpen = createSelector(
  selectIsModalOpen,
  isOpen => isOpen,
)

export const selectOpportunityContactIds = createSelector(
  [selectContactsIds],
  ids => ids,
)

export const selectProjectContacts = createSelector(
  [selectContactsIds, selectContacts],
  (ids, contacts) =>
    ids.map(({ id, entity_type }) => contacts[entity_type][id]),
)

export const selectContactbyId = (id: number, entity_type: EntityType) =>
  createSelector([selectContacts], contacts =>
    Object.values(contacts[entity_type][id]),
  )

export const selectRelationById = (id: number) =>
  createSelector([selectRelations], relations => relations[id])

export const selectOpportunityContactsRelations = createSelector(
  selectRelations,
  relations => relations,
)

export const selectProjectContactSlice = () =>
  createSelector(selectSlice, slice => slice)

export const selectPrimaryProjectProperty = createSelector(
  selectPropertyTiles,
  properties =>
    properties.find(property => property?.is_primary) ||
    properties?.filter(item => item.display)?.[0],
)

export const selectPrimaryProjectClient = createSelector(
  selectContactTiles,
  clients =>
    clients.find(client => client?.is_primary) ||
    clients?.filter(item => item.display)?.[0],
)

export const selectPrimaryOpportunityContacts = createSelector(
  [selectContactTiles, selectPropertyTiles],
  (clients, properties) => ({
    client:
      clients.find(client => client?.is_primary) ||
      clients?.filter(item => item.display)?.[0],
    property:
      properties.find(property => property?.is_primary) ||
      properties?.filter(item => item.display)?.[0],
  }),
)

export const selectProjectContactsTiles = createSelector(
  [selectContactTiles, selectPropertyTiles],
  (clients, properties) => ({
    clients,
    properties,
  }),
)

export default opportunityContacts.reducer

export const {
  toggleContactSection,
  onChangeSelectedItem,
  removeItemFromTiles,
  setItemToCounts,
  updateOrder,
  setItemToIds,
  setContact,
  setRelation,
  setItemToTiles,
  removeItemFromIds,
  removeContact,
  removeRelation,
  setEntitiesToTiles,
  setRelations,
  resetOpportunityContactSliceReducer,
  setItemInProcess,
  onChangeSelectedParentItem,
  setContacts,
} = opportunityContacts.actions
