import { useEffect, useState } from 'react'
import {
  Content,
  Section,
  Wrapper,
  ContentContainer,
  ContentContainerInner,
  MobileContacts,
} from './EditProject.styles'
import Heading from './components/Heading'
import CustomerInfo from './components/CustomerInfo'
// import { ContactSummary } from 'features/Project/Contacts'
import ProjectContent from './components/ProjectContent'
import { useAppDispatch, useAppSelector } from 'store/Orcatec/hooks'
import { selectProjectSlice } from 'features/Project/slices/projectSlice'
import ProjectName from './components/ProjectName'
import {
  selectProject,
  selectProjectSettings,
} from 'features/Project/projectSelectors'
import { ProjectStatusGroup as IProjectStatus } from 'features/Project/types'
import Templates from './components/Templates'
import SignatureBlock from 'containers/MainContent/Orcatec/Proposals/components/Form/SignatureBlock/SignatureBlock'
import TagsBlock from 'containers/MainContent/Orcatec/Proposals/components/Form/TabsContent/TabInfoBlock/TagsBlock/TagsBlock'
import ProjectForms from './components/ProjectForms'
import {
  // selectCompany,
  selectCompanyTimezone,
} from 'store/Orcatec/selectors/company'
import moment from 'moment-timezone'
import SubMenu from 'containers/MainContent/Orcatec/components/SubMenu'
import { WorkOrder } from '../WorkOrder/WorkOrder'
import { ContactSummary } from 'features/Project/Contacts'
import { ManageContacts } from 'features/Project/Contacts/ManageContacts/ManageContacts'
// import { Companies } from 'types/Company'
import { WorkOrderAPI } from '../WorkOrder/api'
import {
  selectWorkOrderSlice,
  setWorkOrderListTotal,
} from 'features/Project/slices/projectWorkOrdersSlice'
import { OpportunityStatus } from 'features/CRM/components/Opportunity'
import { selectUserPermissionsByModule } from 'features/Settings/UsersAndGroups/permissionSlice'
import { ModuleName } from 'features/Settings/UsersAndGroups'
import { CRMPermissions } from 'features/Settings/UsersAndGroups/constants/submodule_permissions/crm/types'

enum Tabs {
  GENERAL,
  WORK_ORDER,
  INVOICES,
}

export const EditProject = () => {
  const dispatch = useAppDispatch()

  const { editContract } = useAppSelector(selectProjectSlice)
  const { total: woTotal } = useAppSelector(selectWorkOrderSlice)
  // const company = useAppSelector(selectCompany)
  const project = useAppSelector(selectProject)
  const projectSettings = useAppSelector(selectProjectSettings)
  const companyTimezone = useAppSelector(selectCompanyTimezone)
  const permissions = useAppSelector(
    selectUserPermissionsByModule(ModuleName.CRM),
  )

  const [activeTab, setActiveTab] = useState<Tabs>(Tabs.GENERAL)

  const getData = async () => {
    const res = await WorkOrderAPI.getList({
      proposal_id: project.id,
    })
    dispatch(setWorkOrderListTotal(res.meta.total))
  }

  useEffect(() => {
    if (project.id) getData()
  }, [project.id, activeTab])

  const handleChangeTab = (id: Tabs) => {
    setActiveTab(id)
  }

  const contractDate = project?.contract_date
    ? moment(project?.contract_date)
        .tz(companyTimezone)
        .format('MM/DD/YYYY [at] h:mma (z)')
    : ''

  const contractInfo = {
    signature: project?.signature,
    ip_client: project?.ip_client,
    signature_date: project?.signature_date,
    contract_date: project?.contract_date,
    proposal_status_changed_by: project?.proposal_status_changed_by,
    signatory_name: project?.signatory_name,
  }

  // const disabled = project.status !== IProjectStatus.Estimate && !editContract

  const renderContent = () => {
    if (activeTab === Tabs.GENERAL) {
      return (
        <>
          <MobileContacts>
            <Section>
              <ManageContacts
                projectId={project.id}
                isEditContract={editContract}
              />
              <ContactSummary isEditContract={editContract} />
            </Section>
          </MobileContacts>

          <Section>
            <ProjectForms />
          </Section>

          {projectSettings?.show_templates && (
            <Section>
              <Templates
                disabled={project.status !== IProjectStatus.Estimate}
              />
            </Section>
          )}

          <Section>
            {projectSettings?.show_tags && <TagsBlock id={project?.id} />}
          </Section>

          <Section>
            <ProjectContent />
          </Section>

          {projectSettings?.show_information && <CustomerInfo />}

          <Section>
            {!!contractDate &&
              !moment(contractInfo?.signature_date).isSame(
                moment(contractInfo?.contract_date),
              ) && (
                <div
                  style={{ textAlign: 'center', marginBottom: 10 }}
                >{`Changed to "Contract" status ${!!contractInfo.proposal_status_changed_by &&
                  `by ${contractInfo.proposal_status_changed_by} `}on ${contractDate}`}</div>
              )}

            {projectSettings?.require_signature &&
              [
                IProjectStatus.Contract,
                IProjectStatus.Completed,
                IProjectStatus.Canceled,
              ].includes(project.status) &&
              project?.activeTabId === project?.signed_tab_id && (
                <SignatureBlock
                  contractInfo={contractInfo}
                  editProjectView
                  projectStatus={project?.status}
                />
              )}
          </Section>
        </>
      )
    }
    if (activeTab === Tabs.WORK_ORDER) {
      return <WorkOrder />
    }
  }

  const menuItems = [
    {
      id: 0,
      title: 'General',
    },
    {
      id: 1,
      title: 'Work Orders',
      counter: woTotal,
    },
  ]

  return (
    <Wrapper>
      {!!project.opportunity?.id &&
        !!permissions[CRMPermissions.CRM_CAN_VIEW_OPPORTUNITIES_LIST] && (
          <OpportunityStatus
            opportunityId={project.opportunity?.id}
            counter={project?.opportunity?.count_proposals || 0}
          />
        )}

      <Heading />

      <ContentContainer>
        <ContentContainerInner>
          <ProjectName
            name={project.name}
            disabled={activeTab === Tabs.WORK_ORDER}
          />
        </ContentContainerInner>
        {/* {[Companies.SafetyNey, Companies.HVAC, Companies.Sony].includes(
          company.id,
        ) && ( */}
        <ContentContainerInner style={{ padding: '0 20px' }}>
          <SubMenu
            activeItem={activeTab}
            menuItemsList={menuItems}
            onChange={handleChangeTab}
          />
        </ContentContainerInner>
        {/* )} */}
        <Content>
          <ContentContainerInner>{renderContent()}</ContentContainerInner>
        </Content>
      </ContentContainer>
    </Wrapper>
  )
}
