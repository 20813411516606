import { Select, TextArea, TextField } from 'components/UIKit'
import {
  BoardStage,
  Opportunity as IOpportunity,
} from 'features/CRM/crm.interface'
import Notes from 'containers/MainContent/Orcatec/components/Notes'
import useIndustries from 'hooks/useIndustries'
import { useEffect } from 'react'
import styled from 'styled-components'
import { Error } from 'types/Error'
import BoardStagesList from '../BoardStagesList'
// import { useBoard } from 'features/CRM/hooks/useBoard'
import { useDefaultIndustry } from 'hooks/useDefaultIndustry'
import { Spin } from 'antd'
import Tags from 'containers/MainContent/Orcatec/components/Tag/Tag'
// import { OpportunityAssociations } from './components'
import { useAppSelector } from 'store/Orcatec/hooks'
import {
  selectBoardsList,
  selectStagesByBoard,
} from 'features/CRM/store/crmSlice'
import { EventTypes } from 'components/UI/EventTypes/EventTypes'
import { EventSources } from 'components/UI/EventSources/EventSources'
import { OpportunityContacts } from './components/Contacts/OpportunityContacts'
import { selectCompanyTechnicians } from 'store/Orcatec/reducers/settings/company'

interface Props {
  data: IOpportunity
  onChange: (field: Partial<IOpportunity>) => void
  error: Error<IOpportunity> | null
  loading: boolean
}

export const Opportunity = ({ data, error, loading, onChange }: Props) => {
  const boardList = useAppSelector(selectBoardsList)
  const stages = useAppSelector(state =>
    selectStagesByBoard(state, data.board_id),
  )

  const industries = useIndustries()
  const defaultIndustry = useDefaultIndustry()
  // const { technicians: assignee } = useTechnicians()
  const assignee = useAppSelector(selectCompanyTechnicians)

  function handleOpportunityChange(e) {
    const { name, value } = e.target

    onChange({ [name]: value ?? null })
  }

  function handleTagsChange(tagIds: number[]) {
    if (data.id) return

    onChange({
      tags: tagIds,
    })
  }

  useEffect(() => {
    if (defaultIndustry) {
      onChange({
        industry_id: defaultIndustry,
      })
    }
  }, [defaultIndustry])

  useEffect(() => {
    onChange({
      stage_id: stages[0].id,
    })
  }, [data?.board_id])

  return (
    <Spin spinning={loading}>
      <Wrapper>
        <TextField
          name='name'
          label='Opportunity Name'
          error={error?.name}
          value={data?.name}
          onChange={handleOpportunityChange}
          required
          maxLength={255}
        />

        <Select
          required
          name='board_id'
          label='Board'
          value={data?.board_id}
          error={error?.board_id}
          onChange={handleOpportunityChange}
          options={boardList}
          fieldNames={{ label: 'title', value: 'id' }}
        />

        <LabelWrapper>
          <Label>Board&apos;s Stages</Label>
          <BoardStagesList
            data={stages}
            activeId={data?.stage_id}
            onChange={(stageId: BoardStage['id']) =>
              handleOpportunityChange({
                target: {
                  name: 'stage_id',
                  value: stageId,
                },
              })
            }
          />
        </LabelWrapper>

        <Row>
          <EventSources
            value={data?.source_id}
            onChange={value =>
              handleOpportunityChange({ target: { name: 'source_id', value } })
            }
          />

          <EventTypes
            value={data?.type_id}
            onChange={value =>
              handleOpportunityChange({ target: { name: 'type_id', value } })
            }
          />
        </Row>

        <LabelWrapper>
          <Label>Tags</Label>
          <Tags
            route={`crm/opportunities/`}
            id={data.id}
            showContent={true}
            onChange={handleTagsChange}
            maxWidth='368px'
          />
        </LabelWrapper>

        <Row>
          <Select
            fieldNames={{ label: 'full_name', value: 'id' }}
            allowClear
            label='Assigned to'
            name='user_id'
            value={data?.user_id}
            options={assignee}
            onChange={handleOpportunityChange}
            listItemHeight={32}
            error={error?.user_id}
          />

          <Select
            required
            fieldNames={{ label: 'name', value: 'id' }}
            name='industry_id'
            label='Industry'
            value={data?.industry_id}
            error={error?.industry_id}
            onChange={handleOpportunityChange}
            options={industries}
          />
        </Row>

        {data?.id ? (
          <LabelWrapper>
            <Label>Notes</Label>
            <Notes route={`/opportunities/${data.id}/notes`} />
          </LabelWrapper>
        ) : (
          <TextArea
            name='notes'
            label='Notes'
            error={error?.notes}
            value={data?.notes}
            onChange={handleOpportunityChange}
          />
        )}

        <div style={{ marginTop: 30 }}>
          <OpportunityContacts opportunityId={data?.id} />
          {/*  <OpportunityAssociations
            opportunityId={data.id}
            contactId={data.contact_id}
            onChangeContact={(id: number | null) =>
              onChange({ contact_id: id })
            }
            onChangeContactRelation={handleOpportunityChange}
            propertyId={data.property_id}
            onChangeProperty={(id: number | null) =>
              onChange({ property_id: id })
            }
            onChangePropertyRelation={handleOpportunityChange}
          /> */}
        </div>
      </Wrapper>
    </Spin>
  )
}

/* const areEqual = (prevProps: Props, nextProps: Props) => {
  return (
    prevProps.data?.id === nextProps.data?.id &&
    prevProps.data?.name === nextProps.data?.name &&
    prevProps.data?.user_id === nextProps.data?.user_id &&
    prevProps.data?.notes === nextProps.data?.notes &&
    prevProps.data?.industry_id === nextProps.data?.industry_id &&
    prevProps.data?.type_id === nextProps.data?.type_id &&
    prevProps.data?.source_id === nextProps.data?.source_id &&
    prevProps.data?.board_id === nextProps.data?.board_id &&
    prevProps.data?.stage_id === nextProps.data?.stage_id &&
    prevProps.data.contact_relation === nextProps.data.contact_relation &&
    prevProps.error === nextProps.error &&
    prevProps.loading === nextProps.loading
  )
} */

// export const Opportunity = memo(OpportunityComponent, areEqual)

const Wrapper = styled.div`
  & > *:not(:last-child) {
    margin-bottom: 1rem;
  }
`

const Row = styled.div`
  display: grid;
  grid-template-columns: 368px 368px;
  align-items: center;
  gap: 16px;
`

const Label = styled.p`
  font-size: 12px;
  color: #808080;
  line-height: 1.5;
  margin-bottom: 3px;
`

const LabelWrapper = styled.div``
