import { AppointmentType } from 'types/Appointment'
import { useAppSelector } from 'store/Orcatec/hooks'

const useTypes = (includeDeleted?: boolean) => {
  const appTypes: AppointmentType[] = useAppSelector(
    state => state.orcatec?.company?.types,
  )

  return appTypes?.filter(type => (includeDeleted ? type : !type.deleted_at))
}

export default useTypes
