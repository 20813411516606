import { getProjectSettings } from 'api/Project'
import { useEffect, useState } from 'react'

export const useDefaultIndustry = () => {
  const [defaultIndustry, setDefaultIndustry] = useState(null)

  useEffect(() => {
    async function getData() {
      const { data } = await getProjectSettings()

      setDefaultIndustry(data?.settings?.industry_id)
    }

    getData()
  }, [])

  return defaultIndustry
}
