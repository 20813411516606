import { EntityType } from 'features/CRM/components/Opportunity/types'
import { ClientType } from 'types/Client'
import { Wrapper } from './AttachedContacts.styles'
import { ContactsBlock } from './components/ContactsBlock/ContactsBlock'
import { PropertiesBlock } from './components/PropertiesBlock/PropertiesBlock'
import { SetPositionArgs } from 'features/CRM/store/opportunityContactsSlice'

export interface Contact {
  id: number
  name: string
  type: ClientType
  opportunity_relation: string | null
}

export interface Property {
  id: number
  full_address: string
  opportunity_relation: string | null
}

interface Props {
  contacts: Contact[]
  properties: Property[]
  onAttach: (entity: EntityType) => void
  onDrop: (dropResult: SetPositionArgs) => void
}

export const AttachedContacts = ({
  contacts,
  properties,
  onAttach,
  onDrop,
}: Props) => {
  return (
    <Wrapper>
      <ContactsBlock
        data={contacts}
        onAttach={() => onAttach(EntityType.CLIENT)}
        onDrop={onDrop}
      />

      <PropertiesBlock
        data={properties}
        onAttach={() => onAttach(EntityType.PROPERTY)}
        onDrop={onDrop}
      />
    </Wrapper>
  )
}
