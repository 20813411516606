import { User, UserRole } from 'types/User'
import { createSelector } from '@reduxjs/toolkit'
import { AppStateType } from '../../index'

export const selectAuthUser = (state: AppStateType) => state.orcatec.user.me
export const selectIndustries = (state: AppStateType) =>
  state.orcatec.user?.me?.industries

export const selectUserRole = (state: AppStateType) =>
  state.orcatec?.user?.me?.role_id
export const isSuperAdmin = createSelector(selectUserRole, roleId =>
  [UserRole.DEFAULT_SUPER_ADMIN, UserRole.SUPER_ADMIN].includes(roleId),
)
export const isOwner = createSelector(
  selectUserRole,
  roleId =>
    roleId === UserRole.OWNER ||
    roleId === UserRole.SUPER_ADMIN ||
    roleId === UserRole.DEFAULT_SUPER_ADMIN,
)

export const isAdminOrOwner = createSelector(
  selectUserRole,
  roleId =>
    roleId === UserRole.ADMIN ||
    roleId === UserRole.OWNER ||
    roleId === UserRole.SUPER_ADMIN ||
    roleId === UserRole.DEFAULT_SUPER_ADMIN,
)
export const isAdminOrOwnerOrAM = createSelector(
  selectUserRole,
  roleId =>
    roleId === UserRole.ADMIN ||
    roleId === UserRole.OWNER ||
    roleId === UserRole.SUPER_ADMIN ||
    roleId === UserRole.DEFAULT_SUPER_ADMIN ||
    roleId === UserRole.ACCOUNT_MANAGER,
)
export const isAdminOrOwnerOrA = createSelector(
  selectUserRole,
  roleId =>
    roleId === UserRole.ADMIN ||
    roleId === UserRole.OWNER ||
    roleId === UserRole.SUPER_ADMIN ||
    roleId === UserRole.DEFAULT_SUPER_ADMIN ||
    roleId === UserRole.ACCOUNTANT,
)
export const isWorker = createSelector(
  selectUserRole,
  roleId => roleId === UserRole.WORKER,
)

export const getUserEmails = (state: AppStateType) =>
  state.orcatec?.user?.list?.data.map((el: User) => el.email)
