import OpportunityContactsTree from './OpportunityContactsTree'
import { Button } from 'components/UIKit'
import { useEffect, useRef } from 'react'
import { TreeHandler } from 'features/Project/Contacts/ContactsTree'
import styled from 'styled-components'
import {
  getRelationContactToOpportunity,
  selectOpportunitySummaryContact,
  setOpportunityClientsPosition,
  setOpportunityPropertiesPosition,
  SetPositionArgs,
} from 'features/CRM/store/opportunityContactsSlice'
import { useAppDispatch, useAppSelector } from 'store/Orcatec/hooks'
import { EntityType } from 'features/Project/types'
import { AttachedContacts } from './components/AttachedContacts/AttachedContacts'

interface Props {
  opportunityId: number
}

export const OpportunityContacts = ({ opportunityId }: Props) => {
  const ref = useRef<TreeHandler>(null)
  const dispatch = useAppDispatch()

  const { client, property } = useAppSelector(selectOpportunitySummaryContact)

  // console.log(client, property)
  // const isModalOpen = useAppSelector(selectProjectWOModalOpen)

  useEffect(() => {
    if (opportunityId) dispatch(getRelationContactToOpportunity(opportunityId))
  }, [opportunityId])

  const onAttach = (entity: EntityType) => ref.current?.onAttach(entity)
  //
  const onManage = () => ref.current?.onManage()

  const onDrop = (dropResult: SetPositionArgs) => {
    const { entity_type, sourceIndex, destinationIndex } = dropResult

    if (entity_type === EntityType.CLIENT) {
      dispatch(
        setOpportunityClientsPosition({
          opportunityId,
          sourceIndex,
          destinationIndex,
        }),
      )
    } else {
      dispatch(
        setOpportunityPropertiesPosition({
          opportunityId,
          sourceIndex,
          destinationIndex,
        }),
      )
    }
  }

  return (
    <div>
      <Heading>
        <h6>Opportunity is associated with</h6>

        {!!(client.length || property.length) && (
          <Button type='link' style={{ fontWeight: 500 }} onClick={onManage}>
            Manage Contacts
          </Button>
        )}
      </Heading>
      <OpportunityContactsTree ref={ref} opportunityId={opportunityId} />

      <AttachedContacts
        contacts={client.filter(contact => contact.display)}
        properties={property.filter(property => property.display)}
        onAttach={onAttach}
        onDrop={onDrop}
      />
    </div>
  )
}

const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
