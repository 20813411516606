import styled from 'styled-components'

export const Wrapper = styled.div`
  margin-top: 10px;

  & > * {
    margin-bottom: 24px;
  }
`
export const Block = styled.div`
  border-radius: 6px;
  border: 1px solid #d6dbed;
`
export const ContactsHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
`
export const ContactsContent = styled.div`
  border-top: 1px solid #d6dbed;
`

export const Buttons = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`

export const Title = styled.p`
  color: ${props => props.theme.font.secondary};
  font-weight: 500;
  font-size: 16px;
`

export const ToggleButton = styled.p`
  cursor: pointer;
`

export const ContactRow = styled.p`
  display: flex;
  gap: 5px;
  align-items: center;
  padding: 10px 16px;
  background-color: #fff;
  border-bottom: 1px solid #d6dbed;
`

export const Primary = styled.span`
  border-radius: 4px;
  background-color: #1ab2e2;
  color: #fff;
  font-weight: 500;
  padding: 1px 8px;
  font-size: 12px;
  margin-right: 20px;
`

export const EmptyText = styled.p`
  font-size: 12px;
  color: #8c8c8c;
`

export const EmptyContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Actions = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`

export const List = styled.div``
export const Name = styled.div`
  overflow: hidden;
`
export const Relation = styled.p`
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
