import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
`

export const ProjectInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  flex: 1 0 300px;
`

export const Title = styled.h4`
  margin-bottom: 0;
`
