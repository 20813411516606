import {
  DeleteOutlined,
  InfoCircleOutlined,
  PlusCircleFilled,
  PlusOutlined,
} from '@ant-design/icons'
import { useSortable } from '@dnd-kit/sortable'
import { Popover, Tooltip } from 'antd'
// import { Popconfirm } from 'antd'
import { TextArea, TextField } from 'components/UIKit'
import { DISPATCH_COLORS } from 'constants/colors'
import { Column } from 'features/KanbanBoard/type'
import useOnClickOutside from 'hooks/useOnClickOutside'
import React, { ChangeEvent, useRef, useState } from 'react'
import { SketchPicker } from 'react-color'
import styled from 'styled-components'
import { Error } from 'types/Error'

interface Props {
  data: Column
  error: Error | null
  children: React.ReactNode
  editMode: boolean
  isFirst: boolean
  isLast: boolean
  cardCount: number
  onError?: (columnId: number, property: string) => void
  onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  onDelete: () => void
  onAddItem?: () => void
  onAddColumn?: (position: 'before' | 'after') => void
}

export const BoardColumn = React.memo(
  ({
    data,
    error,
    children,
    editMode,
    isFirst,
    isLast,
    cardCount,
    onError,
    onChange,
    onDelete,
    onAddItem,
    onAddColumn,
  }: Props) => {
    const { setNodeRef } = useSortable({
      id: `column-${data.id}`,
      data: {
        type: 'column',
      },
    })
    const modalPickerRef = useRef<HTMLDivElement>(null)

    useOnClickOutside(modalPickerRef, () => {
      showColorPicker(false)
    })

    const [colorPicker, showColorPicker] = useState(false)

    return (
      <Wrapper>
        {editMode && (
          <ChangeColor>
            <LinkText onClick={() => showColorPicker(true)}>
              Change the stage color
            </LinkText>

            {colorPicker && (
              <PickerWrapper ref={modalPickerRef}>
                <SketchPicker
                  color={data.color}
                  onChangeComplete={color =>
                    onChange({ target: { value: color.hex, name: 'color' } })
                  }
                  presetColors={DISPATCH_COLORS}
                />
              </PickerWrapper>
            )}
          </ChangeColor>
        )}

        <Heading isFirst={isFirst} isLast={isLast} color={data.color}>
          <Title>{data.title}</Title>
          {!!data.total && <Counter>({data.total})</Counter>}

          {!!data.description && !editMode && (
            <Popover
              title='Description'
              content={<PopoverContent>{data.description}</PopoverContent>}
            >
              <InfoCircleOutlined
                style={{
                  fontSize: 16,
                  marginLeft: 'auto',
                }}
              />
            </Popover>
          )}
        </Heading>

        {!editMode ? (
          <Content ref={setNodeRef}>
            {!cardCount ? (
              <EmptyPlaceholder>Drop here</EmptyPlaceholder>
            ) : (
              children
            )}

            {!!onAddItem && (
              <AddButton className='addButton' onClick={onAddItem}>
                <PlusOutlined />
              </AddButton>
            )}
          </Content>
        ) : (
          <Edit>
            <EditContent>
              <TextField
                name='name'
                label='Stage name'
                value={data.title}
                onChange={onChange}
                error={error?.name}
                onBlur={e => {
                  if (!e.target.value) onError?.(data.id, 'name')
                }}
                maxLength={255}
              />
              <DescriptionSection>
                <TextArea
                  name='description'
                  label='Stage description'
                  value={data.description}
                  onChange={onChange}
                  maxLength={3000}
                />
                <Tooltip title='You have the option to include a description for this column. It will be shown in the info icon next to the column name.'>
                  <InfoCircleOutlined />
                </Tooltip>
              </DescriptionSection>

              <AddColumn position='left' className='addColumn'>
                <Tooltip title='Add Stage' mouseLeaveDelay={0}>
                  <StyledPlustIcon onClick={() => onAddColumn?.('before')} />
                </Tooltip>
              </AddColumn>

              <AddColumn position='right' className='addColumn'>
                <Tooltip title='Add Stage' mouseLeaveDelay={0}>
                  <StyledPlustIcon onClick={() => onAddColumn?.('after')} />
                </Tooltip>
              </AddColumn>
            </EditContent>
            <DeleteSection>
              {/* <Popconfirm title='Delete stage?'> */}
              <div
                style={{
                  display: 'inline-flex',
                  gap: 5,
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={onDelete}
              >
                <DeleteOutlined />
                <span>Delete Stage</span>
              </div>
              {/* </Popconfirm> */}
            </DeleteSection>
          </Edit>
        )}
      </Wrapper>
    )
  },
)

BoardColumn.displayName = 'BoardColumn'

const Edit = styled.div``
const EditContent = styled.div`
  padding: 0 10px 30px;
  padding-top: 0;
  position: relative;

  & > *:not(:last-child) {
    margin-bottom: 12px;
  }
`

const StyledPlustIcon = styled(PlusCircleFilled)`
  font-size: 24px;
  color: #595959;
  cursor: pointer;

  &:hover {
    color: #262626;
  }
`

const AddColumn = styled.div<{ position: 'left' | 'right' }>`
  position: absolute;
  right: ${props => (props.position === 'left' ? 'auto' : '-16px')};
  left: ${props => (props.position === 'right' ? 'auto' : '-16px')};
  bottom: -20px;
  z-index: 1;
  margin-bottom: 0;
  opacity: 0;
  visibility: hidden;
  margin-bottom: 0 !important;
`

const DeleteSection = styled.div`
  background-color: #d9d9d9;
  display: flex;
  justify-content: center;
  /* margin-top: 355px; */
  padding: 5px;
  position: absolute;
  bottom: 0;
  width: 100%;
`

const Wrapper = styled.div`
  background-color: #f0f0f0;
  position: relative;

  &:hover {
    .addButton,
    .addColumn {
      opacity: 1;
      visibility: visible;
    }
  }
`
const Heading = styled.div<{
  isFirst: boolean
  isLast: boolean
  color: string
}>`
  height: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  position: relative;
  border-top: 8px solid ${props => props.color};
  background-color: inherit;
  position: sticky;
  top: 0;
  z-index: 1;
  padding: 0 10px 0 20px;

  &::before {
    content: '';
    position: absolute;
    height: 0;
    width: 0;
    left: 0;
    top: 0;
    border: 25px solid transparent;
    border-left: 8px solid #fff;
    display: ${props => (props.isFirst ? 'none' : 'block')};
  }

  &::after {
    content: '';
    position: absolute;
    height: 0;
    width: 0;
    right: -32px;
    top: 0;
    border: 25px solid transparent;
    border-left: 8px solid #f0f0f0;
    display: ${props => (props.isLast ? 'none' : 'block')};
  }
`
const Content = styled.div`
  padding: 8px;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;
`

const Title = styled.p`
  font-weight: 500;
  font-size: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const Counter = styled.span``

const EmptyPlaceholder = styled.div`
  height: 113.3px;
  background-color: #e9ecef;
  border: 1px dashed #ced4da;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #495057;
  cursor: pointer;
`

const ChangeColor = styled.div`
  padding: 0 10px;
  position: relative;
`

const PickerWrapper = styled.div`
  position: absolute;
  top: 40px;
  right: 10px;
  z-index: 1000;
  & .sketch-picker {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
`
const LinkText = styled.p`
  color: #1890ff;
  text-align: right;
  cursor: pointer;
  text-decoration: underline;
`

const AddButton = styled.div`
  padding: 4px 0 2px;
  text-align: center;
  cursor: pointer;
  background-color: #f0f0f0;
  border-radius: 4px;
  opacity: 0;
  visibility: hidden;

  &:hover {
    background-color: #e7e6e6;
  }
`

const DescriptionSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 5px;
`

const PopoverContent = styled.p`
  white-space: pre-line;
`
