import Modal from 'containers/MainContent/Orcatec/components/UI/Modal'
import { Opportunity } from './Opportunity'
import styled from 'styled-components'
import { Button } from 'components/UIKit'
import { useEffect, useState } from 'react'
import { Opportunity as IOpportunity } from 'features/CRM/crm.interface'
import { useOpportunity } from 'features/CRM/hooks/useOpportunity'
import crmAPI from 'features/CRM/api'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import { Error } from 'types/Error'
import { Badge, Popconfirm, TablePaginationConfig, Tabs } from 'antd'
import { getBoardList, selectStagesByBoard } from 'features/CRM/store/crmSlice'
import { useAppDispatch, useAppSelector } from 'store/Orcatec/hooks'
import { resetOpportunityContactSliceReducer } from 'features/CRM/store/opportunityContactsSlice'
import { LinkedProjects } from './components/LinkedProjects/LinkedProjects'
import { CreateProject } from 'features/Project/components/CreateProject/CreateProject'
import { Project } from 'features/Project/types'
import { AttachProject } from 'features/Project/components/AttachProject/AttachProject'
import { selectUserPermissionsByModule } from 'features/Settings/UsersAndGroups/permissionSlice'
import { ModuleName } from 'features/Settings/UsersAndGroups'
import { GeneralTableParams } from 'containers/MainContent/Orcatec/components/Table/types'

interface Props {
  id: number | null
  stageId?: number
  isNew: boolean
  onClose: (isCancel?: boolean) => void
  onSaved?: () => void
}

export const OpportunityModal = ({
  id,
  stageId,
  isNew,
  onClose,
  onSaved,
}: Props) => {
  const dispatch = useAppDispatch()
  const {
    opportunity: initialData,
    loading: opportunityLoading,
  } = useOpportunity(id, stageId)

  const [opportunity, setOpportunity] = useState<IOpportunity>(
    initialData as IOpportunity,
  )
  const [loading, setLoading] = useState(false)
  const [attaching, setAttaching] = useState(false)
  const [error, setError] = useState<Error<IOpportunity> | null>(null)

  const [projectModal, showProjectModal] = useState(false)
  const [attachModal, showAttachModal] = useState(false)
  const [projects, setProjects] = useState([])
  const [tableMeta, setTableMeta] = useState()

  const stages = useAppSelector(state =>
    selectStagesByBoard(state, opportunity.board_id),
  )
  const { project_can_read, project_can_create } = useAppSelector(
    selectUserPermissionsByModule(ModuleName.PROJECT),
  )

  async function getData(params?: GeneralTableParams) {
    if (!id) return

    setLoading(true)

    const { data, meta } = await crmAPI.getOportunityProjects(id, params)

    setLoading(false)
    setTableMeta(meta)
    setProjects(data)
  }

  useEffect(() => {
    return () => {
      dispatch(resetOpportunityContactSliceReducer())
    }
  }, [])

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    setOpportunity(initialData as IOpportunity)
  }, [initialData])

  function handleOpportunityChange(field: Partial<IOpportunity>) {
    setOpportunity(prev => ({
      ...prev,
      ...field,
    }))

    if (error) {
      setError(null)
    }
  }

  async function handleSave() {
    if (!opportunity.name) {
      return setError({
        name: 'Opportunity name is required field',
      })
    }

    setLoading(true)
    try {
      !opportunity.id
        ? await crmAPI.createOpportunity(opportunity)
        : await crmAPI.updateOpportunity(opportunity)

      setError(null)
      onClose()
      onSaved?.()
    } catch (error) {
      if (error?.response?.data?.errors?.stage_id) {
        await dispatch(
          getBoardList(
            !error?.response?.data?.errors?.board_id
              ? opportunity.board_id
              : undefined,
          ),
        )

        if (error?.response?.data?.errors?.board_id) {
          onClose?.()
          return openNotificationWithIcon('error', {
            message: 'Oops',
            description: 'Selected board not found',
          })
        }

        setOpportunity(prev => ({
          ...prev,
          stage_id: stages[0]?.id,
        }))

        return openNotificationWithIcon('error', {
          message: 'Oops',
          description: 'Selected stage not found. Please choose another',
        })
      }

      openNotificationWithIcon('error', {
        message: 'Oops',
        description: error?.response?.data?.message,
      })
    } finally {
      setLoading(false)
    }
  }

  async function handleDelete() {
    setLoading(true)
    try {
      await crmAPI.deleteOpportunity(opportunity.id, { hard_delete: false })
    } catch (error) {
      openNotificationWithIcon('error', {
        message: 'Oops',
        description: error?.response?.data?.message,
      })
    }
    setError(null)
    onClose()
    onSaved?.()
  }

  async function handleAttachProject(project: Project) {
    setAttaching(true)

    if (attaching) return

    try {
      await crmAPI.attachProject(opportunity.id, project.id)
      getData()
      showAttachModal(false)

      openNotificationWithIcon('success', {
        message: 'Project has been attached successfully',
      })
    } catch (error) {
      openNotificationWithIcon('error', {
        message: 'Oops',
        description: error?.response?.data?.message || 'Something went wrong',
      })
    } finally {
      setAttaching(false)
    }
  }

  async function handleProjectDetach(projectId: number) {
    setAttaching(true)

    if (attaching) return

    try {
      await crmAPI.detachProject(opportunity.id, projectId)
      getData()
    } catch (error) {
      openNotificationWithIcon('error', {
        message: 'Oops',
        description: error?.response?.data?.message || 'Something went wrong',
      })
    } finally {
      setAttaching(false)
    }
  }

  function handleTableChange(pagination: TablePaginationConfig) {
    getData({
      page: pagination.current,
      per_page: pagination.pageSize,
    })
  }

  return (
    <>
      <Modal
        open
        title={opportunity.id ? 'Update Opportunity' : 'Create Opportunity'}
        width={800}
        onCancel={() => onClose(true)}
        keyboard={false}
        bodyStyle={{ height: '80vh', overflowY: 'auto' }}
        footer={
          <Footer>
            {opportunity.id && (
              <Popconfirm
                title='Are you sure you want to delete this opportunity?'
                onConfirm={handleDelete}
              >
                <Button danger loading={loading}>
                  Delete
                </Button>
              </Popconfirm>
            )}

            <Button
              style={{ marginLeft: 'auto' }}
              onClick={() => onClose(true)}
            >
              Cancel
            </Button>
            <Button type='primary' onClick={handleSave} loading={loading}>
              Save
            </Button>
          </Footer>
        }
      >
        {isNew || !project_can_read ? (
          <Opportunity
            data={opportunity}
            onChange={handleOpportunityChange}
            error={error}
            loading={loading || opportunityLoading}
          />
        ) : (
          <Tabs
            // tabPosition='left'
            defaultActiveKey='1'
            items={[
              {
                label: <Badge count={0}>General information</Badge>,
                key: '1',
                children: (
                  <Opportunity
                    data={opportunity}
                    onChange={handleOpportunityChange}
                    error={error}
                    loading={loading || opportunityLoading}
                  />
                ),
              },
              {
                label: (
                  <Badge
                    count={tableMeta?.total}
                    size='small'
                    color='#1890ff'
                    offset={[8, 2]}
                  >
                    Projects
                  </Badge>
                ),
                key: '2',
                children: (
                  <LinkedProjects
                    data={projects}
                    pagination={tableMeta}
                    loading={loading}
                    onTableChange={handleTableChange}
                    tableMeta={tableMeta}
                    onCreate={() => {
                      if (!project_can_create) {
                        return openNotificationWithIcon('warning', {
                          message:
                            'You have no permissions to perform this action',
                        })
                      }
                      showProjectModal(true)
                    }}
                    onAttach={() => showAttachModal(true)}
                    onDetach={handleProjectDetach}
                  />
                ),
              },
            ]}
          />
        )}
      </Modal>

      {projectModal && (
        <Modal
          title='Create Project'
          style={{ minWidth: '400px' }}
          open
          onCancel={() => showProjectModal(false)}
          footer={null}
        >
          <CreateProject
            route={`/crm/opportunities/${opportunity.id}/proposals`}
            initialData={{
              industry_id: opportunity.industry_id,
              type_id: opportunity.type_id,
              source_id: opportunity.source_id,
              user_id: opportunity.user_id,
            }}
            onCancel={() => showProjectModal(false)}
            onCreated={(data: Project) => {
              window.open(`/projects/${data?.id}`, '_blank')
              getData()
              showProjectModal(false)
            }}
          />
        </Modal>
      )}

      {attachModal && (
        <Modal
          title='Attach Project'
          open
          onCancel={() => showAttachModal(false)}
          footer={null}
          width={600}
        >
          <AttachProject
            onClose={() => showAttachModal(false)}
            onAttach={handleAttachProject}
            loading={attaching}
          />
        </Modal>
      )}
    </>
  )
}

export const Footer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  /* justify-content: flex-end; */
`
