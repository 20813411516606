import { CheckOutlined } from '@ant-design/icons'
import { Spin, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { ProjectAPI } from 'api/Project'
import { Button, InfoPlate, Search } from 'components/UIKit'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import useOnClickOutside from 'hooks/useOnClickOutside'
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'

interface Props {
  onClose: () => void
  onAttach: (project: SearchProject) => void
  loading: boolean
}

interface SearchProject {
  id: number
  code: string
  name: string | null
  is_opportunity: boolean
}

export const AttachProject = ({ loading, onClose, onAttach }: Props) => {
  const ref = useRef(null)

  const [search, setSearch] = useState('')
  const [dropdown, showDropdown] = useState(false)
  const [searching, setSearching] = useState(false)
  const [projects, setProjects] = useState<SearchProject[]>([])
  const [selectedProject, setSelectedProject] = useState<SearchProject | null>(
    null,
  )

  useOnClickOutside(ref, () => showDropdown(false))

  useEffect(() => {
    if (search.length > 2) {
      searchProject()
    }
  }, [search])

  function handleSearch(e: ChangeEvent<HTMLInputElement>) {
    const { value } = e.target

    setSearch(value)

    if (value.length < 3 && projects.length) {
      setProjects([])
    }
  }

  async function searchProject() {
    setSearching(true)
    const data = await ProjectAPI.searchProject({ search })
    setSearching(false)
    setProjects(data)
  }

  function handleRowClick(record: SearchProject) {
    return {
      onClick: () => {
        if (!!record.is_opportunity) {
          openNotificationWithIcon('error', {
            message:
              'This project is attached to another Opportunity and cannot be chosen.',
          })

          return
        }

        setSelectedProject?.(record)
        showDropdown(false)
        setSearch(record.code)
      },
    }
  }

  const columns: ColumnsType<SearchProject> = useMemo(
    () => [
      {
        title: 'ProjectID',
        dataIndex: 'code',
      },
      {
        title: 'Project Name',
        dataIndex: 'name',
      },
      {
        title: 'With Opportunity',
        dataIndex: 'is_opportunity',
        width: 150,
        align: 'center',
        render: (hasOpportunity: boolean) =>
          hasOpportunity && <CheckOutlined />,
      },
    ],
    [],
  )

  return (
    <Wrapper ref={ref}>
      <Search
        label='Search Project'
        value={search}
        onChange={handleSearch}
        placeholder='Search by Project ID or Project name...'
        onClick={() => showDropdown(true)}
      />

      {dropdown && (
        <Dropdown>
          <Text>
            {search.length > 2
              ? 'Select project from the list'
              : 'Type at least 3 characters to start searching'}
          </Text>

          <Spin spinning={searching}>
            <Table
              dataSource={projects}
              columns={columns}
              size='small'
              pagination={false}
              loading={searching}
              scroll={{ y: 250 }}
              onRow={handleRowClick}
              rowClassName='pointer'
            />
          </Spin>
        </Dropdown>
      )}

      <div style={{ margin: '20px 0 30px' }}>
        <InfoPlate type='info' size='small'>
          Please note: A project can have only one attached opportunity.
        </InfoPlate>
      </div>

      <Buttons>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          loading={loading}
          type='primary'
          disabled={!selectedProject}
          onClick={() => {
            if (!selectedProject) return

            onAttach(selectedProject)
          }}
        >
          Attach
        </Button>
      </Buttons>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
`
const Dropdown = styled.div`
  position: absolute;
  top: 2px;
  padding: 5px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  background-color: #fff;
  z-index: 1;
  top: 55px;
`

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
`

const Text = styled.p``
