import { useEffect, useState } from 'react'
import { getPropertyById, createProperty, updateProperty } from 'api/Property'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import { getAddressWithUnit } from 'helpers/getAddressWithUnit'
import { Property } from 'types/Property'
import axios from 'axios'
import { useAppSelector } from 'store/Orcatec/hooks'
import states from 'helpers/states'

export type PropertyRequestType = 'get' | 'create' | 'update' | ''

const useProperty = ({
  id,
  payload,
  type,
  heandleCloseRouteModal,
}: {
  id: number
  type: PropertyRequestType
  payload: Property
  heandleCloseRouteModal?: () => void
}): [boolean, Property | null, { [key: string]: string } | null] => {
  const [loading, setLoading] = useState(false)
  const [property, setProperty] = useState<Property | null>(null)
  const [error, setError] = useState(null)
  const owner_user_id = useAppSelector(state => state.orcatec.company.owner_id)
  useEffect(() => {
    const doRequest = async () => {
      setLoading(true)

      try {
        const data = await (type === 'get'
          ? getPropertyById(id)
          : type === 'create'
          ? createProperty({
              ...payload,
              owner_user_id,
              full_address: `${getAddressWithUnit(payload)}, ${
                payload.city
              }, ${states[payload.state] || payload.state}, ${
                payload.postcode
              }`,
            })
          : updateProperty(id, {
              ...payload,
              full_address: `${getAddressWithUnit(payload)}, ${
                payload.city
              }, ${states[payload.state] || payload.state}, ${
                payload.postcode
              }`,
            }))

        setProperty({ ...data, tags: data.tags || [] })

        if (type !== 'get') {
          openNotificationWithIcon('success', {
            message: `Property has been ${type}d successfully`,
            description: '',
            key: 'property-update-success',
          })
        }
        await heandleCloseRouteModal?.()
      } catch (error) {
        if (axios.isAxiosError(error)) {
          setError(error?.response?.data)
        }
      }

      setLoading(false)
    }

    if (type) doRequest()
  }, [type])
  return [loading, property, error]
}

export default useProperty
