import React, { FC } from 'react'
import {
  LocationIcon,
  OrganizationsIcon,
  UserIcon,
} from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
import { ItemInfoCount } from '../ContactItem/ContactItem.styles'
import { useAppSelector } from 'store/Orcatec/hooks'
import { selectCountsByParentId } from 'features/CRM/store/opportunityContactsSlice'
import {
  EntityType,
  ContactWithAdditionalData,
  PropertyWithAdditionalData,
} from 'features/Project/types'
import { ClientType } from 'types/Client'
import { Tooltip } from 'antd'

interface IProps {
  parent: ContactWithAdditionalData | PropertyWithAdditionalData
}

const title = {
  contact: 'Contacts',
  properties: 'Properties',
  organizations: 'Organization',
}

export const ContactsCounter: FC<IProps> = ({ parent }) => {
  const item = useAppSelector(selectCountsByParentId(parent.id))

  if (parent.entity_type === EntityType.PROPERTY) {
    return (
      <ItemInfoCount>
        <div>
          <Tooltip mouseEnterDelay={0} title={title.contact}>
            <span>
              <UserIcon width={16} height={16} color='black' />
            </span>
          </Tooltip>
          <span>{item?.countClient || 0}</span>
        </div>
        <div>
          <Tooltip mouseEnterDelay={0} title={title.organizations}>
            <span>
              <OrganizationsIcon width={16} height={16} color='black' />
            </span>
          </Tooltip>
          <span>{item?.countOrganization || 0}</span>
        </div>
      </ItemInfoCount>
    )
  }

  if (
    parent.entity_type === EntityType.CLIENT &&
    parent.type === ClientType.ORGANIZATION
  ) {
    return (
      <ItemInfoCount>
        <div>
          <Tooltip mouseEnterDelay={0} title={title.properties}>
            <span>
              <LocationIcon width={16} height={16} color='black' />
            </span>
          </Tooltip>
          <span>{item?.countProperties || 0}</span>
        </div>
        <div>
          <Tooltip mouseEnterDelay={0} title={title.contact}>
            <span>
              <UserIcon width={16} height={16} color='black' />
            </span>
          </Tooltip>
          <span>{item?.countClient || 0}</span>
        </div>
      </ItemInfoCount>
    )
  }

  return (
    <ItemInfoCount>
      <div>
        <Tooltip mouseEnterDelay={0} title={title.properties}>
          <span>
            <LocationIcon width={16} height={16} color='black' />
          </span>
        </Tooltip>
        <span>{item?.countProperties || 0}</span>
      </div>
      <div>
        <Tooltip mouseEnterDelay={0} title={title.organizations}>
          <span>
            <OrganizationsIcon width={16} height={16} color='black' />
          </span>
        </Tooltip>
        <span>{item?.countOrganization || 0}</span>
      </div>
    </ItemInfoCount>
  )
}
