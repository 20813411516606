import React, { useState } from 'react'
import {
  Buttons,
  ContactRow,
  ContactsContent,
  ContactsHeading,
  EmptyContent,
  EmptyText,
  Primary,
  Title,
  ToggleButton,
  Block,
  Actions,
  List,
  Name,
  Relation,
} from '../../AttachedContacts.styles'
import { Badge } from 'antd'
import { DownOutlined, RightOutlined } from '@ant-design/icons'
import { Button } from 'components/UIKit'
import {
  DragIcon,
  IconUserv2,
  OrganizationsIcon,
} from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
import { Contact } from '../../AttachedContacts'
import { ClientType } from 'types/Client'
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd'
import { EntityType } from 'features/CRM/components/Opportunity/types'
import { SetPositionArgs } from 'features/CRM/store/opportunityContactsSlice'

interface Props {
  data: Contact[]
  onAttach: () => void
  onDrop: (result: SetPositionArgs) => void
}

export const ContactsBlock = ({ data, onAttach, onDrop }: Props) => {
  const [open, setOpen] = useState(true)

  const handleDragEnd = (result: DropResult) => {
    const { destination, source } = result

    if (
      !destination ||
      (destination.droppableId === source.droppableId &&
        destination.index === source.index)
    ) {
      return
    }

    onDrop({
      sourceIndex: source.index,
      destinationIndex: destination.index,
      entity_type: EntityType.CLIENT,
    })
  }

  const renderContent = () => {
    return data.length ? (
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable direction='vertical' droppableId='projctContacts'>
          {provided => (
            <List ref={provided.innerRef} {...provided.droppableProps}>
              {data?.map((contact, idx) => (
                <Draggable
                  draggableId={`contact-${contact.id}-${idx}`}
                  key={`contact-${contact.id}-${idx}`}
                  index={idx}
                >
                  {provided => (
                    <ContactRow
                      key={contact.id}
                      ref={provided?.innerRef}
                      {...provided?.draggableProps}
                    >
                      {contact.type === ClientType.ORGANIZATION ? (
                        <span
                          style={{
                            width: 20,
                            alignSelf: 'flex-start',
                            position: 'relative',
                            top: -2,
                          }}
                        >
                          <OrganizationsIcon width={20} height={20} />
                        </span>
                      ) : (
                        <IconUserv2
                          style={{
                            fontSize: 20,
                            position: 'relative',
                            alignSelf: 'flex-start',
                            top: 2,
                          }}
                        />
                      )}
                      <Name>
                        {contact.opportunity_relation && (
                          <Relation>{contact.opportunity_relation}</Relation>
                        )}
                        <p>{contact.name}</p>
                      </Name>

                      <Actions>
                        {idx === 0 && <Primary>Primary</Primary>}

                        {data?.length > 1 && (
                          <span {...provided?.dragHandleProps}>
                            <DragIcon style={{ cursor: 'grab' }} />
                          </span>
                        )}
                      </Actions>
                    </ContactRow>
                  )}
                </Draggable>
              ))}

              {provided.placeholder}
            </List>
          )}
        </Droppable>
      </DragDropContext>
    ) : (
      <EmptyContent>
        <EmptyText>No contacts yet</EmptyText>
        {!data.length && (
          <Button onClick={onAttach} type='link'>
            + Attach Contact
          </Button>
        )}
      </EmptyContent>
    )
  }

  if (!data.length)
    return (
      <Block>
        <ContactsHeading>
          <div>
            <Title>Contacts</Title>
            <EmptyText>No contacts yet</EmptyText>
          </div>

          <Buttons>
            <Button onClick={onAttach} type='link'>
              + Attach Contact
            </Button>
          </Buttons>
        </ContactsHeading>
      </Block>
    )

  return (
    <Block>
      <ContactsHeading>
        <Badge
          count={data?.length}
          size='small'
          color='#096DD9'
          offset={[10, 5]}
        >
          <Title>Contacts</Title>
        </Badge>

        <Buttons>
          {!!data.length && (
            <Button onClick={onAttach} type='link'>
              + Attach Contact
            </Button>
          )}

          <ToggleButton onClick={() => setOpen(!open)}>
            {!open ? <RightOutlined /> : <DownOutlined />}
          </ToggleButton>
        </Buttons>
      </ContactsHeading>

      {open && <ContactsContent>{renderContent()}</ContactsContent>}
    </Block>
  )
}
