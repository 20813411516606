import { Tooltip } from 'antd'
import { BoardStage } from 'features/CRM/crm.interface'
import styled from 'styled-components'

interface Props {
  activeId: BoardStage['id']
  data: BoardStage[]
  onChange: (stageId: BoardStage['id']) => void
}

export const BoardStagesList = ({ data, activeId = 1, onChange }: Props) => {
  return (
    <Wrapper>
      {data.map(stage => (
        <Tooltip title={stage.name} key={stage.id} mouseLeaveDelay={0}>
          <Stage
            key={stage.id}
            color={stage.color}
            active={stage.id === activeId}
            onClick={() => onChange(stage.id)}
          >
            <Title>{stage.name}</Title>
          </Stage>
        </Tooltip>
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(auto-fit, minmax(88px, 1fr));
  align-items: center;
  font-weight: 400;
  padding-right: 5px;
`

const Stage = styled.div<{ color: string; active: boolean }>`
  font-size: 12px;
  background-color: ${props =>
    props.active ? `${props.color}` || '#40A9FF' : '#D9D9D9'};
  color: ${props => (props.active ? '#fff' : 'initial')};
  cursor: pointer;
  padding: 2px 4px 2px 10px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    height: 0;
    width: 0;
    left: 0;
    top: 0;
    border: 12px solid transparent;
    border-left: 5px solid #fff;
    display: block;
  }

  &::after {
    content: '';
    position: absolute;
    height: 0;
    width: 0;
    right: -17px;
    top: 0;
    border: 12px solid transparent;
    border-left: 5px solid
      ${props => (props.active ? `${props.color}` || '#40A9FF' : '#D9D9D9')};
    display: block;
  }
`

const Title = styled.p`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
