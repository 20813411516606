import { useState } from 'react'
import { Tooltip, Select, DatePicker, Radio, Checkbox } from 'antd'
import moment from 'moment-timezone'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
import classNames from 'classnames'
import { useAppDispatch, useAppSelector } from 'store/Orcatec/hooks'
import {
  IconLineItems,
  IconBarItems,
} from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
import styled from 'styled-components'
import {
  RedoOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons'
import { AccessControl } from 'features/Settings/UsersAndGroups/components/AccessControl/AccessControl'
import { DispatchPermission } from 'features/Settings/UsersAndGroups'
import { TextField } from 'components/UIKit'
import {
  columnFilterUpdated,
  selectMatrixLog,
} from 'features/Dispatch/dispatchSlice'
import { tytumHvacDispetchers } from 'constants/hardcodes'
import { selectAuthUser } from 'store/Orcatec/selectors/user'

const { RangePicker } = DatePicker

enum PageView {
  DAYVIEW = 1,
  WEEKVIEW,
  LISTVIEW,
}
enum ItemView {
  BAR = 1,
  ROW,
}

interface Props {
  currentDate: string | string[]
  isWorker: boolean
  itemsView: ItemView
  pageView: PageView
  startDayOfWeek: number
}

export const Header = ({
  // changeType,
  toggleTimeoff,
  dayBefore,
  dayNext,
  onChangeDate,
  today,
  currentDate,
  isMobileDevice,
  // isWorker,
  onChageView,
  onSizeChange,
  startDayOfWeek,
  onZoomIn,
  onZoomOut,
  onZoomReset,
  visibleHeader,
  setShowAppointment,
  showAppointment,
  pageView,
  heandlePrintModal,
  printModalVisible,
  changeItemsView,
  itemsView,
  loading,
  dispatchSize,
}: Props) => {
  const dispatch = useAppDispatch()

  const [dates, setDates] = useState([])
  const isSocalCompany = useAppSelector(
    state => state.orcatec.company.id === 264,
  )
  const { filters } = useAppSelector(selectMatrixLog)
  const authUser = useAppSelector(selectAuthUser)

  const disabledDate = current => {
    if (!dates || dates.length === 0) {
      return current && current < moment().startOf('day')
    }

    const tooLate = dates[0] && current.diff(dates[0], 'days') > 6
    const tooEarly = current < moment(dates[0])
    return tooEarly || tooLate
  }

  return (
    <div className={classNames('header', visibleHeader ? 'sticky_header' : '')}>
      <CreateButtons>
        <AccessControl
          additionalAccess={pageView !== PageView.LISTVIEW}
          scopes={[DispatchPermission.DISPATCH_CAN_CREATE_EVENT]}
        >
          <MainButton
            disabled={showAppointment}
            title='Create appointment'
            onClick={() => setShowAppointment(true)}
          />
        </AccessControl>

        <AccessControl
          additionalAccess={pageView !== PageView.LISTVIEW}
          scopes={[DispatchPermission.DISPATCH_CAN_CREATE_EVENT]}
        >
          <MainButton title='Add time Off' onClick={() => toggleTimeoff()} />
        </AccessControl>

        {isSocalCompany && pageView === PageView.DAYVIEW && (
          <MainButton
            title='Log Sheets'
            onClick={() => heandlePrintModal(!printModalVisible)}
          />
        )}
      </CreateButtons>

      {pageView !== PageView.LISTVIEW && (
        <div className='calendar d-flex-center'>
          <button className='btn-arrow' onClick={() => dayBefore()}>
            <i className='fas fa-angle-left'></i>
          </button>
          {pageView !== PageView.WEEKVIEW ? (
            <div style={{ display: 'flex', gap: 5 }}>
              <DatePicker
                format='MMM D (ddd), YYYY'
                onChange={e => onChangeDate(e)}
                value={moment(currentDate /* , 'YYYY-MM-DD' */)}
                allowClear={false}
                suffixIcon={<i className='mdi mdi-calendar-multiselect'></i>}
              />
              <button className='btn-arrow' onClick={() => dayNext()}>
                <i className='fas fa-angle-right'></i>
              </button>
              <div className='d-flex-center'>
                <button
                  className='btn  btn-custom-info today'
                  onClick={() => today()}
                >
                  Today
                </button>
              </div>
            </div>
          ) : (
            <div style={{ display: 'flex', gap: 5 }}>
              <RangePicker
                value={
                  Array?.isArray(currentDate)
                    ? currentDate
                    : [
                        moment()
                          .isoWeekday(startDayOfWeek)
                          ?.add(
                            moment().isoWeekday() < startDayOfWeek ? -1 : 0,
                            'week',
                          ),
                        moment()
                          .isoWeekday(startDayOfWeek)
                          ?.add(
                            moment().isoWeekday() < startDayOfWeek ? -1 : 0,
                            'week',
                          )
                          .add(6, 'd'),
                      ]
                }
                onChange={moment => onChangeDate(moment)}
                onCalendarChange={val => setDates(val)} //fires on start/end date changed
                format='MM/DD/YYYY'
                disabledDate={disabledDate}
                onOpenChange={() => setDates([])}
                suffixIcon={<i className='mdi mdi-calendar-multiselect'></i>}
                allowClear={false}
              />
              <button className='btn-arrow' onClick={() => dayNext()}>
                <i className='fas fa-angle-right'></i>
              </button>
            </div>
          )}
        </div>
      )}

      {/* <div
        style={{
          display: 'flex',
          gap: 10,
          alignItems: 'center',
        }}
      > */}
      {/* <span>Dispatch View:</span> */}
      <Tooltip title='Dispatch View' mouseLeaveDelay={0}>
        <Select
          value={pageView}
          style={{ width: 140 }}
          onChange={onChageView}
          options={[
            {
              value: 1,
              label: 'Day view',
            },
            {
              value: 2,
              label: 'Week view',
            },
            {
              value: 3,
              label: 'List view',
            },
            {
              value: 4,
              label: 'Time Off list',
            },
          ].filter((
            //TEMP HARDCODE
            opt,
          ) =>
            tytumHvacDispetchers.includes(authUser.id)
              ? [1, 2].includes(opt.value)
              : opt,
          )}
          disabled={loading}
        />
      </Tooltip>
      {/* </div> */}

      {[PageView.DAYVIEW, PageView.WEEKVIEW].includes(pageView) &&
        !isMobileDevice && (
          <ZoomButton>
            <Tooltip title='Zoom In'>
              <ZoomInOutlined
                style={{ fontSize: 24, cursor: 'pointer' }}
                onClick={onZoomIn}
              />
            </Tooltip>
            <Tooltip title='Zoom Out'>
              <ZoomOutOutlined
                style={{ fontSize: 24, cursor: 'pointer' }}
                onClick={onZoomOut}
              />
            </Tooltip>
            <Tooltip title='Reset Zoom'>
              <RedoOutlined
                style={{ fontSize: 24, cursor: 'pointer' }}
                onClick={onZoomReset}
              />
            </Tooltip>
          </ZoomButton>
        )}

      {[PageView.DAYVIEW].includes(pageView) && (
        <Tooltip title='Dispatch size' mouseLeaveDelay={0}>
          <Radio.Group onChange={onSizeChange} value={dispatchSize}>
            <Radio value='S'>S</Radio>
            <Radio value='M'>M</Radio>
            <Radio value='L'>L</Radio>
          </Radio.Group>
        </Tooltip>
      )}

      {[PageView.WEEKVIEW].includes(pageView) && !isMobileDevice && (
        <ViewButtons>
          <Tooltip title='Grid View'>
            <IconWrapper
              active={itemsView === ItemView.BAR}
              onClick={() => changeItemsView(ItemView.BAR)}
            >
              <IconBarItems />
            </IconWrapper>
          </Tooltip>
          <Tooltip title='List View'>
            <IconWrapper
              active={itemsView === ItemView.ROW}
              onClick={() => changeItemsView(ItemView.ROW)}
            >
              <IconLineItems />
            </IconWrapper>
          </Tooltip>
        </ViewButtons>
      )}

      {[PageView.WEEKVIEW].includes(pageView) && (
        <div
          style={{
            display: 'flex',
            gap: 10,
            alignItems: 'center',
          }}
        >
          <TextField
            value={filters?.techName}
            onChange={e =>
              dispatch(columnFilterUpdated({ techName: e.target.value }))
            }
            width='300px'
            placeholder='Search by Column title, Tech name, title, description..'
          />

          <Checkbox
            checked={filters?.showEmptyColumns}
            onChange={e =>
              dispatch(
                columnFilterUpdated({ showEmptyColumns: e.target.checked }),
              )
            }
          >
            Show empty columns
          </Checkbox>
        </div>
      )}
    </div>
  )
}

interface IconWrapperProps {
  active?: boolean
}

const IconWrapper = styled.div<IconWrapperProps>`
  cursor: pointer;
  & {
    fill: ${props => (props.active ? '#448aff' : 'inherit')};
    stroke: ${props => (props.active ? '#448aff' : 'inherit')};
  }
`

const ZoomButton = styled.div`
  display: flex;
  gap: 15px;

  span {
    color: #000;
    transition: all 0.33s;
    &:hover {
      color: #4285f4;
    }
  }
`
const ViewButtons = styled.div`
  display: flex;
  gap: 15px;

  div {
    transition: all 0.33s;
    &:hover {
      fill: #4285f4;
    }
  }
`

const CreateButtons = styled.div`
  display: flex;
  gap: 15px;
`
