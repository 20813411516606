import { ClientType } from 'types/Client'
import { PropertyType } from 'types/Property'

export enum EntityType {
  CLIENT = 'client',
  PROPERTY = 'property',
}

export enum EntityLevel {
  PARENT = 1,
  CHILD = 2,
  NESTED_CHILD = 3,
}

export interface Rating {
  value: number
  count_votes: number
}

export interface Phone {
  phone: string
  type: string
}

export interface Contact {
  checked: boolean
  created_at: string
  deleted_at: string | null
  display: boolean
  emails: string[]
  // emails: Email[] | null
  entity_type: EntityType
  id: number
  is_primary: boolean
  merged_with: null | {
    name: string
    id: number
    merged_at: string
  }
  name: string
  phones: Phone[] | null
  position: number
  opportunity_relation_id: number
  opportunity_relation: null
  rating: Rating | null
  relation: null
  type: ClientType
}

export interface ProjectProperty {
  address: string
  checked: boolean
  city: string
  created_at: string
  deleted_at: string | null
  display: boolean
  emails: null
  entity_type: EntityType
  full_address: string
  id: number
  is_primary: boolean
  merged_with: null | {
    name: string
    id: number
    merged_at: string
  }
  name: string
  nested_contacts: Contact[]
  nested_properties: ProjectProperty[]
  phones: null
  position: number
  postcode: string
  opportunity_relation_id: number
  opportunity_relation: null
  rating: null
  relation: null | string
  state: string
  text: string
  type: PropertyType
  unit: string | null
}

export interface AdditionalData {
  parent_id: number
  parent_type: EntityType
  level: EntityLevel
  entity_id: number
  index: number
  entity_parent_type: EntityType
  position?: number
  is_primary?: boolean
  entity_type: EntityType
  is_child_modal?: boolean
  display: boolean
  parent_opportunity_relation_id: number
}

export type ContactWithAdditionalData = Contact & AdditionalData
export type PropertyWithAdditionalData = ProjectProperty & AdditionalData
export type IItem = ContactWithAdditionalData | PropertyWithAdditionalData
