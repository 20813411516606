import TableLayout from '../../../../containers/MainContent/Orcatec/Layouts/TableLayout/TableLayout'
import { Button, Search, Select, TextField } from 'components/UIKit'
import {
  ContentSwitcher,
  SwitcherButton,
} from 'features/Project/ProjectLayout/ProjectLayout.styles'
import {
  IconBoard,
  IconTable,
} from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
import KanbanBoard from 'features/KanbanBoard'
import { ChangeEvent, useEffect, useState } from 'react'
import { Card, Column } from 'features/KanbanBoard/type'
import crmAPI from 'features/CRM/api'
import {
  BoardStage,
  CRMBoard as ICRMBoard,
  Opportunity,
} from 'features/CRM/crm.interface'
import { DeleteOutlined, FormOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { Badge, Divider, Space, Spin, Tooltip } from 'antd'
import { IOpportunityCard, OpportunityCard } from './components/OpportunityCard'
import { useAppDispatch, useAppSelector } from 'store/Orcatec/hooks'
import {
  activeBoardIdSet,
  boardColumnAdded,
  boardTitleChanged,
  columnDeleted,
  columnsTotalSet,
  columnsTotalUpdate,
  columnUpdated,
  createBoard,
  deleteBoard,
  errorSet,
  getBoardById,
  resetBoard,
  selectActiveBoard,
  selectBoardsList,
  // selectActiveBoard,
  // selectBoardList,
  selectCRMSlice,
  selectStagesByBoard,
  toggleEditMode,
  updateBoard,
} from 'features/CRM/store/crmSlice'
import { Error } from 'types/Error'
import { DeleteStageModal } from './components/DeleteStageModal/DeleteStageModal'
import useLocalStorage from 'hooks/useLocalStorage'
import { DeleteBoardModal } from './components/DeleteBoardModal/DeleteBoardModal'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import useDebounce from 'hooks/useDebounce'
import { BoardFilters } from './components/BoardFilters/BoardFilters'
import { FilterList } from '@material-ui/icons'

interface BoardFilters {
  user_id: number[]
}

interface Props {
  invalidate: number
  boardView: boolean
  onViewChange: (value: boolean) => void
  onOpen: (params: { id?: number; stageId?: BoardStage['id'] } | null) => void
}

export const CRMBoard = ({
  invalidate,
  onViewChange,
  boardView,
  onOpen,
}: Props) => {
  const dispatch = useAppDispatch()
  const { editMode, status, boardTotals, error: columnError } = useAppSelector(
    selectCRMSlice,
  )
  const activeBoard = useAppSelector(selectActiveBoard)
  const boards = useAppSelector(selectBoardsList)
  const stages = useAppSelector(state =>
    selectStagesByBoard(state, activeBoard?.id),
  )

  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(false)
  const [cards, setCards] = useState<Map<number, Card[]>>()
  const [error, setError] = useState<Error | null>(null)
  const [columnToDelete, setColumnToDelete] = useState<BoardStage | null>(null)
  const [boardToDelete, setBoardToDelete] = useState<ICRMBoard | null>(null)

  const [filters, setFilters] = useState<BoardFilters>({ user_id: [] })
  const [filtersDrawer, showFiltersDrawer] = useState(false)

  const [, setLastActiveBoardId] = useLocalStorage(
    'lastActiveBoardId',
    activeBoard?.id || 1,
  )

  const debouncedSearchValue = useDebounce(search, 800)

  useEffect(() => {
    getData()
  }, [activeBoard?.id, invalidate, debouncedSearchValue, filters])

  async function getData(isLoadMore?: boolean) {
    if (!activeBoard) return

    setLoading(true)
    try {
      const { data } = await crmAPI.getOpportunityBoard(activeBoard?.id, {
        search: debouncedSearchValue || undefined,
        ...filters,
      })

      const totals = data.reduce((totals, value) => {
        totals[value.stage_id] = value.total
        return totals
      }, {})

      dispatch(columnsTotalSet(totals))

      const allCards: Card[] = data
        .flatMap(stage => stage.items)
        .map((opportunity: Opportunity) => ({
          id: opportunity.id,
          column_id: opportunity.stage_id,
          title: opportunity.name,
          assignee: opportunity.assignee,
          source: opportunity.source,
          type: opportunity.type,
          contacts: opportunity.contacts,
          proposals_count: opportunity.proposals_count,
          min_max_total: opportunity.min_max_total,
          is_won: opportunity.is_won,
          is_multi_won: opportunity.is_multi_won,
          //
        }))

      // if (!allCards.length) setDataOver(true)

      setCards(prev => {
        const updatedMap = isLoadMore ? new Map(prev) : new Map()

        allCards.forEach(project => {
          if (!updatedMap.has(project.column_id)) {
            updatedMap.set(project.column_id, [])
          }
          updatedMap.get(project.column_id)?.push(project)
        })

        return updatedMap
      })

      // setPage(page + 1)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  function handleChange(cards: Map<number, Card[]>) {
    setCards(cards)
  }

  function toogleEditBoard() {
    dispatch(toggleEditMode())
  }

  function handleColumnAdd(position?: {
    columnId: number
    place: 'before' | 'after'
  }) {
    // console.log(index)
    dispatch(boardColumnAdded(position))
  }

  function handleColumnChange(
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    columnId: BoardStage['id'],
  ) {
    const { name, value } = e.target

    dispatch(
      columnUpdated({
        columnId,
        fieldsToUpdate: { [name]: value },
      }),
    )
  }

  function handleColumnDelete(column: BoardStage) {
    if (status === 'loading') return

    if (stages?.filter(stage => !stage.delete_action).length === 1) {
      return openNotificationWithIcon('error', {
        message: 'Oops',
        description:
          'You cannot delete the last stage in a board. At least one stage must remain.',
      })
    }

    setColumnToDelete(column)
  }

  function handleBoardTitleChange(e: ChangeEvent<HTMLInputElement>) {
    if (error) setError(null)
    dispatch(boardTitleChanged(e.target.value))
  }

  async function handleUpdateBoard() {
    if (
      !activeBoard ||
      error ||
      (columnError && Object.keys(columnError)?.length)
    )
      return

    await dispatch(
      updateBoard({
        ...activeBoard,
        stages: stages.map((stage, idx) => ({
          ...stage,
          position: idx,
        })),
      }),
    )
    getData()
  }

  async function handleCancel() {
    if (!activeBoard) return

    dispatch(getBoardById(activeBoard.id))
    dispatch(resetBoard())
    getData()
    toogleEditBoard()
  }

  async function handleNewBoardAdd() {
    dispatch(createBoard())
  }

  function handleStageDelete(
    columnId: BoardStage['id'],
    deleteAction: BoardStage['delete_action'],
  ) {
    dispatch(columnDeleted({ columnId, deleteAction }))
    setColumnToDelete(null)
  }

  async function handleBoardDelete(
    boardId: ICRMBoard['id'],
    deleteAction: BoardStage['delete_action'],
  ) {
    const res = await dispatch(deleteBoard({ boardId, deleteAction }))

    if (res.meta.requestStatus === 'rejected') {
      return openNotificationWithIcon('error', {
        message:
          res?.payload?.response?.data?.message || 'Something went wrong',
      })
    }

    setBoardToDelete(null)
    setLastActiveBoardId(deleteAction?.board_id)
  }

  function handleError(columnId: number, property: keyof Column) {
    dispatch(errorSet({ [columnId]: { [property]: 'Stage name is required' } }))
  }

  async function handleDropEvent(event: Card, sourceColumnId: number) {
    const newCards = new Map(cards)
    const newColumnCards = [...(newCards?.get(event.column_id) || [])]
    const projectIndex = newColumnCards.findIndex(card => card.id === event.id)

    newColumnCards[projectIndex] = event

    newCards.set(event.column_id, newColumnCards)

    setCards(newCards)

    try {
      await crmAPI.patchOpportunity(event.id, { stage_id: event.column_id })

      dispatch(
        columnsTotalUpdate({
          source: sourceColumnId,
          dist: event.column_id,
        }),
      )
    } catch (error) {
      openNotificationWithIcon('warning', {
        message: 'Oops',
        description:
          error?.response?.data?.errors.stage_id || 'Something went wrong',
      })
      return Promise.reject(error)
    }
  }

  function handleFiltersChange(filters: BoardFilters) {
    setFilters(filters)
  }

  return (
    <TableLayout
      title={
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 20,
            flexWrap: 'wrap',
          }}
        >
          <p style={{ whiteSpace: 'nowrap' }}>
            {editMode ? 'Edit Board' : 'Board'}
          </p>

          {!editMode ? (
            <Select
              required
              name='board_id'
              value={activeBoard?.id}
              onChange={(e, value) => {
                dispatch(activeBoardIdSet(value))
                setLastActiveBoardId(value)
              }}
              options={boards}
              fieldNames={{ label: 'title', value: 'id' }}
              width='180px'
              dropdownRender={menu => (
                <>
                  {menu}
                  <Divider style={{ margin: '8px 0' }} />
                  <Space style={{ padding: '0 8px 4px' }}>
                    <Button type='link' onClick={handleNewBoardAdd}>
                      + Create Board
                    </Button>
                  </Space>
                </>
              )}
            />
          ) : (
            <TextField
              name='title'
              value={activeBoard?.title}
              onChange={handleBoardTitleChange}
              error={error?.title}
              onBlur={e => {
                if (!e.target.value)
                  setError({ title: 'Board title is required' })
              }}
              maxLength={255}
              width='250px'
            />
          )}

          {!editMode && (
            <Button
              type='primary'
              onClick={() => onOpen(null)}
              loading={status === 'loading'}
            >
              + Add Opportunity
            </Button>
          )}
        </div>
      }
      visible={filtersDrawer}
      onClose={() => showFiltersDrawer(false)}
      sidebarContent={
        <BoardFilters data={filters} onChange={handleFiltersChange} />
      }
      center={
        <>
          {!editMode && (
            <Center>
              <ContentSwitcher>
                <SwitcherButton
                  active={!boardView}
                  onClick={() => onViewChange(false)}
                >
                  <div
                    style={{ display: 'flex', alignItems: 'center', gap: 5 }}
                  >
                    <IconTable style={{ fontSize: 20 }} />
                    Table
                  </div>
                </SwitcherButton>
                <SwitcherButton
                  active={boardView}
                  onClick={() => onViewChange(true)}
                >
                  <div
                    style={{ display: 'flex', alignItems: 'center', gap: 5 }}
                  >
                    <IconBoard style={{ fontSize: 20 }} />
                    Board
                  </div>
                </SwitcherButton>
              </ContentSwitcher>
            </Center>
          )}
        </>
      }
      actions={
        <Actions>
          <div style={{ flex: '0 0 14px', width: 20 }}>
            <Spin spinning={loading} size='small' />
          </div>

          {!editMode ? (
            <>
              <IconWrapper onClick={() => showFiltersDrawer(true)}>
                <Badge count={filters?.user_id?.length}>
                  <Tooltip title='Filters'>
                    <FilterList />
                  </Tooltip>
                </Badge>
              </IconWrapper>

              <Search
                placeholder='Search...'
                value={search}
                onChange={e => setSearch(e.target.value)}
              />
              <Tooltip title='Edit board'>
                <FormOutlined
                  style={{ fontSize: 20, cursor: 'pointer' }}
                  onClick={toogleEditBoard}
                />
              </Tooltip>
            </>
          ) : (
            <>
              <Button onClick={handleCancel}>Cancel</Button>
              <Button
                onClick={handleUpdateBoard}
                type='primary'
                loading={status === 'loading'}
              >
                Save changes
              </Button>

              <Tooltip
                title='Delete Board'
                placement='left'
                mouseLeaveDelay={0}
              >
                <DeleteOutlined
                  style={{ marginLeft: 20, fontSize: 20, cursor: 'pointer' }}
                  onClick={() => {
                    if (boards.length === 1) {
                      return openNotificationWithIcon('error', {
                        message: 'Oops',
                        description:
                          'You cannot delete the last remaining board. At least one board must exist in the system.',
                      })
                    }
                    setBoardToDelete(activeBoard)
                  }}
                />
              </Tooltip>
            </>
          )}
        </Actions>
      }
    >
      <div style={{ marginTop: 10 }}>
        <KanbanBoard
          isLoading={loading || status === 'loading'}
          columns={
            stages
              ?.filter(stage => !stage.delete_action)
              ?.map(stage => ({
                id: stage.id,
                title: stage.name,
                color: stage.color,
                description: stage.description,
                total: boardTotals[stage.id] ?? 0,
              })) || []
          }
          cards={cards}
          editMode={editMode}
          error={columnError}
          onChange={handleChange}
          cardComponent={(cardData: IOpportunityCard) => (
            <OpportunityCard
              data={cardData}
              onClick={() => onOpen({ id: cardData.id })}
            />
          )}
          onAddColumn={handleColumnAdd}
          onColumnChange={handleColumnChange}
          onColumnDelete={handleColumnDelete}
          onAddItem={(columnId: BoardStage['id']) =>
            onOpen({ stageId: columnId })
          }
          onDrop={handleDropEvent}
          onError={handleError}
          // onLoadMore={handleLoadMore}
        />
      </div>

      {columnToDelete && (
        <DeleteStageModal
          stageToDelete={columnToDelete}
          onClose={() => setColumnToDelete(null)}
          onSave={data => handleStageDelete(columnToDelete.id, data)}
        />
      )}

      {boardToDelete && (
        <DeleteBoardModal
          boardToDelete={boardToDelete}
          onClose={() => setBoardToDelete(null)}
          onSave={data => handleBoardDelete(boardToDelete.id, data)}
          isLoading={status === 'loading'}
        />
      )}
    </TableLayout>
  )
}

const Actions = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`

const Center = styled.div`
  width: 300px;

  @media (max-width: 500px) {
    margin-bottom: 10px;
  }
`

const IconWrapper = styled.div`
  cursor: pointer;
`
