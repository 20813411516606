import { useSortable } from '@dnd-kit/sortable'
import { Card } from 'features/KanbanBoard/type'
import styled from 'styled-components'
import { CSS } from '@dnd-kit/utilities'
import { memo } from 'react'
// import { Link } from 'react-router-dom'
// import Avatar from 'containers/MainContent/Orcatec/components/Avatar'
// import { priceToView } from 'helpers/thousandSeparator'

interface Props {
  data: Card
  color?: string
  children: React.ReactNode
}

const BoardCardComponent = ({ data, color, children }: Props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: data.id,
    data: {
      type: 'item',
      item: data,
    },
  })

  return (
    <Wrapper
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      isDragging={isDragging}
      color={color}
      style={{
        transition,
        transform: CSS.Translate.toString(transform),
      }}
    >
      {children}
    </Wrapper>
  )
}

const areEqual = (prevProps: Props, nextProps: Props) => {
  return (
    prevProps.data.id === nextProps.data.id &&
    prevProps.data.column_id === nextProps.data.column_id &&
    prevProps.data.title === nextProps.data.title &&
    prevProps.data.assignee === nextProps.data.assignee &&
    prevProps.data.contact === nextProps.data.contact &&
    prevProps.data.source === nextProps.data.source &&
    prevProps.data.type === nextProps.data.type
  )
}

export const BoardCard = memo(BoardCardComponent, areEqual)

const Wrapper = styled.div<{ isDragging: boolean; color?: string }>`
  padding: 4px 8px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  /* height: 100px; */
  box-shadow: 0px 2px 3px 0px #4140781a;
  background-color: #fff;
  opacity: ${props => (props.isDragging ? 0.5 : 1)};
  /* border-left: 4px solid ${props => props.color}; */
  cursor: pointer;
  touch-action: none;
`
