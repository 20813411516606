import { User } from 'types/User'

interface SelectOption {
  id: number | string
  value: number
  label: string
}
export enum Tabs {
  TEMPLATES,
  BUILDER,
}

type TextfieldType = 'text' | 'email' | 'number'

export interface Component {
  id: string
  defaultValue?: string
  type: string
  required?: boolean
  label: string
  subtype?: TextfieldType
  value?: string | []
  options?: SelectOption[]
  multiple?: boolean
  error?: boolean
  main_field_id: string | null
  uppercase?: boolean
  barcode_scanner?: boolean
  show_in_email: boolean

  //Picture
  without_label?: boolean
  size?: PictureSize
  pictureAlign?: 'flex-start' | 'center' | 'flex-end'
  description?: string
  descriptionSize?: number
  descriptionWeight?: number
  descriptionAlign?: 'left' | 'center' | 'right'
}

export enum PictureSize {
  S = 100,
  M = 200,
  L = 300,
}

export interface CustomFormTemplate {
  id: number
  body: Record<string, Component>
  title: string
  has_forms: boolean
  action?: string
  main_field_id: string | null
  count: number
}

export interface CustomForm extends CustomFormTemplate {
  code: string | null
  completed: boolean
  created_at: string | null
  fill_percentage: number
  is_verified?: boolean
  job_id: number | null
  name: string
  project_id: number | null
  type: string
  updated_at: string | null
  main_field_label: string
  main_field_value: string
  submitted_by: User
  submitted_at: string
  last_notification?: {
    from_name: string
    send_at: string
    recipients: {
      email: string
    }[]
  }
  verified_name: string | null
  wo_proposal_id: number | null
}

export interface TableData {
  title: string
  id: number
  created_at: string
  created_by: string
  body: Record<string, Component>
}

export enum ActionEnum {
  CREATE = 'create',
  UPDATE = 'update',
}

export enum CustomFormEntityType {
  PROJECT = 1,
  JOB,
  APPOINTMENT,
}

export interface CountForms {
  count_forms: number
  count_templates: number
}

export interface ISubmittedTable {
  id: number
  proposal_id: number
  proposal_code: string
  proposal_created_at: string
  created_at: string
  updated_at: string
  fill_percentage: number
  completed: boolean
  job_code: string | null
  job_id: number | null
  is_verified?: boolean
  is_sent: boolean
}

export enum CustomFormsOperation {
  CREATE = 'can_create_custom_forms',
  READ = 'can_read_custom_forms',
  UPDATE = 'can_edit_custom_forms',
  DELETE = 'can_delete_custom_forms',
}
