import { AppointmentSource } from 'types/Appointment'
import { useAppSelector } from 'store/Orcatec/hooks'

const useSources = (includeDeleted?: boolean) => {
  const appSources: AppointmentSource[] = useAppSelector(
    state => state.orcatec?.company?.source,
  )

  return appSources?.filter(type => (includeDeleted ? type : !type.deleted_at))
}

export default useSources
