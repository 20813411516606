import { useEffect } from 'react'

export const useTitle = (title = 'Orcatec') => {
  useEffect(() => {
    document.title = title
    // return () => {
    //   document.title = ""
    // }
  }, [title])
}
