import { TextArea } from 'components/UIKit'
import styled from 'styled-components'
import { useAppDispatch, useAppSelector } from 'store/Orcatec/hooks'
import {
  selectProjectActiveTab,
  selectProjectTotal,
} from 'features/Project/projectSelectors'
// import { proposalStatuses } from 'containers/MainContent/Orcatec/Proposals/helpers/proposalStatuses'
import { checkAccessControl } from 'features/Settings/UsersAndGroups/helpers/checkAccessControl'
import {
  ProjectStatusGroup,
  ProjectStatus as IProjectStatus,
} from 'features/Project/types'
import {
  ModuleName,
  ProjectPermissions,
} from 'features/Settings/UsersAndGroups'
import { selectUserPermissionsByModule } from 'features/Settings/UsersAndGroups/permissionSlice'
import { selectAuthUser } from 'store/Orcatec/selectors/user'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import {
  selectProjectSlice,
  setEditContract,
  updateProjectField,
} from 'features/Project/slices/projectSlice'
import { updateProjectTabField } from 'features/Project/slices/projectTabsSlice'
import { useState } from 'react'
import Modal from 'containers/MainContent/Orcatec/components/UI/Modal'
import moment from 'moment-timezone'
import { Button, Popover } from 'antd'
import ModalCancelContract from 'containers/MainContent/Orcatec/Proposals/components/Modals/ModalCancelContract/ModalCancelContract'
import { resetSignature } from 'store/Orcatec/actions/proposal/proposalForm'
import ErrorBoundary from 'containers/MainContent/Orcatec/components/ErrorBoundary/ErrorBoundary'
import { InfoCircleOutlined } from '@ant-design/icons'
import { ProjectStatusDropdown } from './ProjectStatusDropdown'
import {
  getProjectStatuses,
  selectProjectStatusSlice,
} from 'features/Project/slices/projectStatusSlice'
import { STATUS_INFO } from 'features/Settings/Project/Statuses/Statuses'

export const ProjectStatus = () => {
  const dispatch = useAppDispatch()

  const me = useAppSelector(selectAuthUser)
  const { project, editContract } = useAppSelector(selectProjectSlice)
  const currentTab = useAppSelector(selectProjectActiveTab)
  const permissions = useAppSelector(
    selectUserPermissionsByModule(ModuleName.PROJECT),
  )
  const projectTotal = useAppSelector(selectProjectTotal)
  const { statusList } = useAppSelector(selectProjectStatusSlice)

  const [cancelModal, showCancelModal] = useState(false)
  const [editContractModla, showEditContractModla] = useState(false)
  const [cancellationReason, setCancellationReason] = useState('')
  const [updatingStatus, setUpdatingStatus] = useState(false)
  const [resettingSignature, setResettingSignature] = useState(false)
  const [
    selectedCanceledStatus,
    setSelectedCanceledStatus,
  ] = useState<IProjectStatus | null>(null)

  const canEditContract = checkAccessControl(
    permissions[ProjectPermissions.PROJECT_CAN_EDIT_CONTRACT],
    [project.user_id, ...(project?.extraAssignedUsers || [])],
    me.id,
  )

  const checkAccessToChangeStatus = (
    value: ProjectStatusGroup,
    previousProposalStatus: ProjectStatusGroup,
    cb: (text: string) => void,
  ) => {
    let noAccess = true
    const {
      project_can_change_status_from_estimate_to_contract,
      project_can_change_status_from_contract_to_completed,
      project_can_change_status_from_completed_to_contract,
      project_can_change_status_from_contract_to_estimate,
      project_can_change_status_from_contract_to_canceled,
      project_access_to_contract,
    } = permissions

    const text = 'You do not have access to the action.'

    if (value === 1) {
      if (
        previousProposalStatus === 2 &&
        !checkAccessControl(
          project_can_change_status_from_contract_to_estimate,
          [project.user_id, ...(project?.extraAssignedUsers || [])],
          me.id,
        )
      ) {
        noAccess = false
        cb(text)
      }
    } else if (value === 2) {
      if (
        (previousProposalStatus === 1 &&
          !checkAccessControl(
            project_can_change_status_from_estimate_to_contract,
            [project.user_id, ...(project?.extraAssignedUsers || [])],
            me.id,
          )) ||
        !checkAccessControl(
          project_access_to_contract,
          [project.user_id, ...(project?.extraAssignedUsers || [])],
          me.id,
        )
      ) {
        noAccess = false
        cb(text)
      }
      if (
        previousProposalStatus === 3 &&
        !checkAccessControl(
          project_can_change_status_from_completed_to_contract,
          [project.user_id, ...(project?.extraAssignedUsers || [])],
          me.id,
        )
      ) {
        noAccess = false
        cb(text)
      }
    } else if (value === 3) {
      if (
        previousProposalStatus === 2 &&
        !checkAccessControl(
          project_can_change_status_from_contract_to_completed,
          [project.user_id, ...(project?.extraAssignedUsers || [])],
          me.id,
        )
      ) {
        noAccess = false
        cb(text)
      }
    } else if (value === 4) {
      if (
        previousProposalStatus === 2 &&
        !checkAccessControl(
          project_can_change_status_from_contract_to_canceled,
          [project.user_id, ...(project?.extraAssignedUsers || [])],
          me.id,
        )
      ) {
        noAccess = false
        cb(text)
      }
    }
    return noAccess
  }

  const handleStatusChange = async (customStatus: IProjectStatus) => {
    const { id, parent_status } = customStatus
    const isAccess = checkAccessToChangeStatus(
      parent_status,
      project.status,
      (description: string) =>
        openNotificationWithIcon('warning', {
          message: 'Attention',
          description: description,
        }),
    )
    if (!isAccess) return

    if (parent_status === ProjectStatusGroup.Canceled && !cancelModal) {
      setSelectedCanceledStatus(customStatus)
      return showCancelModal(true)
    }

    setUpdatingStatus(true)

    const res = await dispatch(
      updateProjectField({
        custom_status_id: id,
        status: parent_status,
        signed_tab_id:
          parent_status === ProjectStatusGroup.Contract
            ? currentTab?.id
            : parent_status === ProjectStatusGroup.Estimate
            ? null
            : project.signed_tab_id,
        canceled_info:
          parent_status === ProjectStatusGroup.Canceled
            ? {
                date: moment().utc(),
                reason: cancellationReason,
                user: me.full_name,
              }
            : null,
      }),
    )

    setUpdatingStatus(false)

    if (cancelModal) {
      setSelectedCanceledStatus(null)
      showCancelModal(false)
    }

    if (parent_status === ProjectStatusGroup.Canceled) {
      dispatch(
        updateProjectTabField({
          contract_cancelled: projectTotal,
        }),
      )
    }

    if (res.meta.requestStatus === 'rejected') {
      dispatch(getProjectStatuses())

      return openNotificationWithIcon('warning', {
        message: 'Attention',
        description:
          res.payload?.errors?.status || res.payload?.errors?.custom_status_id,
      })
    }
  }

  const toggleEditContract = () => {
    if (!canEditContract) {
      return openNotificationWithIcon('warning', {
        message: 'You do not have permissions to perform this action',
      })
    }

    if (editContract) {
      return dispatch(setEditContract(!editContract))
    }

    showEditContractModla(true)
  }

  return (
    <ErrorBoundary>
      <Wrapper>
        {/* <Status color={colorByStatus[project.status]}>
          <Select
            name='status'
            value={project.status}
            onChange={handleStatusChange}
            showSearch={false}
            defaultValue={1}
            loading={updatingStatus}
            options={Object.keys(proposalStatuses.statuses).map(statusId => ({
              value: +statusId,
              label: (
                <Label>
                  <i
                    className={proposalStatuses.statuses[statusId].icon}
                    style={proposalStatuses.statuses[statusId].iconStyle}
                  />
                  {proposalStatuses.statuses[statusId].name}
                </Label>
              ),
            }))}
          />
        </Status> */}
        <div>
          <ProjectStatusDropdown
            statusId={project.custom_status_id}
            loading={updatingStatus}
            onChange={handleStatusChange}
            statusList={statusList.ids.map(id => statusList.data[id])}
            statusInfo={STATUS_INFO}
          />
        </div>

        {project.status === ProjectStatusGroup.Contract && (
          <Button onClick={toggleEditContract} style={{ width: '100%' }}>
            {editContract ? 'Done' : 'Edit Contract'}
          </Button>
        )}

        {project.status === ProjectStatusGroup.Canceled && (
          <Popover
            title={project.canceled_info?.reason || 'Project cancelled'}
            content={`${project.canceled_info?.user}, ${moment
              .utc(project.canceled_info?.date)
              .local()
              .format('MM/DD/YYYY [at] hh:mm a')}`}
            placement='right'
            overlayStyle={{ maxWidth: 400 }}
          >
            <InfoCircleOutlined style={{ fontSize: 20, color: '#206FED' }} />
          </Popover>
        )}
      </Wrapper>

      {cancelModal && (
        <Modal
          title='Cancel Project'
          onCancel={() => {
            setSelectedCanceledStatus(null)
            showCancelModal(false)
          }}
          onOk={() => handleStatusChange(selectedCanceledStatus)}
          visible
          width={400}
        >
          <div>
            <h6>Are you sure you want to cancel Project?</h6>

            <TextArea
              value={cancellationReason}
              placeholder='Cancellation reason...'
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                setCancellationReason(e.target.value)
              }
              style={{ margin: '20px 0 20px' }}
            />
          </div>
        </Modal>
      )}

      {editContractModla && (
        <ModalCancelContract
          type='to edit contract'
          show
          onSave={async () => {
            if (project.signature) {
              setResettingSignature(true)
              await dispatch(resetSignature(project.id))
              setResettingSignature(false)
            }

            dispatch(setEditContract(!editContract))
            showEditContractModla(false)
          }}
          onHide={() => showEditContractModla(false)}
          isStatusChanging={resettingSignature}
          isSignature={!!project.signature}
        />
      )}
    </ErrorBoundary>
  )
}

const Wrapper = styled.div`
  display: flex;
  gap: 16px;

  .ant-select-selector {
    border: none !important;
    box-shadow: none !important;
  }
`
