import React, { memo, useEffect, useRef, useState } from 'react'
import { Popover, Tooltip, Spin } from 'antd'
import { Link, withRouter } from 'react-router-dom'
import { getAddressWithUnit } from '../../../../../../../helpers/getAddressWithUnit'
import { getValueFromLS } from 'hooks/useLocalStorage'
import { getEventStatus } from './helpers'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
import { isMobileDevise } from 'helpers/isMobileDevice'
import states from 'helpers/states'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentAppointmentId } from 'store/Orcatec/actions/appointment'
import styled from 'styled-components'
import { EventKind } from 'types/Appointment'
import { useAppSelector } from 'store/Orcatec/hooks'

import EventPopover from '../eventPopover/EventPopover'
import '../style.scss'
import { Resizable } from 're-resizable'
import {
  selectCurrentMatrixLog,
  selectDispatchSettings,
  updateMatrixEvent,
} from 'features/Dispatch/dispatchSlice'
import {
  getAmericanTime,
  getIntegerPartFromDevision,
  getRoundedValue,
} from '../../../helpers'
import { getContrastYIQ } from 'helpers/contrastColor'
import {
  ModuleName,
  SchedulePermissions,
  DispatchPermission,
} from 'features/Settings/UsersAndGroups'

import { AccessControl } from 'features/Settings/UsersAndGroups/components/AccessControl/AccessControl'
import { selectUserPermissionsByModule } from 'features/Settings/UsersAndGroups/permissionSlice'
import moment from 'moment'
import { CalendarSize } from 'features/Dispatch/types'
import { Companies } from 'types/Company'

const popoverStyle = { height: '100%' }
const popoveroverlayStyle = { maxWidth: 500 }
const isMobileDevice = isMobileDevise()

const generateArrayStartingFromN = (n, length) => {
  return Array.from({ length: length }, (_, index) => n + index)
}

const Event = memo(
  ({
    event,
    worker: column,
    minTime,
    maxTime,
    // startDragging,
    backgroundColor,
    // getAmericanTime,
    dragEventStart,
    dragEventEnd,
    visible,
    handleVisibleChange,
    onDublicate,
    onCreateProposal,
    history,
    index,
    currentAppointmentId,
    activeAppointment,
    isWorker,
    boxHeight = 40,
    boxSize,
    isSchedule,
    onScheduleClick,
    activeEvent,
    onChangeActiveEvent,
    weekView,
    loading,
    onOpenAppointment,
    onSetEventData,
    eventType,
    handleDeleteAppointment,
    heandleChangeStatusAppointment,
    handleClickRfRModal,
    getPropertyHeandle,
    onClearCreatedStore,
    tooltipLoading,
    isMap,
    handleOpenContactModal,
    handleOpenPublicEventModal,
    onMapClick,
    eventSettings,
    showPreloader,
    onDrop,
    dragEventOver,
    workerId,
    resourceid,
    eventWidth,
    eventLeft,
    dragEventLeave,
  }) => {
    const dispatch = useDispatch()
    // const eventRef = useRef()
    const [width, setWidth] = useState(60)
    const [height, setHeight] = useState(40)
    const [resizingStart, setResazingStart] = useState(false)

    // const [height, setHeight] = useState(
    //   boxHeight * (event.current_end_time - event.current_start_time) +
    //     (event.current_end_time - event.current_start_time - 1) * 5,
    // )

    const { matrix_address_type, tooltip_mod: openOnClick, size } = useSelector(
      selectDispatchSettings,
    )

    const schedulePermissions = useAppSelector(
      selectUserPermissionsByModule(ModuleName.SCHEDULE),
    )
    const dispatchPermissions = useAppSelector(
      selectUserPermissionsByModule(ModuleName.DISPATCH),
    )
    const columnEvents = column?.events
    const company = useAppSelector(state => state.orcatec.company)

    const dragNDropDenied = isSchedule
      ? !!schedulePermissions[
          SchedulePermissions.SCHEDULE_CAN_CHANGE_EVENT_FRAME
        ]
      : !!dispatchPermissions[
          DispatchPermission.DISPATCH_CAN_CHANGE_EVENT_FRAME
        ]

    const getInitialEventHeight = () => {
      const endTime =
        event.current_end_time > event.current_start_time
          ? event.current_end_time
          : event.current_end_time + 24

      return (
        boxHeight * (endTime - event.current_start_time) +
        (endTime - event.current_start_time - 1) * 5
      )
    }

    useEffect(() => {
      if (!resizingStart) {
        const height = getInitialEventHeight()

        setHeight(height)
      }
    }, [boxHeight, columnEvents, resizingStart])

    useEffect(() => {
      setWidth(boxSize.width)
    }, [boxSize.width])

    // useEffect(() => {
    //   if (currentAppointmentId !== event?.id || !eventRef?.current) return
    //   debugger
    //   eventRef?.current?.scrollIntoView(false)
    // }, [])

    const isJob = event.kind === 2
    // const matrixAddressType = useSelector(state => state.orcatec.company.appointment_setting.matrix_address_type)
    const appointmentMatrixType =
      column.user_id !== 'misc'
        ? 'appointments_used'
        : column.user_id === 'queue'
        ? 'appointments_queue'
        : 'appointments_misc'

    // const getHeight = event => {
    //   const difference = event.current_end_time - event.current_start_time
    //   if (difference === 1) {
    //     return boxHeight
    //   } else {
    //     return difference * (boxHeight + 5) - 5
    //   }
    // }
    const getPosition = (event, worker, minTime) => {
      const hours = event.current_start_time - minTime
      const offset =
        worker.user_id === 'misc' ? (boxHeight < 40 ? 24.5 : 74.5) : 0

      return hours * (boxHeight + 5) + offset
    }

    const appointmentStyle = {
      top: `${getPosition(event, column, minTime)}px`,
      // height: `${getHeight(event)}px`,
      color: getContrastYIQ(backgroundColor || '#626ed4'),
      left: `${eventLeft}%`,
      width: `${eventWidth}%`,
      position: 'absolute',
      zIndex: index,
      border: '1px solid white',
      borderRadius: event?.parent_id ? '15px' : '5px',
      fontSize: size === CalendarSize.S ? 6 : size === CalendarSize.M ? 12 : 14,
      lineHeight: size === CalendarSize.S ? 1.15 : 1.33,
      textAlign:
        company.id === Companies['SD Hair Extensions Studio']
          ? 'center'
          : 'left',
    }

    const eventContentStyle = {
      backgroundColor: `${backgroundColor ? backgroundColor : '#626ed4'}`,
    }

    const goToAppointment = e => {
      if (e.target.classList.contains('btn')) return

      if (isSchedule) return onScheduleClick(event)

      onMapClick?.(event)

      if (isMobileDevice) {
        return handleVisibleChange(!visible.visible, event.id)
      }

      if (!openOnClick) {
        onOpenAppointment?.()
        onSetEventData?.(event)
      } else {
        handleVisibleChange(!visible.visible, event.id)
      }
    }

    const onDragStart = e => {
      if (!dragNDropDenied) return
      dragEventStart(e, event, appointmentMatrixType)
      handleVisibleChange(false, event.id)
    }

    const onDragEnd = e => {
      if (!dragNDropDenied) return
      dragEventEnd(e, index, appointmentMatrixType)
      onChangeActiveEvent('')
    }

    const onVisibleChange = visible => {
      handleVisibleChange(visible, event?.id)
    }

    const handleToggleDnd = () => {
      onChangeActiveEvent(event?.id)
      handleVisibleChange(false, event?.id)
    }

    const handleHoverEvent = () => {
      if (!currentAppointmentId) return
      dispatch(setCurrentAppointmentId(null))
    }

    /* const onOpenTooltip = () => {
      onVisibleChange(true)
      onClearCreatedStore()
    }
    const onCloseTooltip = e => {
      console.dir(e)
      onVisibleChange(false)
      onClearCreatedStore()
    } */

    // const getResizeLimit = () => {
    //   // const appointmentsByTech = columnEvents?.filter(
    //   //   app => app.appointed_technician_id === event?.appointed_technician_id,
    //   // )

    //   const appointmentsAfterCurrent = columnEvents
    //     ?.filter(app => app.current_start_time > event.current_start_time)
    //     ?.map(app => app.current_start_time)
    //     ?.sort((a, b) => a > b)

    //   const minValue = appointmentsAfterCurrent?.[0]

    //   return minValue || maxTime || 22
    // }

    const handleStopResizing = (e, direction, ref, d) => {
      if (!dragNDropDenied) return

      setWidth(width + (d.width < 0 ? 0 : d.width))
      setHeight(height + d.height)
      const endTime =
        event.current_end_time +
        getIntegerPartFromDevision(d.height, boxHeight) -
        getRoundedValue(getIntegerPartFromDevision(d.height, boxHeight) / 8)

      dispatch(
        updateMatrixEvent({
          app_type: 'appointments_used',
          to: 'appointments_used',
          event: {
            ...event,
            time_start: !eventSettings?.show_promise_time
              ? moment(event.current_start_time, 'HH').format('HH:mm')
              : event.time_start,
            time_end: !eventSettings?.show_promise_time
              ? moment(endTime, 'HH').format('HH:mm')
              : event.time_end,
            action: 'used',
            current_end_time: endTime,
            // current_end_time: endTime < 24 ? endTime : endTime - 24,
          },
          date: event.date,
        }),
      )

      if (isSchedule && showPreloader) {
        showPreloader()
      }
      setResazingStart(false)
    }

    const boxCount = event.current_end_time - event.current_start_time

    const overlayBoxes = generateArrayStartingFromN(
      event.current_start_time,
      boxCount,
    )

    return (
      <Resizable
        className={`box event del-cross ${
          isSchedule && activeAppointment === event.id ? ' current' : ''
        }${isMobileDevice && activeEvent === event.id ? ' show-resize' : ''}${
          !dragNDropDenied ? 'nodrag' : ''
        }${event.parent_id ? ' recurring' : ''}${
          isJob && !event.parent_id ? ' job' : ''
        }${isJob && event.parent_id ? ' recurring-job' : ''}`}
        key={`event-${event.id}`}
        resourceid={column.id}
        style={appointmentStyle}
        onDrop={onDrop}
        // onDragOver={dragEventOver}
        hour={event?.current_start_time}
        workerid={workerId}
        // ref={eventRef}
        snap={{
          x: [60, 125, 190],
          y: Array.from(
            { length: maxTime - minTime },
            // { length: getResizeLimit() - event?.current_start_time },
            (_, i) => boxHeight * (i + 1) + i * 5,
          ),
        }}
        minHeight={boxHeight}
        size={{ width: `${eventWidth}%`, height }}
        // size={{ width, height }}
        onResizeStop={handleStopResizing}
        onResizeStart={() => setResazingStart(true)}
        bounds={'parent'}
        enable={{
          top: false,
          left: false,
          topRight: false,
          bottomLeft: false,
          topLeft: false,
          bottom: weekView ? false : dragNDropDenied,
          bottomRight: false,
          right: false,
        }}
      >
        <Tooltip
          getPopupContainer={triggerNode => triggerNode.parentNode.parentNode}
          defaultVisible={true}
          title={`${currentAppointmentId === event.id ? 'Current' : ''}`}
        >
          <div
            // onClick={!isSchedule ? onOpenTooltip : goToAppointment}
            onClick={goToAppointment}
            style={{
              height: '100%',
              position: 'relative',
              backgroundColor: 'transparent !important',
            }}
            onMouseOver={handleHoverEvent}
            // onDrop={onDrop}
            // onDragOver={dragEventOver}
          >
            {loading ? (
              <Spin
                style={{ position: 'absolute', top: '30%', left: '40%' }}
                size='small'
              />
            ) : (
              <div
                className={`draggable-event ${getEventStatus(event)}`}
                draggable={dragNDropDenied}
                onDragStart={onDragStart}
                onDragEnd={onDragEnd}
                style={eventContentStyle}
                resourceid={resourceid}
                workerid={workerId}
                // onMouseOver={onOpenTooltip}
                // onMouseOut={onCloseTooltip}
              >
                <EventPopover
                  visible={isSchedule ? false : visible}
                  onVisibleChange={onVisibleChange}
                  handleToggleDnd={handleToggleDnd}
                  event={event}
                  isSchedule={isSchedule}
                  eventType={eventType}
                  heandleChangeStatusAppointment={
                    heandleChangeStatusAppointment
                  }
                  weekView={weekView}
                  isWorker={isWorker}
                  onCreateProposal={onCreateProposal}
                  isJob={isJob}
                  onDublicate={onDublicate}
                  handleClickRfRModal={handleClickRfRModal}
                  getAmericanTime={getAmericanTime}
                  onSetEventData={onSetEventData}
                  onOpenAppointment={onOpenAppointment}
                  handleDeleteAppointment={handleDeleteAppointment}
                  getPropertyHeandle={getPropertyHeandle}
                  tooltipLoading={tooltipLoading}
                  isMap={isMap}
                  handleOpenContactModal={handleOpenContactModal}
                  handleOpenPublicEventModal={handleOpenPublicEventModal}
                >
                  <div
                    resourceid={resourceid}
                    className='box-overlay-wrapper'
                    style={{
                      position: 'absolute',
                      top: 0,
                      height:
                        overlayBoxes.length === 1
                          ? 38
                          : overlayBoxes.length * 40 + overlayBoxes.length * 2,
                      width: '100%',
                      zIndex: 100,
                    }}
                  >
                    {overlayBoxes.map((el, idx, arr) => (
                      <div
                        className='box-overlay'
                        style={{
                          height: 38,
                          zIndex: 1,
                          borderRadius: '5px',
                          margin: arr.length - 1 !== idx ? '4px' : '0 4px',
                        }}
                        hour={el}
                        workerid={workerId}
                        key={el}
                        onDragOver={e => dragEventOver(e, idx)}
                        onDragLeave={e => dragEventLeave(e, idx)}
                        onDrop={onDrop}
                      ></div>
                    ))}
                  </div>
                  <AccessControl
                    scopes={[
                      SchedulePermissions.SCHEDULE_CAN_READ_CLIENT_ADDRESS,
                    ]}
                    visible={!isSchedule}
                  >
                    <div className='zip'>
                      {event.entity_type === 1 ||
                      event.kind === EventKind.JOB ? (
                        matrix_address_type === 3 ? (
                          event?.city ? (
                            <>
                              <span>{event.city}</span>
                              <span>
                                , {states[event.state] || event.state}
                              </span>
                            </>
                          ) : (
                            ''
                          )
                        ) : matrix_address_type === 2 ? (
                          event?.city ? (
                            <span>{event.city}</span>
                          ) : (
                            ''
                          )
                        ) : event?.postcode ? (
                          event.postcode
                        ) : (
                          ''
                        )
                      ) : (
                        'Office'
                      )}
                      {!!event.preferred_technician_id && (
                        <i className='fa fa-user' />
                      )}
                    </div>
                  </AccessControl>
                  <AccessControl
                    module={ModuleName.SCHEDULE}
                    scopes={[SchedulePermissions.SCHEDULE_CAN_READ_CLIENT_NAME]}
                    visible={!isSchedule}
                  >
                    {event.client_name && (
                      <div className='client-name'>{event.client_name}</div>
                    )}
                  </AccessControl>

                  <div className='event-type'>
                    {event.appointment_type || '-'}
                  </div>
                  <div className='event-tech'>
                    {event.preferred_technician &&
                      event.preferred_technician.name &&
                      event.preferred_technician.name}
                  </div>
                  {company?.id === 264 ? (
                    <div>{event?.relation}</div>
                  ) : (
                    <div className='event-time'>
                      {eventSettings?.show_promise_time
                        ? event.time_start === event.time_end
                          ? event.time_start &&
                            getAmericanTime(event.time_start)
                          : `${event.time_start &&
                              getAmericanTime(
                                event.time_start,
                              )}${event.time_start &&
                              event.time_end &&
                              ' - '} ${event.time_end &&
                              getAmericanTime(event.time_end)}`
                        : event.current_start_time === event.current_end_time
                        ? event.current_start_time &&
                          getAmericanTime(event.current_start_time)
                        : `${event.current_start_time &&
                            getAmericanTime(
                              event.current_start_time,
                            )}${event.current_start_time &&
                            event.current_end_time &&
                            ' - '} ${event.current_end_time &&
                            getAmericanTime(event.current_end_time)}`}
                    </div>
                  )}
                </EventPopover>
              </div>
            )}
          </div>
        </Tooltip>
      </Resizable>
    )
  },
)

// const connectedEvent = withRouter(Event)
// export { connectedEvent as Event }
export { Event }

Event.displayName = 'Event'
