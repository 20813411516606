import { IProperty } from "containers/MainContent/Orcatec/CreateAppointmentV2/AppointmentForm/components/AppointmentPreferredTech/types"
import { INote } from "types/Notes"

export interface ContactEmail {
  id: number
  preference: boolean
  send_promo: boolean
  send_reminder: boolean
  email: string
  entity_type: ContactType
}

export interface ContactPhone {
  id: number
  phone: string
  preference: 'voice' | 'text'
  entity_type: ContactType
  type: 'cell' | 'work' | 'home' | 'other'
}

export enum ContactType {
  ORGANIZATION = 1,
  INDIVIDUAL = 2,
}

export interface IContacts {
  appointments: object[]
  messagings?: object[]

  calls?: object[]
  client_associations: object[]
  created_at: string
  deleted_at: string | null
  emails: ContactEmail[]
  feedBackList: []
  id: number
  industry: null | string
  is_individual: boolean
  memberships: object[]
  name: string
  note: string
  notes: INote[]
  organization_id: null | number
  organizations: []
  other_company_client_id: number[]
  owner_user_id: number
  phones: ContactPhone[]
  properties: IProperty[]
  proposals: object[]
  relative_company_id: null | number
  tags: []
  type: ContactType
  updated_at: string
  user_id: number
  vote: number
  website: null | string

}