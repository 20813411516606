import React, { useState, useMemo } from 'react'
import { DeleteFilled } from '@ant-design/icons'
import { Select } from 'components/UIKit'
import { Popconfirm, SelectProps } from 'antd'
import styled from 'styled-components'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'

interface CustomSelectProps extends Omit<SelectProps, 'onChange'> {
  label?: string
  value: number | null
  onChange: (value: number | string | null) => void
  addNewLabel?: string
  showColor?: boolean
  onAddOption?: (newOption: string) => Promise<void>
  onDeleteOption?: (value: number) => void
}

export const CustomSelect: React.FC<CustomSelectProps> = ({
  value,
  onChange,
  onAddOption,
  onDeleteOption,
  addNewLabel = 'Add',
  options,
  showColor,
  ...restProps
}) => {
  const [searchValue, setSearchValue] = useState('')
  const [loading, setLoading] = useState(false)

  // Filter and prepare options based on search value
  const filteredOptions = useMemo(() => {
    const normalizedSearch = searchValue.trim().toLowerCase()

    // Start with existing options
    let filtered =
      options?.filter(
        option =>
          !option.deleted &&
          option?.label
            ?.toString()
            .toLowerCase()
            .includes(normalizedSearch),
      ) || []

    // Transform options to include delete button
    filtered = filtered.map(option => ({
      ...option,
      label: (
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 30px',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              gap: 2,
              alignItems: 'center',
              overflow: 'hidden',
            }}
          >
            {showColor && <Color color={option?.color?.hex || '#4B94D8'} />}

            <Label>{option.label}</Label>
          </div>

          {onDeleteOption && (
            <Popconfirm
              title='Are you sure you want to delete?'
              onConfirm={e => {
                e?.stopPropagation()
                if (!option.value) return

                onDeleteOption(option?.value)
              }}
            >
              <DeleteFilled
                className='ml-2 text-red-500 hover:text-red-700'
                onClick={e => {
                  e.stopPropagation()
                }}
              />
            </Popconfirm>
          )}
        </div>
      ),
      // Keep original label for selected value display
      value: option.value,
    }))

    // Add "create new" option if search value doesn't exist
    if (
      onAddOption &&
      normalizedSearch &&
      !options?.some(
        opt => opt?.value?.toString().toLowerCase() === normalizedSearch,
      )
    ) {
      filtered = [
        ...filtered,
        {
          label: `${addNewLabel} "${searchValue.trim()}"`,
          value: `${addNewLabel}_${searchValue.trim()}`,
        },
      ]
    }

    return filtered
  }, [searchValue, options, addNewLabel, onDeleteOption])

  const handleSelect = async (selectedValue: number | string) => {
    // Check if this is a "create new" selection
    if (
      typeof selectedValue === 'string' &&
      selectedValue.startsWith(`${addNewLabel}_`) &&
      onAddOption
    ) {
      const newValue = searchValue.trim()

      setLoading(true)

      try {
        const data = await onAddOption?.(newValue)

        if (data?.id) onChange(data.id)
      } catch (error) {
        openNotificationWithIcon('error', {
          message: 'Oops',
          description: error?.response?.data?.message || 'Something went wrong',
        })
      } finally {
        setLoading(false)
      }
    } else {
      onChange(selectedValue)
    }
    setSearchValue('')
  }

  const handleSearch = (value: string) => {
    setSearchValue(value)
  }

  const handleClear = () => {
    onChange(null)
    setSearchValue('')
  }

  // Function to get display label for selected value
  const getDisplayValue = () => {
    if (!value) return undefined
    const option = options?.find(opt => opt.value === value)
    return option ? { value, label: option.label } : value
  }

  return (
    <Select
      showSearch
      value={getDisplayValue()}
      style={{ width: '100%' }}
      onSelect={handleSelect}
      onSearch={handleSearch}
      onClear={handleClear}
      searchValue={searchValue}
      options={filteredOptions}
      filterOption={false}
      notFoundContent={null}
      loading={loading}
      disabled={loading}
      {...restProps}
    />
  )
}

const Color = styled.div`
  background-color: ${props => props?.color || '#626ed4'};
  display: inline-block;
  flex: 0 0 25px;
  height: 20px;
  margin-right: 10px;
  border-radius: 4px;
  border: 1px solid;
`

const Label = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
