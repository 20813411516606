import { CustomSelect } from 'components/UIKit'
import { selectDispatchSettings } from 'features/Dispatch/dispatchSlice'
import { EventColorType } from 'features/Dispatch/types'
import useTypes from 'hooks/useTypes'
import { useAppDispatch, useAppSelector } from 'store/Orcatec/hooks'
import {
  createCompanyTypes,
  deleteCompanyTypes,
} from 'store/Orcatec/operation/settings/company'
import { isOwner } from 'store/Orcatec/selectors/user'

interface Props {
  value: number | null
  onChange: (value: number | null) => void
}

export const EventTypes = ({ value, onChange }: Props) => {
  const dispatch = useAppDispatch()

  const owner = useAppSelector(isOwner)
  const types = useTypes(true)
  const {
    restrict_crud_source_type: disableCrud,
    event_color_type,
  } = useAppSelector(selectDispatchSettings)
  const showColor = event_color_type === EventColorType['Event type']

  async function handleOptionAdd(value: string) {
    return await dispatch(createCompanyTypes({ name: value }))
  }

  async function handleOptionDelete(optionId: number) {
    dispatch(deleteCompanyTypes(optionId))
  }

  return (
    <CustomSelect
      label='Type'
      value={value}
      onChange={onChange}
      options={types.map(type => ({
        label: type.name,
        value: type.id,
        color: type.color,
        deleted: !!type.deleted_at,
      }))}
      allowClear
      onAddOption={!owner && disableCrud ? undefined : handleOptionAdd}
      onDeleteOption={!owner && disableCrud ? undefined : handleOptionDelete}
      showColor={showColor}
    />
  )
}
