import { Button, Popover, Result } from 'antd'
import { Password } from 'components/UIKit'
import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import * as yup from 'yup'
import PasswordChecklist from 'react-password-checklist'
import {
  CheckOutlined,
  CloseOutlined,
} from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
import { AuthAPI } from '../api'
import useQuery from 'hooks/useQuery'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'

const passwordValidationSchema = yup.object().shape({
  password: yup
    .string()
    .required('Password is required')
    .min(6, 'Password length should be at least 6 characters')
    .max(32, 'Password cannot exceed more than 32 characters')
    .matches(
      /.*\p{Ll}.*/u,
      'Password must contain at least 1 lower case letter',
    )
    .matches(
      /.*\p{Lu}.*/u,
      'Password must contain at least 1 upper case letter',
    )
    // .matches(
    //   /[!@#$%^&*(),.?":{}|<>]/,
    //   'Password must contain at least 1 special character',
    // )
    .matches(/.*\d.*/, 'Password must contain at least 1 number'),

  password_confirmation: yup
    .string()
    .required('Confirm Password is required')
    .min(6, 'Password length should be at least 6 characters')
    .max(32, 'Password cannot exceed more than 32 characters')
    .oneOf([yup.ref('password')], 'Passwords do not match'),
})

export const CreatePassword = () => {
  const searchParams = useQuery()
  const history = useHistory()
  const token = searchParams.get('token')

  const [passwordValues, setPasswordValues] = useState({
    password: '',
    password_confirmation: '',
  })
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [passwordChanged, setPasswordChanged] = useState(false)

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target

    if (error) setError('')

    setPasswordValues(prev => ({
      ...prev,
      [name]: value,
    }))
  }

  const handlePasswordSubmit = async () => {
    setLoading(true)

    try {
      await passwordValidationSchema.validate({
        password: passwordValues.password,
        password_confirmation: passwordValues.password_confirmation,
      })

      await AuthAPI.createPassword({
        token,
        password: passwordValues.password,
      })
      setPasswordChanged(true)

      openNotificationWithIcon('success', {
        message: 'Password successfully set',
      })
    } catch (error) {
      setError(
        error.errors
          ? { [error.path]: error.errors }
          : error?.response?.data || { password: 'Something went wrong' },
      )
    } finally {
      setLoading(false)
    }
  }

  const renderContent = () => (
    <Content>
      {passwordChanged ? (
        <Result
          status='success'
          title='Password has been set successfully'
          extra={[
            <Button
              type='primary'
              key='login'
              onClick={() => history.push('/auth/signin')}
            >
              Back to Sign in
            </Button>,
          ]}
        />
      ) : (
        <Form>
          <p style={{ marginBottom: 20 }}>Please enter your password</p>
          <Popover
            placement='right'
            content={
              <PasswordChecklistStyled
                rules={[
                  'minLength',
                  'lowercase',
                  'capital',
                  'number',
                  // 'specialChar',
                  // 'match',
                ]}
                minLength={6}
                value={passwordValues.password}
                valueAgain={passwordValues.password_confirmation}
                messages={{
                  minLength: 'Length 6 - 32 symbols',
                  lowercase: 'Should include at least 1 lowercase',
                  capital: 'Should include at least 1 uppercase',
                  number: 'Should include at least 1 number',
                  // specialChar: 'Special symbol',
                }}
                // onChange={(a, b) => console.log(a, b)}
                iconComponents={{
                  InvalidIcon: <CloseOutlined style={{ fontSize: 24 }} />,
                  ValidIcon: <CheckOutlined style={{ fontSize: 24 }} />,
                }}
              />
            }
            trigger='focus'
          >
            <Password
              name='password'
              value={passwordValues.password}
              onChange={handlePasswordChange}
              label='Password'
              required
              error={error?.password}
              autoComplete='new-password'
            />
          </Popover>

          <Password
            name='password_confirmation'
            value={passwordValues.password_confirmation}
            onChange={handlePasswordChange}
            label='Confirm password'
            required
            error={error?.password_confirmation}
            autoComplete='off'
          />

          <Button
            block
            type='primary'
            onClick={handlePasswordSubmit}
            size='large'
            style={{ marginTop: 30 }}
            loading={loading}
            disabled={passwordChanged}
          >
            {passwordChanged ? 'Password successfully set' : 'Set password'}
          </Button>
        </Form>
      )}
    </Content>
  )

  return (
    <Wrapper>
      {renderContent()}
      {!passwordChanged && (
        <Signin>
          <Link to='/auth/signin'>Back to Sign In</Link>
        </Signin>
      )}

      <Policy>
        This site is subject to{' '}
        <Link
          to={{
            pathname: 'https://orcatec.com/privacy_policy.pdf',
          }}
          target='_blank'
          rel='noopener noreferrer'
        >
          Privacy Policy
        </Link>{' '}
        and{' '}
        <Link
          to={{
            pathname: 'https://orcatec.com/terms_and_conditions.pdf',
          }}
          target='_blank'
          rel='noopener noreferrer'
        >
          Terms of use
        </Link>
      </Policy>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 450px;
  padding: 48px;
  border: 2px solid #165ed033;
  border-radius: 10px;
  background-color: #fff;

  @media (max-width: 450px) {
    width: 375px;
    padding: 16px;
  }
`

const Content = styled.div``
const Form = styled.div`
  /* margin-top: 20px; */

  & > *:not(:last-child) {
    margin-bottom: 16px;
  }
`

const Signin = styled.p`
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: 30px;
  color: #4d4d4d;
`

const PasswordChecklistStyled = styled(PasswordChecklist)`
  li {
    gap: 10px;
    align-items: center !important;
    margin-bottom: 8px !important;
  }
`

const Policy = styled.p`
  margin-top: 32px;
  color: #4d4d4d;

  a {
    font-weight: 500;
  }
`
