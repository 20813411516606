import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'
import { BoardStage, CRMBoard } from '../crm.interface'
import { AsyncThunkAPI } from 'store/types'
import crmAPI, { CRMBoardResponse } from '../api'
import { AppStateType } from 'store'
import { transformArrayToObj } from 'features/Dispatch/helpers'
import { getValueFromLS } from 'hooks/useLocalStorage'
import { Error } from 'types/Error'

interface CRM {
  activeBoardId: number | null
  boards: {
    entities: Record<number, CRMBoard>
    ids: CRMBoard['id'][]
  }
  boardTotals: Record<number, number>
  error: Error | null
  editMode: boolean
  stages: {
    entities: Record<number, BoardStage>
    ids: BoardStage['id'][]
  }
  status: 'idle' | 'loading' | 'error'
}

const initialState: CRM = {
  activeBoardId: null,
  boards: {
    entities: {},
    ids: [],
  },
  boardTotals: {},
  error: null,
  editMode: false,
  stages: {
    entities: {},
    ids: [],
  },
  status: 'idle',
}

const crmSlice = createSlice({
  name: 'crmSlice',
  initialState,
  reducers: {
    activeBoardIdSet: (state, action) => {
      state.activeBoardId = action.payload
    },
    toggleEditMode: state => {
      state.editMode = !state.editMode
    },
    boardTitleChanged: (state, action) => {
      if (!state.activeBoardId) return

      state.boards.entities[state.activeBoardId].title = action.payload
    },

    //Column actions
    boardColumnAdded: (
      state,
      action: PayloadAction<
        | {
            columnId: number
            place: 'before' | 'after'
          }
        | undefined
      >,
    ) => {
      if (!state.activeBoardId) return
      const { columnId, place } = action.payload || {}

      const id = Date.now()
      const position = Math.max(
        ...Object.values(state.stages.entities).map(stage => stage.position),
      )
      const newStage = {
        id,
        board_id: state.activeBoardId,
        name: 'New stage',
        description: '',
        color: '#1890FF',
        position,
        isNew: true,
        total: 0,
      }

      state.stages.entities[newStage.id] = newStage
      state.boardTotals[newStage.id] = 0

      const index = state.stages.ids.findIndex(id => id === columnId)
      const newColumnIndex =
        index !== -1 ? index + (place === 'after' ? 1 : 0) : undefined

      if (
        newColumnIndex !== undefined &&
        newColumnIndex >= 0 &&
        newColumnIndex <= state.stages.ids.length
      ) {
        state.stages.ids.splice(newColumnIndex, 0, id)
      } else {
        state.stages.ids.push(id)
      }
    },
    columnUpdated: (
      state,
      action: PayloadAction<{
        columnId: BoardStage['id']
        fieldsToUpdate: Partial<BoardStage>
      }>,
    ) => {
      state.stages.entities[action.payload.columnId] = {
        ...state.stages.entities[action.payload.columnId],
        ...action.payload.fieldsToUpdate,
      }

      if (
        state.error?.[action.payload.columnId] &&
        Object.keys(action.payload.fieldsToUpdate).includes('name')
      ) {
        delete state.error?.[action.payload.columnId]
      }
    },
    columnDeleted: (
      state,
      action: PayloadAction<{
        columnId: BoardStage['id']
        deleteAction: BoardStage['delete_action']
      }>,
    ) => {
      state.stages.entities[action.payload.columnId].delete_action =
        action.payload.deleteAction

      if (action.payload.deleteAction?.type === 1) {
        state.boardTotals[action.payload.deleteAction.stage_id] +=
          state.boardTotals[action.payload.columnId]
      }
    },
    columnsTotalSet: (state, action) => {
      const totals = action.payload

      state.boardTotals = totals
    },
    columnsTotalUpdate: (
      state,
      action: PayloadAction<{
        source: number
        dist: number
      }>,
    ) => {
      const { source, dist } = action.payload

      state.boardTotals[source] -= 1
      state.boardTotals[dist] += 1
    },
    resetBoard: state => {
      const keysToDelete = Object.entries(state.stages.entities)
        .filter(([, stage]) => stage.isNew)
        .map(([key]) => +key)

      // Delete the matching keys
      keysToDelete.forEach(key => {
        delete state.stages.entities[key]
      })

      // Optionally, update the `ids` array if you track it
      state.stages.ids = state.stages.ids.filter(
        id => !keysToDelete.includes(id),
      )
    },
    errorSet: (state, action) => {
      state.error = action.payload
    },
  },
  extraReducers(builder) {
    builder.addCase(getBoardList.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(getBoardList.fulfilled, (state, action) => {
      // state.boardList = transformArrayToObj(action.payload)
      state.boards = {
        ids: action.payload.map(board => board.id),
        entities: transformArrayToObj(action.payload),
      }
      const stages: BoardStage[] = action.payload.flatMap(board => board.stages)

      state.stages = {
        ids: stages.map(stage => stage.id),
        entities: transformArrayToObj(stages),
      }
      state.status = 'idle'
    })

    builder.addCase(getBoardById.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(getBoardById.fulfilled, (state, action) => {
      const { stages, ...board } = action.payload

      state.status = 'idle'
      state.boards.entities[board.id] = board

      stages.forEach(
        stage => (state.stages.entities[stage.id] = { ...stage, total: 0 }),
      )
    })
    builder.addCase(getBoardById.rejected, state => {
      state.status = 'idle'
    })

    builder.addCase(updateBoard.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(updateBoard.fulfilled, state => {
      state.status = 'idle'
      state.editMode = false
    })
    builder.addCase(updateBoard.rejected, state => {
      state.status = 'idle'
    })

    builder.addCase(deleteBoard.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(deleteBoard.fulfilled, (state, action) => {
      const { meta } = action

      state.status = 'idle'
      state.editMode = false
      state.boards.ids = state.boards.ids.filter(id => id !== meta.arg.boardId)
      state.activeBoardId = meta.arg.deleteAction?.board_id
    })
    builder.addCase(deleteBoard.rejected, state => {
      state.status = 'idle'
    })

    builder.addCase(createBoard.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(createBoard.fulfilled, (state, action) => {
      state.status = 'idle'
      state.editMode = true

      const { stages, ...board } = action.payload

      state.boards.ids.push(board.id)
      state.boards.entities[board.id] = board
      state.stages.ids.push(stages[0].id)
      state.stages.entities[stages[0].id] = { ...stages[0], total: 0 }
      state.activeBoardId = board.id
    })
    builder.addCase(createBoard.rejected, state => {
      state.status === 'idle'
    })
  },
})

export default crmSlice.reducer
export const {
  // activeBoardSet,
  activeBoardIdSet,
  toggleEditMode,
  boardTitleChanged,
  boardColumnAdded,
  columnUpdated,
  columnDeleted,
  resetBoard,
  columnsTotalSet,
  columnsTotalUpdate,
  errorSet,
} = crmSlice.actions

//Thunks
export const getBoardList = createAsyncThunk<
  CRMBoardResponse[],
  number | undefined,
  AsyncThunkAPI
>('crmSlice/getBoardList', async (boardId, { dispatch, rejectWithValue }) => {
  try {
    const boards = await crmAPI.getBoardList()
    const lastActiveBoardId: number | undefined = getValueFromLS(
      'lastActiveBoardId',
    )

    const activeBoardId = boardId
      ? boardId
      : boards.map(board => board.id).includes(lastActiveBoardId)
      ? lastActiveBoardId
      : boards[0].id

    dispatch(activeBoardIdSet(activeBoardId))
    return boards
  } catch (error) {
    return rejectWithValue(error?.response?.data)
  }
})

export const getBoardById = createAsyncThunk<
  CRMBoardResponse,
  number,
  AsyncThunkAPI
>('crmSlice/getBoardById', async (boardId, { rejectWithValue }) => {
  try {
    return await crmAPI.getBoard(boardId)
  } catch (error) {
    return rejectWithValue(error.response.data)
  }
})

export const createBoard = createAsyncThunk<
  CRMBoardResponse,
  void,
  AsyncThunkAPI
>('crmSlice/createBoard', async (_, { rejectWithValue }) => {
  try {
    const newBoard = await crmAPI.createBoard({
      title: 'New Board',
      name: 'New Stage',
      description: '',
      color: '#6174D5',
      position: 0,
    })

    return newBoard
  } catch (error) {
    return rejectWithValue(error?.respose?.data)
  }
})

export const updateBoard = createAsyncThunk<
  void,
  CRMBoard & { stages: BoardStage[] },
  AsyncThunkAPI
>('crmSlice/updateBoard', async (board, { dispatch, rejectWithValue }) => {
  try {
    await crmAPI.updateBoard(board)

    dispatch(getBoardList(board.id))
  } catch (error) {
    return rejectWithValue(error?.response?.data)
  }
})

export const deleteBoard = createAsyncThunk<
  void,
  {
    boardId: number
    deleteAction: {
      type: 1 | 2
      board_id: number
      stage_id: number
    }
  },
  AsyncThunkAPI
>(
  'crmSlice/deleteBoard',
  async ({ boardId, deleteAction }, { rejectWithValue }) => {
    try {
      const res = await crmAPI.deleteBoard(boardId, {
        delete_action: deleteAction?.type,
        board_id: deleteAction?.board_id,
        stage_id: deleteAction?.stage_id,
      })

      return res
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  },
)

//selectors
export const selectCRMSlice = (state: AppStateType) => state.orcatec.crmSlice

const selectActiveBoardId = (state: AppStateType) =>
  state.orcatec.crmSlice.activeBoardId

export const selectAllStages = (state: AppStateType): BoardStage[] =>
  state.orcatec.crmSlice.stages.ids.map(
    id => state.orcatec.crmSlice.stages.entities[id],
  )

export const selectStagesByBoard = createSelector(
  [selectAllStages, (_: AppStateType, boardId?: number) => boardId],
  (stages, boardId) =>
    stages.filter(stage => (boardId ? stage.board_id === boardId : stage)),
)

export const selectBoardsList = (state: AppStateType): CRMBoard[] =>
  state.orcatec.crmSlice.boards.ids.map(
    id => state.orcatec.crmSlice.boards.entities[id],
  )

export const selectActiveBoard = createSelector(
  [selectActiveBoardId, (state: AppStateType) => state.orcatec.crmSlice.boards],
  (activeBoardId, boards) => {
    return activeBoardId ? boards.entities[activeBoardId] : null
  },
)

export const selectBoardTotal = (state: AppStateType) =>
  Object.values(state.orcatec.crmSlice.boardTotals).reduce(
    (sum, value) => (sum += value),
    0,
  )
