import { useEffect, useState } from 'react'
import { CRMBoard } from '../crm.interface'
import crmAPI from '../api'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'

const initialState: Omit<CRMBoard, 'id'> = {
  name: 'New opportunity',
  stages: [],
}

export const useBoard = (id?: number) => {
  const [board, setBoard] = useState<CRMBoard>(initialState as CRMBoard)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    async function getData(id: number) {
      setLoading(true)

      try {
        const data = await crmAPI.getBoard(id)

        setBoard(data)
      } catch (error) {
        openNotificationWithIcon('error', {
          message: 'Oops',
          description: error?.response?.data?.message || 'Board not found',
        })
      } finally {
        setLoading(false)
      }
    }

    if (id) getData(id)
  }, [id])

  return { board, loading }
}
