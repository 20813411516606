import { Opportunity } from 'features/CRM/crm.interface'
import { GlobalItem, ItemAttachment } from 'features/Items/types'
import { ClientType } from 'types/Client'
import { Media } from 'types/Media'
import { PropertyType } from 'types/Property'
import { ProjectPayment, ITabModule } from 'types/Proposal'
import { User } from 'types/User'

export interface Project {
  activity: { id: number; updated_at: string; name: string }[]
  activeTab: number
  activeTabId: number
  all_work_completed: boolean
  appointment_id?: number
  date_of_completion: string | null
  date_of_sale: string | null
  canceled_info: {
    date: string
    reason: string
    user: string
  } | null
  clients: Client[]
  contract_date: string | null
  created_by: {
    id: number
    full_name: string
  }
  created_at: string
  created_by_entity: string
  custom_status_id: ProjectStatus['id']
  code: string
  default_rate: number
  extraAssignedUsers: number[]
  had_been_sent: boolean
  had_been_viewed: boolean
  id: number
  industry_id: number
  info_from_customer: string
  info_for_customer: string
  ip_client: string
  files: Media[]
  files_for_worker: Media[]
  files_from_customer: Media[]
  issue_date: string
  industry_name: string
  lead_installer_id: number
  name: string
  opportunity:
    | (Pick<Opportunity, 'id' | 'name'> & { count_proposals: number })
    | null
  organizations: Client[]
  parent_id: number | null
  properties: ProjectProperty[]
  proposal_tabs: IProjectTab[]
  priority_tab_id: number
  proposal_status_changed_by: string
  setting: ProjectSettings
  signature: string
  signature_date: string | null
  signatory_name: string
  signed_by: string
  signed_tab_id: number | null
  source: {
    id: number
    name: string
  } | null
  source_id: number | null
  status: ProjectStatusGroup
  tabs_info: IProjectTab[]
  template_name: string | null
  term_and_conditions: {
    file0: Media
  }
  type: {
    id: number
    name: string
  } | null
  type_id: number | null
  user_id: number
  user: User & {
    company_id: number
  }
  wo_status: number
  wo_proposal_id: number | null
}

export interface ProjectSection {
  description: string
  id: number
  is_from_proposal?: boolean
  items: ProjectItem[]
  installation: number
  groups: IProjectItemsGroup[]
  discounts: ProjectDiscount[]
  position: number
  rebates: ProjectRebate[]
  show: boolean
  title: string
}

export interface SectionGroup {
  title: string
  position: number
}

export interface IProjectTab {
  cancellation_fees: number
  description: string
  fees: ProjectFee[]
  files: Media[]
  id: number
  image: string | null
  modules: ITabModule[]
  notes_for_installers: string
  payment_status: number
  payment: ProjectPayment
  position: number
  proposal_notes: string | null
  proposal_sections: ProjectSection[]
  status: number
  tax: ProjectTax | null
  title: string
  total_to_pay: string
}

export interface ProjectItem extends GlobalItem {
  approved: boolean
  checked_option: boolean
  difference: number | null
  global_item_id: number | null
  group_id: number | null
  id: number
  installed: number
  is_hidden: boolean
  options?: Record<number, ProjectItem> | null
  option_group_id: number | null
  order_option: number
  parent_id?: number | null
  position: number
  progress: number
  qty: number
  required_to_pay: boolean
  section_id: number
  tab_id: number
  unit?: string
  use_calculation: boolean
}

export interface ProjectDiscount {
  active?: boolean
  amount: number
  attachments: ItemAttachment[]
  checked_option: boolean
  difference: number
  discount_type: ProjectDiscountType
  expiration: string | null
  expiration_type: DiscountExpirationType
  global_item_id: number | null
  group_id: number | null
  id: number
  name: string
  option_group_id: number | null
  order_option: number
  position: number
  section_id: number
  tab_id: number
}

export interface ProjectRebate {
  active?: boolean
  amount: number
  attachments: ItemAttachment[]
  checked_option: boolean
  difference: number
  expiration: string | null
  expiration_type: DiscountExpirationType
  global_item_id: number | null
  group_id: number | null
  id: number
  name: string
  option_group_id: number | null
  order_option: number
  position: number
  section_id: number
  tab_id: number
}

export enum ProjectDiscountType {
  Amount = 1,
  Percentage,
}

export enum DiscountExpirationType {
  '15th of every month' = 1,
  'End of each month',
  'Fixed date',
  'No expiration',
}

export interface ProjectSettings {
  balance_auto_creation: boolean
  completion_progress: boolean
  default_payment_due_date: number
  default_rate: string
  show_tips: boolean
  financing: boolean
  hide_zero_price: boolean
  include_tips_to_insight: boolean
  multiple_choice_items: boolean
  multiple_tabs: boolean
  payment_processing_fee: number
  require_signature: boolean
  show_discounts: boolean
  show_information: boolean
  show_installation: boolean
  show_item_description_only: boolean
  show_forms: boolean
  show_groups: boolean
  show_items_qty: boolean
  show_modules: boolean
  show_non_contract_tabs: boolean
  show_notes: boolean
  show_price: boolean
  show_rebates: boolean
  show_sections: boolean
  show_tags: boolean
  show_taxes: boolean
  show_tech: boolean
  show_templates: boolean
  simple_item_view: boolean
}

export interface ItemProgressEntry {
  created_by: number
  end_time: string | null
  hours: number
  id: number
  install_date: string
  qty: number
  start_time: string | null
  tech: {
    id: number
    title: string
  } | null
  user_id?: number
}

export interface OptionGroup {
  id: number
  position: number
  options: Record<number, ProjectItem>
  group_id: number
  section_id: number
  checked_option: number
}

export interface IProjectItemsGroup {
  id: number
  name: string
  position: number
  section_id: number
  isNew?: boolean
}

export interface ProjectTax {
  id: number
  name: string
  rate: number
  tab_id: number
  total: number
  type: TaxChargeType
  rate_source: TaxRateSource
}

export interface ProjectFee {
  id: number
  description: string
  price: number
  tab_id: number
}

export enum TaxChargeType {
  MATERIALS = 'material',
  INVESTMENT = 'investment',
  // NO_TAX = 'no-tax',
  CUSTOM = 'custom',
}
export enum TaxRateSource {
  'Based on ZIP' = 1,
  'Custom for this Indusctry',
  'Custom %',
}

export enum ProjectStatusGroup {
  Estimate = 1,
  Contract,
  Completed,
  Canceled,
  Cold,
}

export interface ProjectStatus {
  id: number
  parent_status: number
  display_name: string
  color: string
  is_default: boolean
}

export enum ProjectView {
  EDIT = 1,
  PREVIEW,
}

//contacts

export interface Rating {
  value: number
  count_votes: number
}
export enum EntityType {
  CLIENT = 'client',
  PROPERTY = 'property',
}

export enum EntityLevel {
  PARENT = 1,
  CHILD = 2,
  NESTED_CHILD = 3,
}

export interface Phone {
  phone: string
  type: string
}

// interface Email {
//   email: string
// }

export interface Contact {
  checked: boolean
  created_at: string
  deleted_at: string | null
  display: boolean
  emails: string[]
  // emails: Email[] | null
  entity_type: EntityType
  id: number
  is_primary: boolean
  merged_with: null | {
    name: string
    id: number
    merged_at: string
  }
  name: string
  phones: Phone[] | null
  position: number
  proposal_relation_id: number
  proposal_relation: null
  rating: Rating | null
  relation: null
  type: ClientType
}

export interface ProjectProperty {
  address: string
  checked: boolean
  city: string
  created_at: string
  deleted_at: string | null
  display: boolean
  emails: null
  entity_type: EntityType
  full_address: string
  id: number
  is_primary: boolean
  merged_with: null | {
    name: string
    id: number
    merged_at: string
  }
  name: string
  nested_contacts: Contact[]
  nested_properties: ProjectProperty[]
  phones: null
  position: number
  postcode: string
  proposal_relation_id: number
  proposal_relation: null
  rating: null
  relation: null | string
  state: string
  text: string
  type: PropertyType
  unit: string | null
}

export interface Client {
  id: number
  text: string
  full_address: string
  name: string
  entity_type: EntityType
  type: ClientType
  created_at: string | null
  deleted_at: string | null
  proposal_relation: null
  proposal_relation_id: number
  proposal_relation_created_at: string | null
  emails: null
  phones: null
  rating: Rating
  children: (ProjectProperty | Contact)[]
  merged_with: null | {
    name: string
    id: number
    merged_at: string
  }
}

export interface ApiResponse {
  data: Client[]
}

export interface AdditionalData {
  parent_id: number
  parent_type: EntityType
  level: EntityLevel
  entity_id: number
  index: number
  entity_parent_type: EntityType
  position?: number
  is_primary?: boolean
  entity_type: EntityType
  is_child_modal?: boolean
  display: boolean
  parent_proposal_relation_id: number
}

export type ContactWithAdditionalData = Contact & AdditionalData
export type PropertyWithAdditionalData = ProjectProperty & AdditionalData
export type IItem = ContactWithAdditionalData | PropertyWithAdditionalData

export type ChildrenByEntityType<
  T extends 'client' | 'property'
> = T extends 'client'
  ? Contact[]
  : T extends 'property'
  ? ProjectProperty[]
  : never

export interface WorkOrderRowData {
  id: number
  name: string
  primary_client: string
  primary_address: string
  status: string
  created_by: string
  total: number
}

export interface IWorkOrderState {
  forms: boolean
  scope_of_work: boolean
  info_for_workers: boolean
  customer_info: boolean
  contacts: boolean
  notes: boolean
}
