import { useEffect, useState } from 'react'
import { Button, Radio, RadioChangeEvent, Space } from 'antd'
import Modal from 'containers/MainContent/Orcatec/components/UI/Modal'
import styled from 'styled-components'
import BoardStagesList from 'features/CRM/components/BoardStagesList'
import { useAppSelector } from 'store/Orcatec/hooks'
import {
  selectBoardsList,
  selectBoardTotal,
  selectStagesByBoard,
} from 'features/CRM/store/crmSlice'
import { BoardStage, CRMBoard } from 'features/CRM/crm.interface'
import { Select } from 'components/UIKit'

interface Props {
  boardToDelete: CRMBoard
  isLoading: boolean
  onClose: () => void
  onSave: (deleteAction: {
    type: number
    board_id: number
    stage_id: BoardStage['id']
  }) => void
}

export const DeleteBoardModal = ({
  boardToDelete,
  isLoading,
  onClose,
  onSave,
}: Props) => {
  const totalCards = useAppSelector(selectBoardTotal)
  const boards = useAppSelector(selectBoardsList).filter(
    board => board.id !== boardToDelete.id,
  )

  const [value, setValue] = useState(1)
  const [selectedBoardId, setSelectedBoardId] = useState(boards[0].id)

  const stages = useAppSelector(state =>
    selectStagesByBoard(state, selectedBoardId),
  )
  const [selectedStageId, setSelectedStageId] = useState(stages[0].id)

  useEffect(() => {
    setSelectedStageId(stages[0].id)
  }, [selectedBoardId])

  function handleValueChange(e: RadioChangeEvent) {
    setValue(e.target.value)
  }

  function handleSave() {
    onSave({
      type: value,
      board_id: selectedBoardId,
      stage_id: selectedStageId,
    })
  }

  return (
    <Modal
      open
      title='Delete Board'
      width={600}
      onCancel={onClose}
      // onOk={handleSave}
      footer={
        <Buttons>
          <Button onClick={onClose}>Cancel</Button>
          <Button danger onClick={handleSave} loading={isLoading}>
            Delete Board
          </Button>
        </Buttons>
      }
    >
      <Wrapper>
        <Boldtext>
          Are you sure you want to delete the Board “{boardToDelete?.title}”?
        </Boldtext>

        {totalCards ? (
          <>
            <p>
              For this action please choose what would you like to do with all
              Opportunities ({totalCards} card(s)) on this Board.
            </p>

            <Radio.Group onChange={handleValueChange} value={value}>
              <Space direction='vertical' size='middle'>
                <Radio value={1}>
                  <p style={{ marginBottom: 5 }}>Move to another Board</p>

                  {value === 1 && (
                    <>
                      <span
                        onClick={e => {
                          e.preventDefault()
                          e.stopPropagation()
                        }}
                      >
                        <Select
                          required
                          name='board_id'
                          value={selectedBoardId}
                          onChange={(_, value) => {
                            setSelectedBoardId(value)
                          }}
                          options={boards}
                          fieldNames={{ label: 'title', value: 'id' }}
                          width='200px'
                          style={{ marginBottom: 10, width: '100%' }}
                          showSearch={false}
                        />
                      </span>

                      <BoardStagesList
                        data={stages}
                        activeId={selectedStageId}
                        onChange={(id: BoardStage['id']) =>
                          setSelectedStageId(id)
                        }
                      />
                    </>
                  )}
                </Radio>
                <Radio value={2}>Delete all Opportunities</Radio>
              </Space>
            </Radio.Group>
          </>
        ) : (
          <p>There are no Opportunities on this board.</p>
        )}
      </Wrapper>
    </Modal>
  )
}

const Wrapper = styled.div`
  & > * {
    margin-bottom: 16px;
  }

  .ant-radio-group,
  .ant-space,
  .ant-radio-wrapper {
    width: 100%;
  }

  .ant-radio-wrapper {
    display: grid;
    grid-template-columns: 15px 1fr;
    gap: 5px;

    &::after {
      display: none;
    }
  }
`

const Buttons = styled.div`
  display: flex;
  gap: 12px;
  justify-content: flex-end;
`

const Boldtext = styled.p`
  font-weight: 500;
`
