import { useEffect, useState } from 'react'
import { Opportunity } from '../crm.interface'
import crmAPI from '../api'
// import { selectAuthUser } from 'store/Orcatec/selectors/user'
import { useAppSelector } from 'store/Orcatec/hooks'
import { selectActiveBoard, selectStagesByBoard } from '../store/crmSlice'

const initialState: Omit<Opportunity, 'id'> = {
  contact_id: null,
  contact_relation: '',
  name: 'New opportunity',
  type_id: null,
  source_id: null,
  board_id: 1,
  stage_id: 1,
  industry_id: 1,
  user_id: null,
  notes: '',
}

export const useOpportunity = (id: number | null, stageId?: number) => {
  // const authUser = useAppSelector(selectAuthUser)
  const activeBoard = useAppSelector(selectActiveBoard)
  const stages = useAppSelector(state =>
    selectStagesByBoard(state, activeBoard?.id),
  )

  const [opportunity, setOpportunity] = useState({
    ...initialState,
    // user_id: authUser.id,
    board_id: activeBoard?.id || 1,
    stage_id: stageId || stages?.[0]?.id || 1,
  })
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    async function getData(id: number) {
      setLoading(true)
      try {
        const data = await crmAPI.getOpportunity(id)

        setOpportunity(data)
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }

    if (id) getData(id)
  }, [id])

  return { opportunity, loading }
}
