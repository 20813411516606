import { EditProjectCode } from 'containers/MainContent/Orcatec/Proposals/components/Form/Header/components/EditProjectCode'
import { Title, Wrapper } from './Heading.styles'
import ProjectActions from '../ProjectActions'
import ProjectStatus from 'features/Project/components/ProjectStatus'
import { useAppDispatch, useAppSelector } from 'store/Orcatec/hooks'
import {
  selectProjectSlice,
  updateProjectField,
} from 'features/Project/slices/projectSlice'

export const Heading = () => {
  const dispatch = useAppDispatch()

  const { project, status } = useAppSelector(selectProjectSlice)

  const handleCodeSave = async (code: string) => {
    const res = await dispatch(updateProjectField({ code }))

    if (res?.payload?.errors?.code) throw new Error(res.payload.errors.code)

    return res
  }

  return (
    <Wrapper>
      <Title>Edit Project</Title>

      <EditProjectCode
        project={project}
        loading={status === 'updating'}
        onSave={handleCodeSave}
        disabled={![1, 5].includes(project.status)}
      />

      <ProjectStatus />

      <div style={{ marginRight: 5, marginLeft: 'auto' }}>
        <ProjectActions />
      </div>
    </Wrapper>
  )
}
