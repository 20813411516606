import axiosOrcatec from 'api/axiosInstance'
import { CRMBoard, Opportunity } from './crm.interface'
import { GeneralTableParams } from 'containers/MainContent/Orcatec/components/Table/types'
import { EntityType } from 'features/Project/types'

export interface CRMBoardResponse {
  id: number
  title: string
  stages: {
    id: number
    board_id: number
    name: string
    description: string
    color: string
    position: number
  }[]
}

function getBoardList() {
  return axiosOrcatec
    .get<CRMBoardResponse[]>(`/crm/boards`)
    .then(res => res.data)
}

function getBoard(id: number) {
  return axiosOrcatec
    .get<CRMBoardResponse>(`/crm/boards/${id}`)
    .then(res => res.data)
}

function createBoard(data: CRMBoard) {
  return axiosOrcatec
    .post<CRMBoardResponse>(`/crm/boards`, data)
    .then(res => res.data)
}

function updateBoard(data: CRMBoard) {
  return axiosOrcatec.put(`crm/boards/${data.id}`, data).then(res => res.data)
}

function deleteBoard(
  boardId: number,
  params: {
    delete_action: 1 | 2
    board_id: number
    stage_id: number
  },
) {
  return axiosOrcatec
    .delete(`crm/boards/${boardId}`, { params })
    .then(res => res.data)
}

function getOpportunityList(params: GeneralTableParams) {
  return axiosOrcatec
    .get(`/crm/opportunities`, { params })
    .then(res => res.data)
}

function getOpportunity(id: number) {
  return axiosOrcatec
    .get<Opportunity>(`/crm/opportunities/${id}`)
    .then(res => res.data)
}

function createOpportunity(data: Omit<Opportunity, 'id'>) {
  return axiosOrcatec
    .post<Opportunity>(`/crm/opportunities`, data)
    .then(res => res.data)
}

function updateOpportunity(data: Opportunity) {
  return axiosOrcatec
    .put<Opportunity>(`/crm/opportunities/${data.id}`, data)
    .then(res => res.data)
}

function patchOpportunity(opportunityId: number, data: Partial<Opportunity>) {
  return axiosOrcatec
    .patch<Opportunity>(
      `/crm/opportunities/${opportunityId}/partial-update`,
      data,
    )
    .then(res => res.data)
}

function deleteOpportunity(id: number, params: { hard_delete: boolean }) {
  return axiosOrcatec
    .delete<Opportunity>(`/crm/opportunities/${id}`, { params })
    .then(res => res.data)
}

function getOpportunityBoard(boardId: number, params?: GeneralTableParams) {
  return axiosOrcatec
    .get(`/crm/opportunities/board/${boardId}`, { params })
    .then(res => res.data)
}

function getOportunityProjects(
  opportunityId: number,
  params?: GeneralTableParams,
) {
  return axiosOrcatec
    .get(`/crm/opportunities/${opportunityId}/proposals`, { params })
    .then(res => res.data)
}

function attachProject(opportunityId: number, projectId: number) {
  return axiosOrcatec
    .post(`/crm/opportunities/${opportunityId}/proposals/${projectId}/attach`)
    .then(res => res.data)
}

function detachProject(opportunityId: number, projectId: number) {
  return axiosOrcatec
    .post(`/crm/opportunities/${opportunityId}/proposals/${projectId}/detach`)
    .then(res => res.data)
}

function getOpportunityProperties(id: number) {
  return axiosOrcatec
    .get(`/crm/opportunities/${id}/properties`)
    .then(res => res.data)
}

function attachOpportunityProperty(
  opportunityId: number,
  data: { property_id: number },
) {
  return axiosOrcatec
    .post(`/crm/opportunities/${opportunityId}/properties-attach`, data)
    .then(res => res.data)
}

function attachOpportunityContact(
  opportunityId: number,
  data: { contact_id: number },
) {
  return axiosOrcatec
    .post(`/crm/opportunities/${opportunityId}/contacts-attach`, data)
    .then(res => res.data)
}

function detachOpportunityProperty(
  opportunityId: number,
  data: { property_id: number },
) {
  return axiosOrcatec
    .post(`/crm/opportunities/${opportunityId}/properties-detach`, data)
    .then(res => res.data)
}

function detachOpportunityContact(
  opportunityId: number,
  data: { contact_id: number },
) {
  return axiosOrcatec
    .post(`/crm/opportunities/${opportunityId}/contacts-detach`, data)
    .then(res => res.data)
}

function updateContactRelation(
  opportunityId: number,
  data: { relation_id: number; relation: string },
) {
  return axiosOrcatec
    .patch(`/crm/opportunities/${opportunityId}/contacts/update-relation`, data)
    .then(res => res.data)
}

function updatePropertyRelation(
  opportunityId: number,
  data: { relation_id: number; relation: string },
) {
  return axiosOrcatec
    .patch(
      `/crm/opportunities/${opportunityId}/properties/update-relation`,
      data,
    )
    .then(res => res.data)
}

const crmAPI = {
  getBoardList,
  getBoard,
  createBoard,
  updateBoard,
  deleteBoard,
  getOpportunityList,
  getOpportunity,
  createOpportunity,
  updateOpportunity,
  patchOpportunity,
  deleteOpportunity,
  getOpportunityBoard,
  getOpportunityContacts,
  getOpportunityProperties,
  detachOpportunityProperty,
  detachOpportunityContact,
  attachOpportunityProperty,
  attachOpportunityContact,
  updateContactRelation,
  updatePropertyRelation,
  getOportunityProjects,
  attachProject,
  detachProject,
}

function getRelationContactToOpportunity(opportunityId: number) {
  return axiosOrcatec
    .get(`/crm/opportunities/${opportunityId}/related-contacts`)
    .then(res => res.data)
}

function getOpportunityContacts(opportunityId: number) {
  return axiosOrcatec
    .get(`/crm/opportunities/${opportunityId}/contacts-extended`)
    .then(res => res.data?.data)
}

function attachContactToOpportunity(
  opportunityId: number,
  data: { entity_id: number; entity_type: EntityType },
) {
  return axiosOrcatec
    .post(`/crm/opportunities/${opportunityId}/parent-contacts`, data)
    .then(res => res.data)
}

function attachChildContactToOpportunity(
  opportunityId: number,
  data: {
    opportunity_parent_relation_id: number
    opportunity_parent_id: number
    opportunity_parent_type: EntityType
    parent_entity_id: number
    parent_entity_type: EntityType
    entity_id: number
    entity_type: EntityType
  },
) {
  return axiosOrcatec
    .post(`/crm/opportunities/${opportunityId}/child-contacts`, data)
    .then(res => res.data)
}

function detachContactFromOpportunity(
  opportunityId: number,
  data: {
    entity_id: number
    entity_type: EntityType
    opportunity_relation_id: number
  },
) {
  return axiosOrcatec
    .delete(
      `/crm/opportunities/${opportunityId}/${data.entity_type}/opportunity-relations/${data.opportunity_relation_id}`,
    )
    .then(res => res.data)
}

function updateContactOpportunityRelation(
  opportunityId: number,
  data: {
    opportunity_relation_id: number
    opportunity_relation_type: EntityType
    opportunity_relation: string
  },
) {
  return axiosOrcatec
    .patch(`/crm/opportunities/${opportunityId}/relation`, data)
    .then(res => res.data)
}

function getParentContactById(
  opportunityId: number,
  data: { entity_id: number; entity_type: EntityType },
) {
  return axiosOrcatec
    .get(
      `/crm/opportunities/${opportunityId}/${data.entity_type}/${data.entity_id}/parent `,
    )
    .then(res => res.data)
}

function selectParentContact(
  opportunityId: number,
  data: { main_relation_id: number; entity_type: EntityType; display: boolean },
) {
  return axiosOrcatec.put(
    `/crm/opportunities/${opportunityId}/set-display`,
    data,
  )
}

function setClientsPositions(opportunityId, data) {
  return axiosOrcatec
    .post(`/crm/opportunities/${opportunityId}/contacts/set-position`, data)
    .then(res => res.data)
}

function setPropertiessPositions(opportunityId, data) {
  return axiosOrcatec
    .post(`/crm/opportunities/${opportunityId}/properties/set-position`, data)
    .then(res => res.data)
}

export const OpportunityContactsAPI = {
  getRelationContactToOpportunity,
  getOpportunityContacts,
  attachContactToOpportunity,
  attachChildContactToOpportunity,
  detachContactFromOpportunity,
  updateContactOpportunityRelation,
  getParentContactById,
  selectParentContact,
  setClientsPositions,
  setPropertiessPositions,
}

export default crmAPI
