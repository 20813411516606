import React, { FC, useEffect, useState } from 'react'
import {
  EntityType,
  IItem,
  EntityLevel,
} from 'features/CRM/components/Opportunity/types'
import { Collapse, CollapsePanelProps } from 'antd'
import { useAppSelector } from 'store/Orcatec/hooks'
import { ContactItem } from '../ContactItem/ContactItem'

import styled from 'styled-components'

import { useSearch } from 'features/Project/Contacts/hooks/useSearch'
import { ArrowButtonIcon } from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
import { isMatch } from 'features/Project/Contacts/helpers/isMatch'
import {
  selectContactbyId,
  selectOpportunityContactsRelations,
} from 'features/CRM/store/opportunityContactsSlice'

const { Panel } = Collapse

interface IProps {
  onOpenForm: (item: IItem) => void
  item: IItem
  freezed: boolean

  handleDetachContact: (item: IItem) => void
  level: EntityLevel
  isItemDisabled: boolean
  contactId: number
  search: string
  relations: {
    opportunity_relation: null | string
    opportunity_relation_id: number
    entity_id: number
    entity_type: EntityType
  }
  contactType: EntityType
  handleSelectItem: (checked: boolean, item: IItem) => void
  handleSelectParentItem: (checked: boolean, item: IItem) => void
  handleUpdateProjectRelation: ({
    item,
    opportunity_relation,
  }: {
    item: IItem
    opportunity_relation: string
  }) => void
}

const renderExpandIcon = (
  props: CollapsePanelProps & { isActive: boolean },
): React.ReactNode => {
  const rotate = props?.isActive ? 0 : -90

  return (
    <div {...props} style={{ transform: `rotate(${rotate}deg)`, top: '33%' }}>
      <ArrowButtonIcon />
    </div>
  )
}

export const ContactContainer: FC<IProps> = ({
  contactId,
  contactType,
  handleDetachContact,
  freezed,
  onOpenForm,
  handleSelectItem,
  handleUpdateProjectRelation,
  search,
  handleSelectParentItem,
}) => {
  const [activeKey, setActiveKey] = useState<string[]>([])

  const [parent, ...contacts] = useAppSelector(
    selectContactbyId(contactId, contactType),
  )

  const { filteredContacts, parentVisible } = useSearch(
    contacts,
    parent,
    search,
  )

  const relations = useAppSelector(selectOpportunityContactsRelations)

  const handleChangeTab = (key: string[]) => {
    setActiveKey(key)
  }

  useEffect(() => {
    if (!search) {
      const allActiveContacts = [parent, ...contacts]
        .filter(item => item?.checked || item?.display)
        .map(item => item.index)

      const maxIndex = Math.max(...allActiveContacts)
      const allPanelKeys = Array.from(
        { length: maxIndex },
        (_, i) => `${i + 1}`,
      )
      setActiveKey(allPanelKeys)
      return
    }
    const allPanelKeys = filteredContacts.map(item => item.index)

    setActiveKey([1, ...allPanelKeys])
  }, [search, contactId, contacts.length])

  const parentHasCheckedChildren =
    !parent.display && contacts.some(contact => contact.checked)

  const checkedThirdLevel = contacts
    .filter(contact => contact.level === 3 && contact.checked)
    .map(contact => contact.parent_id)

  const levelTwoHasCheckedChildren = contacts
    .filter(
      contact =>
        contact.level === 2 &&
        !contact.checked &&
        checkedThirdLevel.includes(contact.id),
    )
    .map(contact => contact.id)

  return (
    <Wrapper
      className={parentVisible ? 'tree-visible' : 'tree-hidden'}
      isEmpty={parentVisible}
    >
      {parentVisible && (
        <Collapse
          collapsible='icon'
          expandIconPosition='end'
          expandIcon={renderExpandIcon}
          ghost
          style={{ backgroundColor: 'white', borderRadius: '5px' }}
          activeKey={activeKey}
          onChange={handleChangeTab}
        >
          <Panel
            showArrow={!!filteredContacts?.length}
            header={
              <ContactItem
                handleDetachContact={handleDetachContact}
                freezed={freezed}
                onOpenForm={onOpenForm}
                level={parent.level}
                item={parent}
                relations={relations[parent?.opportunity_relation_id]}
                handleSelectItem={handleSelectItem}
                handleUpdateProjectRelation={handleUpdateProjectRelation}
                searchValue={search}
                handleSelectParentItem={handleSelectParentItem}
                parentRelationId={parent.opportunity_relation_id}
                hasCheckedChilds={parentHasCheckedChildren}
              />
            }
            key={parent.index}
          >
            {filteredContacts
              .filter(item => item?.parent_id === parent?.id)
              .map(children => (
                <Collapse
                  expandIconPosition='end'
                  collapsible='icon'
                  expandIcon={renderExpandIcon}
                  key={`${children.index}`}
                  ghost
                  activeKey={activeKey}
                  onChange={handleChangeTab}
                >
                  <Panel
                    showArrow={
                      !!filteredContacts.filter(
                        item => item?.parent_id === children?.id,
                      )?.length
                    }
                    header={
                      <ContactItem
                        handleDetachContact={handleDetachContact}
                        freezed={freezed}
                        onOpenForm={onOpenForm}
                        level={children.level}
                        item={children}
                        relations={relations[children?.opportunity_relation_id]}
                        handleSelectItem={handleSelectItem}
                        handleUpdateProjectRelation={
                          handleUpdateProjectRelation
                        }
                        searchValue={search}
                        parentRelationId={parent.opportunity_relation_id}
                        hasCheckedChilds={levelTwoHasCheckedChildren.includes(
                          children.id,
                        )}
                      />
                    }
                    key={children.index}
                  >
                    {contacts
                      .filter(
                        item =>
                          item?.parent_id === children?.id &&
                          isMatch(item, search),
                      )
                      .map((item, idx) => (
                        <ContactItem
                          key={idx}
                          handleDetachContact={handleDetachContact}
                          freezed={freezed}
                          onOpenForm={onOpenForm}
                          level={item.level}
                          item={item}
                          relations={relations[item?.opportunity_relation_id]}
                          handleSelectItem={handleSelectItem}
                          handleUpdateProjectRelation={
                            handleUpdateProjectRelation
                          }
                          searchValue={search}
                          parentRelationId={parent.opportunity_relation_id}
                        />
                      ))}
                  </Panel>
                </Collapse>
              ))}
          </Panel>
        </Collapse>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div<{ isEmpty: boolean }>`
  margin-bottom: 10px;
  border: ${props =>
    props.isEmpty ? '1px solid rgba(237, 239, 247, 1)' : 'none'};

  & .ant-collapse-header,
  .ant-collapse-content-box {
    padding: 0 !important;
  }
  & .ant-collapse-item-active .ant-collapse-header-text {
    border-bottom: 1px solid rgba(237, 239, 247, 1);
  }

  &
    .ant-collapse-content
    .ant-collapse-content-box
    > div:last-child
    .ant-collapse-header-text {
    border-bottom: none;
  }
`
